.fleetThree {
    background-image: url("../../img/fleetStructure/fleetThree/teste.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 350px 150px 250px;
    opacity: 0%;

    h1 {
        opacity: 0%;
        color: var(--cor-secondary);
        font-weight: 700;
        padding-bottom: 10px;
    }

    h1.visible {
        animation: halfToDown 1s .2s ease forwards;
    }

    p {
        opacity: 0%;
        max-width: 1000px;
        font-size: 18px;
        color: rgb(223, 223, 223);
        font-weight: 300;
        padding-bottom: 10px;
    }

    p.visible {
        animation: halfToDown 1s .2s ease forwards;
    }

    span {
        color: #fff;
    }
}

.fleetThree.visible {
    animation: fadeIn 2s ease forwards;
}

@media screen and (max-width: 1600px) {
    .fleetThree {
        padding: 300px 50px 200px;

        p {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 500px) {
    .fleetThree {
        padding: 150px 20px 120px;
        background-size: 2000px;

        p {
            font-size: 16px;
        }
    }
}

@media screen and (max-width: 425px) {
    .fleetThree {
        p {
            font-size: 14px;
        }
    }
}