.homeFleet {
    height: 80vh;
    padding: 120px 50px;
    display: grid;
    grid-template-columns: auto auto;
    border-radius: 20px;

    background-image: linear-gradient(45deg, var(--cor-primary) 0% 49.5%, white 49.5% 50.5%, var(--cor-secondary) 50.5% 100%);

    h1 {
        color: white;
        font-weight: 700;
        width: 70%;
        max-width: 500px;
        opacity: 0%;
    }

    h1.visible {
        animation: slideToUp 1s ease forwards;
    }

    p {
        padding-top: 20px;
        font-size: 18px;
        width: 80%;
        color: white;
        font-weight: 300;
        max-width: 650px;
        padding-bottom: 2s0px;
        opacity: 0%;
    }

    p.visible {
        animation: slideToUp 1s .2s ease forwards;
    }

    .fleet {
        align-self: end;

        a {
            display: grid;
            height: fit-content;
            width: fit-content;
            opacity: 0%;

            button {
                width: 300px;
                height: 50px;
                background-color: var(--cor-secondary);
                border: none;
                border-radius: 5px;
                color: white;
                font-weight: 600;
                transition: .4s ease;
                border: 2px solid rgba(255, 255, 255, 0);
            }
        }

        a.visible {
            animation: halfToDown 1s .6s ease forwards;
        }

        a:hover {
            button {
                background-color: rgba(255, 255, 255, 0.815);
                color: var(--cor-secondary);
                border: 2px solid var(--cor-secondary);
                scale: .95;
            }
        }
    }

    .warehouse {
        text-align: right;
        display: grid;
        height: fit-content;

        a {
            display: grid;
            justify-self: end;
            height: fit-content;
            width: fit-content;
            opacity: 0%;

            button {
                width: 300px;
                height: 50px;
                background-color: var(--cor-primary);
                border: none;
                border-radius: 5px;
                color: white;
                font-weight: 600;
                transition: .4s ease;
                border: 2px solid rgba(255, 255, 255, 0);
            }
        }

        a.visible {
            animation: halfToDown 1s .6s ease forwards;
        }

        a:hover {
            button {
                background-color: rgba(255, 255, 255, 0.815);
                color: var(--cor-secondary);
                border: 2px solid var(--cor-secondary);
                scale: .95;
            }
        }

        h1 {
            color: white;
            justify-self: right;
        }

        p {
            justify-self: right;
        }
    }
}

@media screen and (max-width: 1600px) {
    .homeFleet {
        padding: 80px 50px;

        p {
            font-size: 16px !important;
        }
    }
}

@media screen and (max-width: 1400px) {
    .homeFleet {
        p {
            font-size: 16px;
        }

        a {
            img {
                width: 250px !important;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .homeFleet {
        padding: 50px 50px;

        a {
            button {
                width: 200px !important;
            }
        }
    }
}

@media screen and (max-width: 850px) {
    .homeFleet {
        height: fit-content;
        grid-template-columns: 100%;
        background-image: linear-gradient(170deg, var(--cor-primary) 0% 50%, var(--cor-secondary) 50% 100%);
        gap: 80px;
        padding: 80px 50px 50px;

        h1,
        p {
            width: 80%;
        }
    }
}


@media screen and (max-width: 768px) {
    .homeFleet {
        p {
            max-width: 100%;
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 550px) {
    .homeFleet {
        a {
            img {
                width: 220px !important;
            }

            p {
                margin-top: 0px !important;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeFleet {
        padding: 80px 20px 50px;
        gap: 100px;

        p {
            font-size: 14px !important;
        }

        h1,
        p {
            width: 100%;
        }
    }
}