.warehouseOne {
    padding: 150px 200px 30px;

    .warehouseOneTitle {
        text-align: center;
        display: grid;

        h1 {
            padding-bottom: 20px;
            color: var(--cor-primary);
            font-weight: 700;
            opacity: 0%;

            span {
                color: var(--cor-secondary);
            }
        }

        h1.visible {
            animation: slideToUp 1s ease forwards;
        }

        p {
            line-height: 35px;
            justify-self: center;
            font-size: 18px;
            max-width: 1100px;
            opacity: 0%;
        }

        p.visible {
            animation: slideToUp 1s ease forwards;
        }
    }

    .swiper {
        padding: 50px 0px 50px;
        opacity: 0%;

        .swiper-button-next:after,
        .swiper-button-prev::after {
            display: none;
        }

        .swiper-slide {
            scale: .9;
            transition: .5s ease;


            .card {
                background: none !important;
                width: 100%;
                display: grid;
                justify-content: center;
                align-items: center;
                border: none;
                opacity: 0%;
                grid-template-areas: 'card';

                img {
                    justify-self: center;
                    grid-area: card;
                    width: 350px;
                    z-index: 10;
                    transition: .5s ease;
                    cursor: pointer;
                }

                .imgBg {
                    transition: .5s ease;
                    opacity: 0%;
                    z-index: 5;
                    width: 380px;
                }
            }

            .card:hover {

                .imgBg,
                img {
                    scale: 1.1;
                }
            }
        }

        .swiper-slide.visible {
            animation: slideToUp 2s .7s ease forwards;
        }

        .swiper-slide-next {
            scale: 1.1;

            .imgBg {
                opacity: 100% !important;
            }
        }
    }

    .swiper.visible {
        animation: fadeIn 2s ease forwards;

        .card1 {
            animation: halfToUp 2s .1s ease forwards;
        }

        .card2 {
            animation: halfToUp 2s .2s ease forwards;
        }

        .card3 {
            animation: halfToUp 2s .3s ease forwards;
        }

        .card4 {
            animation: halfToUp 2s .4s ease forwards;
        }

        .card5 {
            animation: halfToUp 2s .5s ease forwards;
        }

        .card6 {
            animation: halfToUp 2s .6s ease forwards;
        }

        .card7 {
            animation: halfToUp 2s .7s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px) {
    .warehouseOne {
        padding: 120px 200px 0px;

        .warehouseOneTitle {
            p {
                font-size: 16px;
                line-height: 30px;
            }
        }

        .swiper {
            padding: 30px 0px 30px;

            .swiper-slide {
                .card {
                    img {
                        width: 280px !important;
                    }

                    .imgBg {
                        width: 310px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .warehouseOne {
        padding: 120px 100px 20px;

        .swiper {
            padding: 10px 0px 0px;

            .swiper-slide {
                .card {
                    img {
                        width: 250px !important;
                    }

                    .imgBg {
                        width: 280px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .warehouseOne {
        .swiper {
            .swiper-slide {
                scale: 1;
            }

            .swiper-slide-next {
                scale: 1;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .warehouseOne {
        padding: 120px 50px 0px;

        .warehouseOneTitle {
            p {
                line-height: 25px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .warehouseOne {
        padding-bottom: 0px;

        .warehouseOneTitle {
            text-align: left;

            h1,
            p {
                margin: 0px;
            }
        }

        .swiper {
            padding: 20px 0px;

            .swiper-slide {
                .card {
                    padding: 0px 0px 0px !important;

                    .imgBg {
                        opacity: 100%;
                    }
                }
            }

            .swiper-button-next {
                right: 15%;
            }

            .swiper-button-prev {
                left: 15%;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .warehouseOne {
        padding: 110px 20px 0px;

        .warehouseOneTitle {
            h1 {
                padding-bottom: 10px;
            }

            p {
                font-size: 14px
            }
        }

        .swiper {
            .swiper-button-next {
                right: 5%;

                img {
                    width: 25px;
                }
            }

            .swiper-button-prev {
                left: 5%;

                img {
                    width: 25px;
                }
            }
        }
    }
}