.warehouseSix{
    padding: 80px 250px 0px;   

    display: grid;
    align-items: center;
    justify-content: left; 
    grid-template-columns: 60% auto;
    column-gap: 50px;

    h1{
        grid-column-start: 1;
        grid-column-end: 2;
        color: var(--cor-primary);
        font-weight: 700;
        max-width: 500px;
        opacity: 0%;
    }
    h1.visible{
        animation: halfToLeft 2s ease forwards;
    }
    p{
        grid-column-start: 1;
        grid-column-end: 2;
        line-height: 30px;
        font-size: 18px;
        max-width: 1000px;
        opacity: 0%;
    }
    p.visible{
        animation: halfToLeft 2s ease forwards;
    }
    img{
        width: 300px;
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 3;
        opacity: 0%;
    }
    img.visible{
        animation: halfToLeft 2s ease forwards;
    }
}

@media screen and (max-width: 1600px){
    .warehouseSix{
        padding: 100px 150px 0px;
        p{
            font-size: 16px
        }
    }
}

@media screen and (max-width: 1400px){
    .warehouseSix{
        padding: 100px 100px 0px;
        
        img{
            width: 300px;
        }
    }
}

@media screen and (max-width: 1000px){
    .warehouseSix{
        padding: 50px 50px 20px;
    }
}

@media screen and (max-width: 900px){
    .warehouseSix{
        img{
            width: 100%;
        }
    }
}

@media screen and (max-width: 700px){
    .warehouseSix{
        padding: 50px 50px;
        img{
            position: relative;
            right: 0px;
        }
        .text{
            p{
                line-height: 25px;
            }
        }
    }
}

@media screen and (max-width: 700px){
    .warehouseSix{
        padding: 50px 20px 20px;
        gap: 20px;
        
        img{
            justify-self: end;
            width: 100%;
            max-width: 150px;
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 2;
            grid-column-end: 3;
        }
        h1{
            grid-column-start: 1 !important;
            grid-column: 2;
            grid-row-start: 1;
            grid-row-end: 2;
            max-width: 250px;
            align-self: flex-end;
            margin: 0px;
        }
        p{
            margin: 0px;
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3 !important;
            line-height: 25px;
        }
    }
}

@media screen and (max-width: 425px){
    .warehouseSix{
        img{
            top: 10px;
        }
        p{
            font-size: 16px;
        }
    }
}