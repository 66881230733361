.esgEight {
    padding: 50px 150px 100px;
    display: grid;
    grid-template-columns: auto auto;
    background-image: url("../../img/esg/esgEight/esgEight-bg.webp");
    background-size: cover;
    opacity: 0%;

    .esgEightTitle {
        padding-top: 150px;

        h1 {
            opacity: 0%;
            color: var(--cor-primary);
            font-weight: 700;
            width: 500px;
        }

        h1.visible {
            animation: halfToRight 1s ease forwards;
        }

        p {
            opacity: 0%;
            font-size: 18px;
            width: 550px;
            line-height: 30px;
        }

        p.visible {
            animation: halfToRight 1s .1s ease forwards;
        }
    }

    .cards {
        display: grid;
        grid-template-columns: auto auto;
        width: fit-content;
        justify-self: left;
        gap: 50px;
        row-gap: 30px;

        .card {
            opacity: 0%;
            display: grid;
            border: none;
            width: fit-content;
            grid-template-areas: 'card';
            align-items: center;
            justify-content: center;
            text-align: center;
            cursor: pointer;

            img,
            .text {
                grid-area: card;
                transition: .5s ease;
            }

            .text {
                h5 {
                    position: relative;
                    font-size: 18px;
                    top: 10px;
                    color: var(--cor-tertiary);
                    font-weight: 600
                }

                h3 {
                    font-size: 24px;
                    margin: 0px;
                }

                p {
                    color: var(--cor-primary);
                    font-size: 30px;
                    font-weight: 700;
                    margin: 0px;
                }
            }
        }

        .card:hover {
            img {
                transform: translateY(10px);
                filter: blur(5px);
            }

            .text {
                scale: 1.1;
            }
        }

        .card1.visible {
            animation: halfToRight 1s .2s ease forwards;
        }

        .card2.visible {
            animation: halfToRight 1s .3s ease forwards;
        }

        .card3.visible {
            animation: halfToRight 1s .4s ease forwards;
        }

        .card4.visible {
            animation: halfToRight 1s .5s ease forwards;
        }

        .card1,
        .card3 {
            position: relative;
            top: 50px;
        }

        .card3 {
            top: 10px;
            left: 60%;
        }
    }
}

.esgEight.visible {
    animation: changeOpacity 1s ease forwards;
}

@media screen and (max-width: 1600px) {
    .esgEight {
        .cards {
            gap: 20px 30px;
            justify-self: right;

            .card {
                img {
                    width: 200px;
                }

                .text {
                    h3 {
                        font-size: 20px;
                    }

                    p {
                        font-size: 35px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .esgEight {
        .esgEightTitle {
            width: 100%;

            h1 {
                width: 80%;
            }

            p {
                width: 80%;
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .esgEight {
        grid-template-columns: 50% 50%;
        padding: 50px 50px 50px 150px;

        .esgEightTitle {
            width: fit-content;

            h1 {
                min-width: 400px;
            }
        }

        .cards {
            justify-self: center;
        }
    }
}

@media screen and (max-width: 1000px) {
    .esgEight {
        padding: 50px 50px 50px;
        background: none;
        background-repeat: no-repeat;
        grid-template-columns: 100%;

        .esgEightTitle {
            padding-top: 0px;
        }

        .cards {
            padding-top: 30px;
            display: flex;
            gap: 10px;

            .card {
                img {
                    width: 150px;
                }

                .text {
                    h1 {
                        font-size: 18px;
                    }

                    p {
                        font-size: 25px;
                    }

                    h5 {
                        top: 20px;
                        font-size: 16px;
                    }
                }
            }

            .card3 {
                top: 50px;
                left: 0px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .esgEight {
        .cards {
            gap: 0px;

            .card {
                padding: 10px !important;
                background-color: rgba(255, 255, 255, 0);

                img {
                    width: 150px;
                }

                .text {
                    h3 {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .esgEight {
        .cards {
            padding: 0px;
            display: grid;
            grid-template-columns: auto auto auto auto;
            grid-template-rows: auto auto;

            .card {
                top: 0px;
            }

            .card1 {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 2;
                grid-row-end: 3;
                left: 20px;
                top: -20px;
            }

            .card2 {
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 2;
                left: 20px;
            }

            .card3 {
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
                right: 20px;
                top: -20px;
            }

            .card4 {
                grid-column-start: 4;
                grid-column-end: 5;
                grid-row-start: 1;
                grid-row-end: 2;
                right: 20px;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .esgEight {
        .esgEightTitle {
            width: 100%;

            h1 {
                width: 100%;
                min-width: 100%;
            }

            p {
                width: 100%;
            }
        }

        .cards {
            width: 100%;
            padding-top: 30px;
            grid-template-columns: 50% 50%;
            grid-template-rows: auto;

            .card {
                left: 0px;
                top: 0px;
            }

            .card1,
            .card2 {
                grid-row-start: 1;
                grid-row-end: 2;
            }

            .card3,
            .card4 {
                justify-self: right;
                grid-row-start: 2;
                grid-row-end: 3;
            }

            .card3 {
                left: 40%;
            }

            .card1,
            .card3 {
                grid-column-start: 1;
                grid-column-end: 2;
            }

            .card2,
            .card4 {
                grid-column-start: 2;
                grid-column-end: 3;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .esgEight {
        padding: 50px 20px 0px;

        .esgEightTitle {
            p {
                font-size: 14px;
                line-height: 25px;
            }
        }

        .cards {
            gap: 0px;

            .card {
                max-width: 100%;

                img {
                    max-width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .esgEight {
        .cards {
            gap: 10px;
        }
    }
}