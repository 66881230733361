.servicesTwo {
    padding: 0px;
    padding-bottom: 0px !important;

    .servicesTwoText {
        padding: 0px 0px 50px;
        text-align: center;
        display: grid;

        h1 {
            color: var(--cor-secondary);
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToUp 1s ease forwards;
        }

        h2 {
            color: var(--cor-primary);
            font-weight: 600;
            opacity: 0%;
        }

        h2.visible {
            animation: slideToUp 1s .2s ease forwards;
        }

        p {
            line-height: 35px;
            font-size: 18px;
            padding-top: 20px;
            justify-self: center;
            width: 68%;
            opacity: 0%;
        }

        p.visible {
            animation: slideToUp 1s .2s ease forwards;
        }
    }

    .servicesTwoCards-bg {
        background-image: url('../../img/services/servicesTwo/servicesTwo-bg.webp');
        background-repeat: none;
        background-size: cover;
        background-position: 50% 80%;
        display: flex;
        height: fit-content;
        opacity: 0%;

        .servicesTwoCards {
            -webkit-backdrop-filter: blur(10.5px);
            backdrop-filter: blur(10.5px);
            background-color: hsla(0, 0%, 75%, 0.199);
            background-image: url('../../img/services/servicesTwo/servicesTwo-bg2.webp');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50%;
            padding: 100px;
            height: fit-content;

            display: grid;
            justify-content: center;
            gap: 5%;
            grid-template-columns: auto;

            width: 100%;

            .card {
                height: fit-content;
                width: 350px;
                padding: 30px 12px;
                border-radius: 20px;
                text-align: center;
                display: grid;
                justify-content: center;
                -webkit-backdrop-filter: blur(10.5px);
                backdrop-filter: blur(10.5px);
                background-color: hsla(228, 100%, 99%, 0.774);
                box-shadow: 0 8px 32px 0 #ffffff57;
                opacity: 0%;
                transition: .5s ease;
                border: 2px solid rgba(255, 255, 255, 0);
                cursor: pointer;


                img {
                    justify-self: center;
                    padding-bottom: 20px;
                    opacity: 0%;
                }

                h3 {
                    font-size: 20px;
                    color: var(--cor-tertiary);
                    font-weight: 700;
                    opacity: 0%;
                }

                p {
                    color: rgb(11, 0, 53);
                    font-size: 15px;
                    width: 100%;
                    margin: 0px;
                    opacity: 0%;
                }
            }

            .card:hover {
                scale: .95;
                box-shadow: none;
                border: 2px solid var(--cor-primary);
            }

            .card1 {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 2;
            }

            .card1.visible {
                animation: slideToLeft 1s 1.2s ease forwards;

                img.visible {
                    animation: changeOpacity 1s 1.4s ease forwards;
                }

                h3 {
                    animation: changeOpacity 1s 1.6s ease forwards;
                }

                p {
                    animation: changeOpacity 1s 1.8s ease forwards;
                }
            }

            .card2 {
                position: relative;
                top: 30%;
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 2;
            }

            .card2.visible {
                animation: slideToLeft 2s 1.4s ease forwards;

                img.visible {
                    animation: changeOpacity 2s 1.6s ease forwards;
                }

                h3 {
                    animation: changeOpacity 2s 1.8s ease forwards;
                }

                p {
                    animation: changeOpacity 2s 2s ease forwards;
                }
            }

            .card3 {
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 1;
                grid-row-end: 2;

                img {
                    width: 280px;
                }
            }

            .card3.visible {
                animation: slideToLeft 2s 1.6s ease forwards;

                img.visible {
                    animation: changeOpacity 2s 1.8s ease forwards;
                }

                h3 {
                    animation: changeOpacity 2s 2s ease forwards;
                }

                p {
                    animation: changeOpacity 2s 2.2s ease forwards;
                }
            }

            .card4 {
                position: relative;
                left: 15%;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 2;
                grid-row-end: 3;
            }

            .card4.visible {
                animation: slideToLeft 2s 1.8s ease forwards;

                img.visible {
                    animation: changeOpacity 2s 2s ease forwards;
                }

                h3 {
                    animation: changeOpacity 2s 2.2s ease forwards;
                }

                p {
                    animation: changeOpacity 2s 2.4s ease forwards;
                }
            }

            .card5 {
                position: relative;
                right: 15%;
                grid-column-start: 3;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 3;
            }

            .card5.visible {
                animation: slideToLeft 2s 2s ease forwards;

                img.visible {
                    animation: changeOpacity 2s 2.2s ease forwards;
                }

                h3 {
                    animation: changeOpacity 2s 2.4s ease forwards;
                }

                p {
                    animation: changeOpacity 2s 2.6s ease forwards;
                }
            }
        }
    }

    .servicesTwoCards-bg.visible {
        animation: changeOpacity 2s 1s ease forwards;
    }
}

@media screen and (max-width: 1600px) {
    .servicesTwo {
        .servicesTwoText {
            p {
                font-size: 16px;
            }
        }

        .servicesTwoCards-bg {
            .servicesTwoCards {
                .card {
                    img {
                        width: 120px;
                    }

                    h3 {
                        font-size: 18px !important;
                    }

                    p {
                        font-size: 14px;
                    }
                }

                .card4 {
                    img {
                        width: 220px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .servicesTwo {
        .servicesTwoCards-bg {
            .servicesTwoCards {
                .card4 {
                    left: 0%;
                }

                .card5 {
                    right: 0%
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .servicesTwo {
        .servicesTwoText{
            padding: 0px 50px;
            p{
                width: 100%;
            }
        }
        .servicesTwoCards-bg {
            .servicesTwoCards {
                row-gap: 20px;
                column-gap: 20px;

                .card1 {
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }

                .card2 {
                    position: relative;
                    top: 55%;
                    grid-column-start: 2;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }

                .card3 {
                    position: relative;
                    /* left: 50px; */
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }

                .card4 {
                    position: relative;
                    top: 50%;
                    grid-column-start: 2;
                    grid-column-end: 3;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }

                .card5 {
                    position: relative;
                    /* left: 0px; */
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 3;
                    grid-row-end: 4;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .servicesTwo {
        .servicesTwoCards-bg {
            .servicesTwoCards {
                .card {
                    width: 300px;
                    padding: 30px 20px;
                }

                .card3 {
                    align-self: center;
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .servicesTwo {
        .servicesTwoCards-bg {
            .servicesTwoCards {
                padding: 100px 20px;

                .card {
                    width: 100%;

                    img {
                        width: 100px !important;
                    }
                }

                .card4 {
                    img {
                        width: 200px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .servicesTwo {
        .servicesTwoCards-bg {
            .servicesTwoCards {
                padding: 50px 5px;
                row-gap: 5px;

                .card1 {
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 1;
                    grid-row-end: 2;
                }

                .card2 {
                    position: relative;
                    top: 0%;
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 2;
                    grid-row-end: 3;
                }

                .card3 {
                    position: relative;
                    left: 0px;
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 3;
                    grid-row-end: 4;
                }

                .card4 {
                    position: relative;
                    top: 0%;
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 4;
                    grid-row-end: 5;
                }

                .card5 {
                    position: relative;
                    left: 0px;
                    grid-column-start: 1;
                    grid-column-end: 2;
                    grid-row-start: 5;
                    grid-row-end: 6;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .servicesTwo {
        .servicesTwoText{
            padding: 0px 20px;

            p{
                padding-top: 5px;
                font-size: 14px;
                line-height: 25px;
            }
        }
        .servicesTwoCards-bg {
            .servicesTwoCards {
                padding: 50px 20px;
                row-gap: 10px;
                column-gap: 100px;

                .card {
                    width: 100%;
                }
            }
        }
    }
}