.fleetSix{
    padding: 100px 0px 0px;
    display: grid;
    grid-template-columns: fit-content;
    justify-content: center;
    row-gap: 100px;
    gap: 100px;

    .card{
        border: none;
        width: 600px;
        justify-self: center;
        opacity: 0%;

        iframe{
            border-radius: 30px;
            width: 100%;
        }
        h3{
            font-size: 25px;
            padding-top: 50px;
            color: var(--cor-primary);
            font-weight: 700;
        }
        p{
            font-size: 18px;
        }
    }
    .card1{
        grid-column-start: 1;
        grid-column-end: 2;
    }
    .card1.visible{
        animation: fadeIn 2s ease forwards;
    }
    .card2{
        grid-column-start: 2;
        grid-column-end: 3;
    }
    .card2.visible{
        animation: fadeIn 2s .2s ease forwards;
    }
    .card3.visible{
        animation: fadeIn 2s .4s ease forwards;
    }
    .card4.visible{
        animation: fadeIn 2s .6s ease forwards;
    }
}

@media screen and (max-width: 1600px){
    .fleetSix{
        gap: 50px;
        .card{
            width: 500px;

            iframe{
                height: 280px;
            }
            h3{
                font-size: 22px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .fleetSix{
        gap: 100px;

        .card{
            width: 400px;

            iframe{
                height: 225px;
            }
            h3{
                padding-top: 20px;
                font-size: 22px;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .fleetSix{
        grid-template-columns: 100%;
        gap: 20px;
        padding: 50px 50px 0px;

        .card{
            grid-column-start: 1;
            grid-column-end: 2;
            width: 100%;
            display: grid;
            grid-template-columns: 50% 50%;
            
            .cardText{
                padding-left: 20px;
                
                h3{
                    padding-top: 20px;
                    font-size: 20px;
                }
                p{
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .fleetSix{
        .card{
            width: 450px;
            grid-template-columns: 100%;
            
            iframe{
                max-width: 450px;
                border-radius: 10px;
            }
            .cardText{
                padding: 0px;
            }
        }
        .card1, .card3{
            justify-self: left;
            justify-content: left;
        }
        .card2, .card4{
            justify-self: right;
            text-align: right;

            iframe{
                justify-self: right;
            }
        }
    }
}

@media screen and (max-width: 600px){
    .fleetSix{
        .card{
            justify-self: center !important;
            text-align: left !important;
            width: 100%;

            iframe{
                justify-self: left;
                width: 100% !important;
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 425px){
    .fleetSix{
        padding: 0px 20px;
        .card{
            .cardText{
                h3{
                    margin: 0px;
                    font-size: 18px;
                }
                p{
                    font-size: 14px;
                }
            }
        }
    }
}