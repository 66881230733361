.servicesOne {
    padding: 200px 0px 50px;
    display: grid;
    grid-template-columns: 50% 50%;

    .servicesOneText {
        h1 {
            color: var(--cor-primary);
            font-weight: 700;
            padding-left: 150px;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToRight 2s ease forwards;
        }

        span {
            color: var(--cor-secondary);
        }

        p {
            padding-top: 20px;
            width: 750px;
            line-height: 40px;
            font-size: 16px;
            padding-left: 150px;
            opacity: 0%;
        }

        p.visible {
            animation: slideToRight 2s .2s ease forwards;
        }

        img {
            position: relative;
            margin: 50px 0px 0px -50px;
            opacity: 0%;
            transition: .5s ease;
            width: 400px;
            cursor: pointer;
        }

        img:hover {
            scale: 1.08;
        }

        img.visible {
            animation: slideToLeft 2s .4s ease forwards;
        }
    }

    .servicesOneImg {
        width: fit-content;
        position: relative;
        margin: -50px 50px 0px auto;

        img {
            width: 800px;
            opacity: 0%;
            transition: .5s ease;
            cursor: pointer;
        }

        img:hover {
            scale: 1.05;
        }

        img.visible {
            animation: slideToLeft 2s .2s ease forwards;
        }
    }
    .cards {
        margin-top: -150px;
        display: flex;
        padding-left: 150px;
        width: 900px;
        gap: 5px;

        .card {
            cursor: pointer;
            display: grid;
            width: fit-content;
            height: fit-content;
            grid-template-areas: 'card';
            width: 100%;
            height: auto;
            justify-content: center;
            align-items: center;
            border: none;
            background-color: rgb(245, 245, 245);
            transition: .5s ease;

            img {
                grid-area: card;
                opacity: 100%;
                width: 125px;
                margin: 0px;
                justify-self: center;
                transition: .5s;
                opacity: 100%;
            }

            p {
                text-align: center;
                grid-area: card;
                font-size: 14px;
                margin: 0px;
                padding: 10px;
                opacity: 0%;
                width: 100%;
                line-height: 20px;
                transition: .5s;
                color: white;
            }
        }

        .card:hover {
            background-color: var(--cor-primary);

            img {
                opacity: 0%;
                ;
            }

            p {
                opacity: 100%;
                display: block;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .servicesOne {
        padding-top: 150px !important;

        .servicesOneText {
            h1 {
                padding-left: 100px;
            }

            p {
                width: 650px;
                font-size: 16px !important;
                padding-left: 100px;
            }

            img {
                margin: 0px;
                width: 300px;
            }
        }

        .servicesOneImg {
            padding-top: 50px;

            img {
                width: 600px;
            }
        }
        .servicesOneB {
            .cards {
                margin: -100px 0px 0px;
                padding-left: 100px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .servicesOne {
        padding: 150px 0px 50px;

        .servicesOneText {
            h1 {
                padding-left: 50px;
            }

            p {
                padding-left: 50px;
                width: 100%;
                max-width: 650px;
                padding-right: 0px;
            }

            img {
                width: 300px;
            }
        }

        .servicesOneImg {
            padding-top: 0px !important;
            margin: 0px 50px 0px auto;

            img {
                width: 500px;
            }
        }
        .servicesOneB {
            .cards {
                margin-top: 0px;
                padding-left: 50px;
                width: 800px;

                .card {
                    p {
                        font-size: 12px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .servicesOne {
        .servicesOneText {
            img {
                width: 100px;
            }
        }

        .servicesOneImg {
            padding-right: 50px;
            margin: 0px;
            display: grid;

            img {
                width: 80%;
                justify-self: center;
            }
        }

        .servicesOneB{
            grid-column-start: 1;
            grid-column-end: 3;
            padding-right: 50px;

            .cards{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .servicesOne {
        .servicesOneText {
            p {
                line-height: 35px;
            }
        }

        .servicesOneImg {
            padding: 0px !important;
        }


    }

}

@media screen and (max-width: 800px) {
    .servicesOne {
        .servicesOneText {
            p {
                line-height: 30px;
            }

            img {
                width: 100px;
            }
        }

        .servicesOneB {
            .cards {
                padding-top: 20px;
                display: grid;
                grid-template-columns: 50% 50%;
            }
        }

        .servicesOneImg {
            padding: 0px !important;
        }
    }
}

@media screen and (max-width: 768px) {
    .servicesOne {
        grid-template-columns: 100%;
        padding: 150px 50px 50px;

        .servicesOneText {
            h1 {
                padding: 0px;
            }

            p {
                padding: 0px;
            }
        }

        .servicesOneImg {
            display: grid;
            justify-self: right;

            img {
                width: 400px;
            }
        }
        .servicesOneB{
            grid-column-end: 2;
            padding: 0px;

            .cards{
                padding: 20px 0px 0px;
            }
        }
    }
}


@media screen and (max-width: 500px) {
    .servicesOne {
        .servicesOneImg {
            img {
                width: 100%;
            }
        }
        .servicesOneB{
            .cards{
                grid-template-columns: 100%;

                .card{
                    padding: 10px;
                    img{
                        width: 100px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .servicesOne {
        padding: 150px 20px 50px;

        .servicesOneText {
            p {
                font-size: 14px !important;
            }
        }

        .servicesOneB {
            .cards {
                .card {
                }
            }
        }
    }
}