.warehouseEleven {
    padding: 80px 250px 0px;

    display: grid;
    align-items: center;
    justify-content: right;
    grid-template-columns: auto 70%;
    column-gap: 50px;

    .elevenText {
        display: grid;
        height: fit-content;
        align-self: end;
    }

    h1 {
        grid-column-start: 2;
        grid-column-end: 3;
        color: var(--cor-secondary);
        font-weight: 700;
        max-width: 580px;
        opacity: 0%;
    }

    h1.visible {
        animation: halfToRight 2s ease forwards;
    }

    p {
        grid-column-start: 2;
        grid-column-end: 3;
        line-height: 30px;
        font-size: 18px;
        max-width: 1000px;
        opacity: 0%;
    }

    p.visible {
        animation: halfToRight 2s ease forwards;
    }

    img {
        width: 300px;
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
        opacity: 0%;
    }

    img.visible {
        animation: halfToRight 2s ease forwards;
    }
}

@media screen and (max-width: 1600px) {
    .warehouseEleven {
        padding: 100px 150px 0px;

        p {
            font-size: 16px
        }
    }
}

@media screen and (max-width: 1400px) {
    .warehouseEleven {
        padding: 50px 100px 0px;

        img {
            width: 180px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .warehouseEleven {
        padding: 30px 50px 0px;
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 768px) {
    .warehouseEleven {
        padding: 20px 50px 0px;
        grid-template-columns: 100%;

        img {
            position: relative;
            right: 0px;
            width: 300px;
            grid-row-start: 2;
            grid-row-end: 3;
        }
        .elevenText{
            grid-template-columns: 100%;

            h1, p{
                grid-column-start: 1 !important;
                grid-column-end: 2 !important;

                max-width: 100%;
            }
        }
        .text {
            p {
                line-height: 25px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .warehouseEleven{
        padding: 20px;
    }
}

@media screen and (max-width: 425px) {
    .warehouseEleven {
        img {
            top: 10px;
            width: 80%;
            justify-self: center;
        }

        p {
            font-size: 16px;
        }
    }
}