.aboutEight{
    padding: 100px 150px 0px;
    overflow: hidden;

    display: grid;
    grid-template-columns: auto auto;

    .aboutEightTitle{
        h1{
            color: var(--cor-primary);
            font-weight: 700;
            width: 350px;
            opacity: 0%;
            padding-bottom: 15px;

            span{
                color: var(--cor-secondary);
            }
        }
        h1.visible{
            animation: halfToRight 1s ease forwards;
        }
        p{
            margin: 0px;
            line-height: 30px;
            max-width: 90%;
            opacity: 0%;
        }
        p.visible{
            animation: halfToRight 1s .2s ease forwards;
        }
    }
    .aboutEightImg{
        cursor: pointer;
        display: grid;
        grid-template-areas: 'img';
        width: fit-content;
        justify-self: end;
        align-items: center;

        img{
            grid-area: img;
            opacity: 0%;
            transition: .5s ease;
        }
        .img1.visible{
            z-index: 1;
            animation: halfToRight 1s .6s ease forwards;
        }
        .img2.visible{
            z-index: 4;
            animation: halfToRight 1s .7s ease forwards;
        }
        .img3.visible{
            z-index: 2;
            animation: halfToRight 1s .8s ease forwards;
        }
        .img4.visible{
            z-index: 3;
            animation: halfToRight 1s .9s ease forwards;
        }
    }
    .aboutEightImg:hover{
        .img1, .img3, .img4{
            filter: blur(5px);
        }
        .img2{
            scale: 1.1;
        }
    }
}

@media screen and (max-width: 1600px){
    .aboutEight{
        padding: 80px 50px 0px;
    }
}

@media screen and (max-width: 1440px){
    .aboutEight{
        padding: 80px 0px 0px 50px;
        
        .aboutEightImg{
            position: relative;
            left: 80px;

            img{
                width: 700px;
            }
        }
    }
}

@media screen and (max-width: 1350px){
    .aboutEight{
        .aboutEightImg{
            img{
                width: 600px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .aboutEight{
        grid-template-columns: 100%;

        .aboutEightTitle{
            padding-top: 30px;
            padding-bottom: 20px;
            padding-right: 50px;

            p{
                max-width: 100%;
            }
        }
        .aboutEightImg{
            display: grid;
            img{
                justify-self: right;
            }
        }
    }
}
@media screen and (max-width: 550px){
    .aboutEight{
        padding: 80px 50px 0px;
        .aboutEightTitle{
            padding: 0px 0px 20px 0px;
            p{
                line-height: 25px;
            }
        }
        .aboutEightImg{
            
            img{
                width: 400px;
            }
        }
    }
}

@media screen and (max-width: 425px){
    .aboutEight{
        padding: 80px 20px 20px;
        
        .aboutEightTitle{
            h1{
                width: 100%;
            }
            padding: 0px 0px 20px;
            p{
                font-size: 14px;
            }
        }
    }
}