.servicesFive-bg{
    background-image: url("../../img/services/servicesFive/servicesFive-bg3.webp");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    padding: 230px 150px 180px;
    opacity: 0%;

    .servicesFive{
        display: flex;
        align-items: center;
        justify-content: center;

        gap: 5%;

        h1{
            color: var(--cor-secondary);
            font-weight: 700;
            max-width: 530px;
            opacity: 0%;
        }
        h1.visible{
            animation: slideToRight 2s ease forwards;
        }
        .line{
            height: 80px;
            width: 3px;
            border-radius: 5px;
            background-color: var(--cor-secondary);
            opacity: 0%;
        }
        .line.visible{
            animation: changeOpacity 2s .5s ease forwards;    
        }

        a{
            grid-template-areas: 'link';
            display: grid;
            align-items: center;
            justify-content: center;
            opacity: 0%;
            transition: .5s ease;

            img{
                grid-area: link;
                width: 250px;
                transition: .5s ease;
            }
            p{
                grid-area: link;
                color: white;
                font-size: 18px;
                justify-self: center;
                align-self: center;
                margin: 0px 0px 0px;
                transition: .5s ease;
                letter-spacing: -1px;
            }
        }
        a:hover{
            img{
                filter: blur(5px);
                scale: .9;
            }
            p{
                color: var(--cor-primary);
                scale: 1.1;
                font-weight: 600;
            }
        }
        a.visible{
            animation: slideToLeft 2s ease forwards;
        }
    }
}
.servicesFive-bg.visible{
    animation: changeOpacity 2s ease forwards;
}

@media screen and (max-width: 1600px){
    .servicesFive-bg{
        padding: 200px 50px 150px;

        .servicesFive{
            h1{
                max-width: 440px;
            }
        }
    }
}

@media screen and (max-width: 1440px){
    .servicesFive-bg{
        background-size: 1600px;
        .servicesFive{
            a{
                img{
                    width: 200px;
                }
                p{
                    margin: 5px 0px 0px;
                    font-size: 16px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .servicesFive-bg{
        padding: 150px 50px;
        background-size: 1500px;
    }
}

@media screen and (max-width: 600px){
    .servicesFive-bg{
        padding: 150px 50px;
        background-size: 2000px;

        .servicesFive{
            display: grid;
            
            h1{
                width: fit-content;
                max-width: 100%;
            }
            .line{
                height: 2px;
                width: 100px;
                justify-self: left;
            }
            a{
                justify-self: left;

                img{
                    width: 180px;
                }
                p{
                    font-size: 14px;
                    margin: 0px 0px 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px){
    .servicesFive-bg{
        padding: 150px 20px;
        .servicesFive{ 
            padding: 0px;
        }
    }
}