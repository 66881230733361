.esgTen{
    padding: 50px 150px 0px;
    display: grid;
    grid-template-columns: auto auto;
    opacity: 0%;

    .iso{
        border-radius: 5px;
        background-color: rgb(245, 245, 245);
        position: absolute;
        padding: 15px 15px 5px;
        right: 50px;
        top: 120px;
        z-index: 100;

        .img{
            img{
                width: 60px;
                transition: .5s ease;
            }
            img:hover{
                scale: 1.1;
            }
        }
        p{
            text-align: center;
            color: var(--cor-tertiary);
            font-weight: 600;
            margin: 5px 0px 0px;
        }
    }
    .esgTenText{
        max-width: 800px;
        padding-top: 80px;

        .gheco{
            h1{
                opacity: 0%;
            }
            h1.visible{
                animation: halfToUp 1s .2s ease forwards;
            }
            p{
                opacity: 0%;
                color: var(--cor-primary);
                font-weight: 600;
                font-size: 18px;
            }
            p.visible{
                animation: halfToUp 1s .3s ease forwards;
            }
        }
        h1{
            color: var(--cor-primary);
            font-weight: 700;

            span{
                color: #3A904F;
            }
        }
        h2{
            padding-top: 20px;
            font-weight: 700;
            font-size: 24px !important;
            color: var(--cor-tertiary);
            opacity: 0%;
        }
        h2.visible{
            animation: halfToUp 1s .4s ease forwards;
        }
        p{
            font-size: 18px;
            opacity: 0%;
        }
        p.visible{
            animation: halfToUp 1s .5s ease forwards;
        }
    }
    .esgTenLogo{
        display: grid;
        grid-template-areas: 'img';

        .img{
            grid-area: img;
            width: 300px;
            opacity: 0%;
        }
        img.visible{
            animation: halfToUp 1s .2s ease forwards;
        }
        .link1.visible{
            animation: halfToUp 1s .3s ease forwards;
        }
        .link2.visible{
            animation: halfToUp 1s .4s ease forwards;
        }
        .link3.visible{
            animation: halfToUp 1s .5s ease forwards;
        }
        .link4.visible{
            animation: halfToUp 1s .6s ease forwards;
        }
        .link5.visible{
            animation: halfToUp 1s .7s ease forwards;
        }
        .links{
            padding-top: 20px;
            display: grid;
            gap: 10px;
            
            a{
                color: var(--cor-primary);
                display: flex;
                height: fit-content;
                gap: 10px;
                opacity: 0%;

                img{
                    width: 20px;
                    height: 24px;
                }
                p{
                    font-weight: 700;
                    margin: 0px;
                }
            }
        }
    }
}

.esgTen.visible{
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .esgTen{
        .esgTenText{
            max-width: 600px;
            justify-self: center;
            h2{
                font-size: 22px !important;
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1440px){
    .esgTen{
        padding: 50px 100px 0px;
        gap: 50px;
        
        .iso{
            padding: 10px 10px 5px;
            .img{
               img{
                width: 50px;
               } 
            }
            p{
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .esgTen{
        padding: 50px 50px 20px;
    }
}

@media screen and (max-width: 1000px){
    .esgTen{
        padding-top: 30px;
        grid-template-columns: 100%;
        gap: 20px;

        .iso{
            top: 105px;
            right: 10px;
        }
        .esgTenLogo{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
            grid-template-columns: auto auto;
            justify-self: left;
            gap: 50px;

            .links{
                height: fit-content;
                gap: 20px;
            }
        }
        .esgTenText{
            padding-top: 0px;
            max-width: 100%;
            justify-self: left;
        }
    }
}

@media screen and (max-width: 768px){
    .esgTen{
        padding: 40px 50px 20px 50px;

        .iso{
            .img{
                display: grid;
            }
            p{
                display: none;
            }
        }
        .esgTenLogo{
            gap: 20px;
        }
    }
}

@media screen and (max-width: 700px){
    .esgTen{
        gap: 0px;
        .esgTenLogo{
            .gheco{
                width: 100%;
            }
            .links{
                img{
                    display: block;
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
    .esgTen{
        .esgTenLogo{
            grid-template-columns: 100%;
            width: 100%;

            img{
                width: 100%;
                max-width: 300px;
            }
            .links{
                gap: 5px;
            }
        }
        .esgTenText{
            max-width: 100%;
            h2{
                padding-top: 5px;
            }
        }
    }
}

@media screen and (max-width: 425px){
    .esgTen{
        padding: 60px 20px 20px 20px;

        .iso{
            right: 0px;
            padding: 5px;
            .img{
                img{
                    width: 40px;
                }
            }
        }
        .esgTenText{
            h2{
                font-size: 18px !important;
            }
            p{
                font-size: 14px;
            }
        }
        .esgTenLogo{
            width: 100%;

            img{
                width: 100%;
            }
            .links{
                padding-top: 10px;
                gap: 10px;
                a{
                    font-size: 15px;
                }
            }
        }
    }
}