.homeCareer {
    padding: 50px 150px 300px;
    display: grid;
    justify-content: center;
    text-align: center;
    background-image: url("../../img/home/career/career-bg2.webp");
    background-size: 2000px;
    background-repeat: no-repeat;
    background-position: 50%;
    opacity: 0%;

    .careerTitle {
        padding: 50px 20px 0px;

        h1 {
            font-size: 40px !important;
            font-weight: 800;
            color: var(--cor-primary);
            opacity: 0%;
        }

        h1.visible {
            animation: halfToUp 1s .1s ease forwards;
        }
    }

    .careerContent {
        display: grid;
        grid-template-columns: auto auto auto;

        .arrows1,
        .arrows2 {
            width: fit-content;
            position: absolute;
            display: grid;

            img {
                opacity: 0%;
            }

            .img1 {
                animation: arrows 2s ease infinite;
            }

            .img2 {
                animation: arrows 2s .3s ease infinite;
            }

            .img3 {
                animation: arrows 2s .6s ease infinite;
            }

            .img4 {
                animation: arrows 2s .9s ease infinite;
            }
        }

        .arrows1 {
            left: 3%;
            transform: translateY(500px);
        }

        .arrows2 {
            right: 3%;
            transform: translateY(80px);
        }

        .text {
            padding-top: 150px;
            display: grid;

            p {
                max-width: 450px;
                color: white;
                font-size: 18px;
                font-weight: 300;
                opacity: 0%;
            }

            p.visible {
                animation: halfToUp 1s .2s ease forwards;
            }

            a {
                position: relative;
                top: 80px;
                justify-self: center;
                display: grid;
                align-items: center;
                justify-content: center;
                height: fit-content;
                width: fit-content;
                padding: 5px;
                border-radius: 5px;
                border: 2px solid rgba(255, 255, 255, 0);
                transition: .4s ease;
                opacity: 100%;

                button {
                    align-items: center;
                    justify-content: center;
                    color: white;
                    font-weight: 600;
                    background-color: var(--cor-tertiary);
                    width: 400px;
                    height: 60px;
                    border-radius: 5px;
                    transition: .5s ease;
                    border: none;
                }
            }

            a.visible {
                animation: halfToUp 1s .2s ease forwards;
            }

            a:hover {
                border: 2px solid var(--cor-tertiary);
                border-radius: 5px;
                scale: .9;

                button {
                    background-color: var(--cor-tertiary);
                    border-radius: 5px;
                }
            }

        }
    }
}

.homeCareer.visible {
    animation: fadeIn 1s ease forwards;
}

@media screen and (min-width: 2000px) {
    .homeCareer {
        background-size: 100% 90%;
        background-position: 50% 50%;
    }
}

@media screen and (max-width: 1600px) {
    .homeCareer {
        background-image: url("../../../assets/img/home/career/career-bg2.webp");
        background-size: 2000px 700px;
        background-position: 50%;
        padding: 50px 150px 250px;

        .careerTitle {
            h1 {
                font-size: 35px !important;
            }
        }

        .careerContent {
            .text {
                padding: 130px 0px 0px;

                p {
                    font-size: 16px;
                }

                .careerBtn {
                    transform: translateY(120px);

                    img {
                        width: 300px;
                    }

                    .button {
                        p {
                            top: 20px;
                        }

                        .underline {
                            bottom: 15px;
                        }
                    }
                }
            }

            .arrows1 {
                transform: translateY(400px);

                img {
                    width: 150px;
                }
            }

            .arrows2 {
                transform: translateY(30px);

                img {
                    width: 150px;
                }
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .homeCareer {
        .careerTitle {
            h1 {
                font-size: 30px !important;
            }
        }

        .careerContent {
            .arrows2 {
                transform: translateY(0px);
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .homeCareer {
        padding: 0px 150px 150px;
        background-size: 2000px 500px;

        .careerContent {
            .text {
                padding-top: 80px;

                a {
                    top: 30px;

                    button {
                        width: 300px;
                    }
                }
            }

            .arrows1 {
                transform: translateY(320px);

                img {
                    width: 120px;
                }
            }

            .arrows2 {
                transform: translateY(-50px);

                img {
                    width: 120px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .homeCareer {
        .careerContent {
            .arrows1 {
                left: -20px;
                transform: translateY(280px);
            }

            .arrows2 {
                right: 0px;
            }

            .text {
                p {
                    min-width: 400px;
                }

                .careerBtn {
                    transform: translateY(50px);

                    img {
                        width: 250px;
                    }

                    .button {
                        p {
                            min-width: 0px;
                            font-size: 16px;
                            top: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .homeCareer {
        background-size: 2000px 600px;

        .careerTitle {
            h1 {
                font-size: 25px !important;
            }
        }

        .careerContent {
            .text {
                p {
                    min-width: 300px;
                }
            }

            .arrows2 {
                right: 0px;
                transform: translateY(-10px);

                img {
                    width: 80px;
                }
            }

            .arrows1 {
                transform: translateY(250px);
                left: -50px;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeCareer {
        padding: 0px 20px 100px;
        background-size: 2000px 450px;
        background-position: 45% 40%;

        .careerContent {
            .text {
                padding-top: 40px;

                p {
                    font-size: 14px;
                }

                .careerBtn {
                    transform: translateY(35px);
                }
            }

            .arrows1 {
                transform: translateY(200px);
                left: -80px;
            }

            .arrows2 {
                transform: translateY(-100px);
            }
        }
    }
}