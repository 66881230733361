.esgSix{
    display: grid;
    background-image: url("../../img/esg/esgSix/esgSix-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    padding: 50px 150px 0px;
    opacity: 0%;

    img{
        grid-column-start: 1;
        grid-column-end: 2;
        opacity: 0%;
    }
    img.visible{
        animation: halfToRight 1s ease forwards;
    }
    .esgSixTitle{
        padding-top: 100px;
        grid-column-start: 2;
        grid-column-end: 3;
        display: grid;
        height: fit-content;
    
        h1{
            opacity: 0%;
            width: 500px;
            font-weight: 700;
            color: var(--cor-secondary);
        }
        h1.visible{
            animation: halfToRight 1s .1s ease forwards;
        }
        p{
            opacity: 0%;
            font-size: 18px;
            width: 500px;
        }
        p.visible{
            animation: halfToRight 1s .2s ease forwards;
        }
        .tampinhas{
            width: fit-content;
            display: grid;
            grid-template-areas: 'card';
            position: relative;
            left: 50px;
            justify-content: center;
            align-items: center;
            justify-self: left;
            cursor: pointer;
            
            img{
                transition: .5s;
                grid-area: card;
                max-width: 400px;
            }
            img.visible{
                animation: halfToUp 1s .4s ease forwards;
            }
            p{
                transition: .5s;
                width: 400px;
                text-align: center;
                z-index: 100;
                color: white;
                grid-area: card;
                font-size: 22px;
                font-weight: 500;
                letter-spacing: -1px;
                line-height: 25px;
                color: var(--cor-primary);

                span{
                    font-size: 35px;
                    font-weight: 800;
                    letter-spacing: -2px;
                }
            }
            p.visible{
                animation: slideToUp 1s .8s ease forwards;
                
                span{
                    animation: slideToUp 1s 1s ease forwards;
                }
            }
        }
        .tampinhas:hover{
            img{
                filter: blur(5px);
                transform: translateX(20px);
            }
            p{
                scale: 1.1;
            }
        }
    }
}
.esgSix.visible{
    animation: changeOpacity 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .esgSix{
        padding: 50px 150px 50px;
        background-position: 0% 50%;
    
        img{
            width: 400px;
        }
        .esgSixTitle{
            padding-top: 50px;

            p{
                font-size: 16px;
            }

            .tampinhas{
                img{
                    width: 350px;
                }
                p{
                    width: 350px;
                    font-size: 18px;
                    span{
                        font-size: 30px
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px){
    .esgSix{
        padding: 50px 50px 50px;
    }
}

@media screen and (max-width: 1200px){
    .esgSix{
        gap: 100px;
        img{
            width: 350px;
        }
        .esgSixTitle{
            h1{
                width: 100%;
            }
            p{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .esgSix{
        padding: 50px 50px 50px;
        img{
            width: 300px;
        }
        .esgSixTitle{
            padding-top: 20px;
        
            .tampinhas{
                left: 0px;
                justify-self: right;

            }    
        }
    }
}

@media screen and (max-width: 900px){
    .esgSix{
        .esgSixTitle{
            .tampinhas{
                img{
                    width: 100%;
                    min-width: 320px;
                }
                p{
                    width: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .esgSix{
        gap: 20px;

        .sixImg{
            width: 350px;
            margin-top: -50px;
        }
        .esgSixTitle{
            grid-column-start: 1;
            grid-column-end: 2;

            .tampinhas{
                img{
                    width: 350px;
                }
            }
        }
        img{
            grid-row-start: 2;
            grid-row-end: 3;

        }
    }
}

@media screen and (max-width: 600px){
    .esgSix{
        padding: 80px 50px 0px;
        img{
            width: 250px;
        }
        .esgSixTitle{
            padding: 0px;
        }
    }
}

@media screen and (max-width: 600px){
    .esgSix{
        .esgSixTitle{
            .tampinhas{
                bottom: 30px;
            }
        }
    }
}
@media screen and (max-width: 425px){
    .esgSix{
        padding: 60px 20px 0px;
        gap: 0px;

        .sixImg{
            position: relative;
            top: -50px;
            width: 100%;
        }
        img{
            width: 100%;
        }
        .esgSixTitle{
            p{
                font-size: 14px;
            }
            .tampinhas{
                img{
                    width: 300px;
                }
                p{
                    span{
                        font-size: 25px;
                    }
                    line-height: 18px;
                    font-size: 16px;
                }
            }
        }
    }
}

