.contactOne{
    padding: 200px 150px 20px;
    display: grid;
    grid-template-columns: 50% 50%;
    background-image: url("../../img/contact/contactOne/contactOne-bg.webp");
    background-size: 500px;
    background-position: 110% 100%;
    background-repeat: no-repeat;
    opacity: 0%;

    .contactOneText{
        padding-right: 50px;
        h1{
            color: var(--cor-secondary);
            font-weight: 700;
            width: 300px;
            margin: 0px;
            padding-bottom: 20px;
            opacity: 0%;

            span{
                color: var(--cor-primary);
            }
        }
        h1.visible{
            animation: fadeIn 1s .2s ease forwards;
        }
        p{
            font-size: 18px;
            width: 600px;
            margin: 0px;
            opacity: 0%;
        }
        p.visible{
            animation: fadeIn 1s .3s ease forwards;
        }
        .address{
            padding-top: 20px;
            color: var(--cor-primary);
            font-weight: 400;
            opacity: 0%;
        }
        .address.visible{
            animation: fadeIn 1s .4s ease forwards;
        }
        .email{
            padding-top: 20px !important;

            img{
                width: 25px;
                height: 25px;
            }
        }
        .email.visible{
            animation: fadeIn 1s .5s ease forwards;
        }
        .phone{
            img{
                width: 25px;
                height: 25px;
            }
        }
        .phone.visible{
            animation: fadeIn 1s .6s ease forwards;
        }
        .phone2{
            img{
                width: 25px;
            }
        }
        .phone2.visible{
            animation: fadeIn 1s .7s ease forwards;
        }
        .email, .phone, .phone2{
            padding-top: 10px;
            align-items: center;
            gap: 10px;
            display: flex;
            opacity: 0%;

            p{
                color: var(--cor-primary);
                margin: 0px;
                font-weight: 400;
                opacity: 100%;
            }
        }
    }
    form{
        padding: 50px 20px;
        -webkit-backdrop-filter: blur(10.5px);
        backdrop-filter: blur(5px);
        background-color: rgba(255, 255, 255, 0.849);
        box-shadow: 7px 10px 10px 2px rgba(0, 0, 0, 0.246);
        border-radius: 20px;
        width: 500px;
        justify-self: right;
        opacity: 0%;
        
        label{
            margin: 0px !important;
            color: var(--cor-primary);
        }
        input{
            line-height: 1.2;
            border: 2px solid var(--cor-secondary);
            margin-bottom: 10px;
        }
        textarea{
            border: 2px solid var(--cor-secondary);
        }
        button{
            width: 100%;
            background-color: var(--cor-primary);
            border: none;
        }
        button:hover{
            background-color: var(--cor-tertiary);
        }
    }
    form.visible{
        animation: fadeIn 1s .5s ease forwards;
    }
}
.contactOne.visible{
    animation: fadeIn 2s ease forwards;
}

@media screen and (max-width: 1600px) {
    .contactOne{
        padding: 200px 50px 20px;

        .contactOneText{
            p{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .contactOne{
        padding: 150px 50px 20px;
        
        .contactOneText{
            p{
                width: 100%;
            }
            .address, .email, .phone, .whats{
                font-size: 15px;
                p{
                    font-size: 15px !important;
                }
            }
        }
        form{
            width: 450px;

            label, input{
                font-size: 14px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .contactOne{
        background-size: 400px;

        form{
            max-width: 450px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    .contactOne{
        grid-template-columns: 100%;

        .contactOneText{
            padding: 0px;
            
            p{
                max-width: 600px;
            }
        }
        .contacts{
            display: flex;
            gap: 5%;

            .phone, .whats{
                padding-top: 20px;
            }
        }
        form{
            margin-top: 50px;
            padding: 30px 20px;
            max-width: 450px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .contactOne{
        padding-top: 130px;
        .contacts{
            gap: 0px;
            display: grid;

            .phone, .whats{
                padding-top: 5px;
            }
        }
        form{
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 500px){
    .contactOne{
        padding: 130px 20px 20px;
    }
}

@media screen and (max-width: 425px){
    .contactOne{
        .contactOneText{
            h1{
                width: 100%;
            }
            p{
                font-size: 14px;
            }
        }
        .contacts{
            img{
                width: 20px !important;
                height: 20px !important;
            }

            .address{
                font-size: 14px !important;
            }

            .email, .phone, .whats{
                p{
                    font-size: 14px !important;
                }
            }
        }
        form{
            padding: 30px 10px;
            input, textarea{
                border: 1px solid var(--cor-secondary);
            }
        }
    }
}