.warehouseThree {
    padding: 40px 0px 0px;
    display: grid;
    gap: 30px;
    opacity: 0%;

    .warehouse-bg {
        position: absolute;
        width: 300px;
        right: 0px;
        top: 1400px;
    }

    h1 {
        font-weight: 700;
        color: var(--cor-primary);
        padding: 0px 150px;
        width: 900px;
        margin: 0px;
        opacity: 0%;
    }

    h1.visible {
        animation: slideToDown 1s .1s ease forwards;
    }

    p {
        font-size: 18px;
        padding: 0px 150px;
        width: 1100px;
        line-height: 35px;
        margin: 0px;
        opacity: 0%;
    }

    p.visible {
        animation: slideToDown 1s .2s ease forwards;
    }

    img {
        justify-self: center;
        width: 60%;
        opacity: 0%;
    }

    img.visible {
        animation: fadeIn 3s 1s ease forwards;
    }
}

.warehouseThree.visible {
    animation: changeOpacity 1s ease forwards;
}

@media screen and (max-width: 1600px) {
    .warehouseThree {
        .warehouse-bg {
            top: 1100px;
            width: 250px;
        }

        h1 {
            padding: 0px 50px;
        }

        p {
            padding: 0px 50px;
            font-size: 16px;
            line-height: 30px;
        }
    }
}

@media screen and (max-width: 1300px) {
    .warehouseThree {
        .warehouse-bg {
            top: 1050px;
            width: 200px;
        }

        img {
            width: 70%;
        }
    }
}

@media screen and (max-width: 1100px) {
    .warehouseThree {
        h1 {
            width: 100%;
        }

        p {
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    .warehouseThree {
        .warehouse-bg {
            display: none;
        }
    }
}

@media screen and (max-width: 768px) {
    .warehouseThree {
        gap: 20px;

        img {
            width: 90%;
        }
    }
}

@media screen and (max-width: 500px) {
    .warehouseThree {
        padding: 50px 0px 0px;

        p,
        h1 {
            padding: 0px 20px;
        }
    }
}

@media screen and (max-width: 425px) {
    .warehouseThree {
        p {
            font-size: 14px;
        }
    }
}