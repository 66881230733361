.warehouseTen {
    padding: 30px 150px 100px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 100px;
    background-image: url("../../img/warehouseStructure/warehouseTen/ten-bg.webp");
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: 100%;
    z-index: 1000;
    opacity: 0%;

    .nineText {
        max-width: 700px;

        h1 {
            color: var(--cor-primary);
            font-weight: 700;
            opacity: 0%;

            span {
                font-size: 32px;
                color: var(--cor-secondary);
            }
        }

        h1.visible {
            animation: halfToLeft 1s .2s ease forwards;
        }

        p {
            padding: 50px 0px;
            font-size: 18px;
            line-height: 35px;
            opacity: 0%;
        }

        p.visible {
            animation: halfToLeft 1s .4s ease forwards;
        }
    }

    .nineImg {
        display: grid;

        img {
            transition: .5s ease;
            cursor: pointer;
        }

        img:hover {
            scale: 1.1;
        }
        .img2 {
            position: relative;
            bottom: 20px;
            opacity: 0%;
            width: 650px;
        }

        .img2.visible {
            animation: halfToLeft 1s .8s ease forwards;
        }
    }
}

.warehouseTen.visible {
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px) {
    .warehouseTen {
        padding: 0px 100px 50px;
        background-size: 1600px;

        .nineText {
            p {
                font-size: 16px;
            }
        }

        .nineImg {
            .img2{
                width: 550px;
            }
        }
    }
}

@media screen and (max-width: 1350px) {
    .warehouseTen {
        background-size: 1500px;
        padding-bottom: 0px;

        .nineText {
            p {
                line-height: 30px;
            }
        }

        .nineImg {
            .img2 {
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .warehouseTen {
        padding: 0px 50px 50px 50px;
        grid-template-columns: 100%;
        gap: 0px;
        background-image: none;

        .nineText {
            max-width: 100%;

            p {
                padding: 30px 0px 0px;
            }
        }

        .nineImg {
            background-image: url('../../img/warehouseStructure/warehouseNine/imgBg.png');
            background-repeat: no-repeat;
            background-size: 300px;
            background-position: 50% 80%;
            width: fit-content;
            grid-row-start: 2;
            grid-row-end: 3;

            .img2{
                inset: 0px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .warehouseTen {
        padding: 0px 50px 50px 50px;

        .nineImg{
            .img2{
                width: 100%;
            }
        }
    } 
}



@media screen and (max-width: 550px) {
    .warehouseTen {
        padding-top: 20px;

        .nineImg {
            background-size: 200px;
            img {
                width: 200px;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .warehouseTen {
        padding: 30px 20px;
        background-size: 1200px;

        .nineText {
            h1 {
                span {
                    font-size: 25px;
                }

                margin: 0px;
            }

            p {
                padding-top: 20px;
                font-size: 14px;
                line-height: 25px;
            }
        }
    }
}