.careerThree{
    background-image: url("../../img/career/careerThree/careerThree.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 300px 150px 200px;
    opacity: 0%;

    h1{
        opacity: 0%;
        color: var(--cor-secondary);
        font-weight: 700;
        padding-bottom: 10px;
    }
    h1.visible{
        animation: halfToDown 1s .2s ease forwards;
    }
    p{
        opacity: 0%;
        max-width: 1000px;
        font-size: 18px;
        color: rgb(223, 223, 223);
        font-weight: 300;
        padding-bottom: 10px;
    }
    p.visible{
        animation: halfToDown 1s .4s ease forwards;
    }
    a{
        display: grid;
        height: fit-content;
        width: fit-content;
        opacity: 0%;

        button{
            width: 400px;
            height: 60px;
            background-color: var(--cor-secondary);
            border: none;
            border-radius: 10px;
            color: white;
            font-weight: 600;
            transition: .4s ease;
            border: 2px solid rgba(255, 255, 255, 0);
        }
    }
    a.visible{
        animation: halfToDown 1s .6s ease forwards;
    }
    a:hover{
        button{
            background-color: rgba(255, 255, 255, 0.815);
            color: var(--cor-secondary);
            border: 2px solid var(--cor-secondary);
            scale: .95;
        }
    }
}
.careerThree.visible{
    animation: fadeIn 2s ease forwards;
}

@media screen and (max-width: 1600px){
    .careerThree{
        padding: 300px 50px 200px;

        p{
            font-size: 16px;
        }
        a{
            button{
                width: 350px;
                height: 50px;
            }
        }
    }
}

@media screen and (max-width: 500px){
    .careerThree{
        padding: 150px 20px 120px;
        background-size: 1500px;

        p{
            font-size: 16px;
        }
        a{
            button{
                width: 350px;
                height: 50px;
            }
        }
    }
}

@media screen and (max-width: 425px){
    .careerThree{
        p{
            font-size: 14px;
        }
        a{
            width: 100%;
            button{
                max-width: 300px;
                width: 100%;
                font-size: 14px;
            }
        }
    }
}