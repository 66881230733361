.warehouseTwo {
    background-image: url("../../img/warehouseStructure/warehouseTwo/warehouseTwo-bg.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    padding: 300px 150px 200px;
    opacity: 0%;
    display: grid;

    .arrows1,
    .arrows2 {
        width: fit-content;
        position: absolute;
        display: grid;
        top: -50%;
        left: 10%;
        transform: translateY(500px);

        img {
            opacity: 0%;
        }

        .img1 {
            animation: arrows 2s ease infinite;
        }

        .img2 {
            animation: arrows 2s .3s ease infinite;
        }

        .img3 {
            animation: arrows 2s .6s ease infinite;
        }

        .img4 {
            animation: arrows 2s .9s ease infinite;
        }
    }

    .arrows1 {
        opacity: 50%;
    }

    .arrows2 {
        top: -20%;
        left: 25% !important;
    }

    h1 {
        text-align: right;
        opacity: 0%;
        color: var(--cor-secondary);
        font-weight: 700;
        padding-bottom: 10px;
    }

    h1.visible {
        animation: halfToDown 1s .2s ease forwards;
    }

    p {
        text-align: right;
        justify-self: right;
        opacity: 0%;
        max-width: 1000px;
        font-size: 18px;
        color: rgb(223, 223, 223);
        font-weight: 300;
        padding-bottom: 10px;
    }

    p.visible {
        animation: halfToDown 1s .2s ease forwards;
    }

    a {
        display: grid;
        height: fit-content;
        width: fit-content;
        opacity: 0%;

        button {
            width: 400px;
            height: 60px;
            background-color: var(--cor-secondary);
            border: none;
            border-radius: 10px;
            color: white;
            font-weight: 600;
            transition: .4s ease;
            border: 2px solid rgba(255, 255, 255, 0);
        }
    }

}

.warehouseTwo.visible {
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px) {
    .warehouseTwo {
        padding: 300px 50px 200px;

        p {
            font-size: 16px;
        }

        a {
            button {
                width: 350px;
                height: 50px;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .warehouseTwo {
        .arrows1 {
            left: 2%;

            img {
                width: 120px;
            }
        }

        .arrows2 {
            left: 12% !important;

            img {
                width: 90px;
            }
        }

        p {
            max-width: 800px;
        }
    }
}


@media screen and (max-width: 1200px) {
    .warehouseTwo {
        padding: 230px 50px 150px;
        background-size: 1600px;

        .arrows1 {
            img {
                width: 100px;
            }

            top: -400px;
        }

        .arrows2 {
            top: -150px;

            img {
                width: 80px;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .warehouseTwo {
        padding: 200px 50px 150px;
        background-size: 1600px;

        .arrows1 {
            top: -470px;
        }

        .arrows2 {
            img {
                width: 60px;
            }

            right: 20px !important;
            left: auto !important;
            top: -50px;
        }
    }
}

@media screen and (max-width: 500px) {
    .warehouseTwo {
        padding: 200px 20px 120px;
        background-size: 1800px;

        .arrows1 {
            display: none;
        }

        .arrows2 {
            top: -70px;
        }

        p {
            font-size: 16px;
        }

        a {
            button {
                width: 350px;
                height: 50px;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .warehouseTwo {
        padding: 100px 20px 60px;
        background-size: 1600px;

        .arrows2 {
            top: -170px;
        }

        p {
            font-size: 14px;
            width: 100%;
        }

        h1 {
            width: 100%;
            max-width: 100%;
        }

        a {
            width: 100%;

            button {
                width: 100%;
            }
        }
    }
}