.aboutTwo{
    width: 100%;
    display: grid;
    justify-content: center;
    padding: 100px 0px 200px;
    background-image: url('../../img/about/aboutTwo/aboutTwo-bg.webp');
    background-repeat: no-repeat;
    background-size: 1920px;
    opacity: 0%;

    iframe{
        border-radius: 30px;
        opacity: 0%;
        transition: .5s ease;
    }
    iframe:hover{
        scale: 1.08;
        border-radius: 10px;
    }
    iframe.visible{
        animation: halfToUp 2s ease forwards;
    }
}
.aboutTwo.visible{
    animation: halfToUp 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .aboutTwo{
        background-size: cover;
    }
}

@media screen and (max-width: 1200px){
    .aboutTwo{
        padding: 100px 150px;
        background-position: 50% !important;

        iframe{
            width: 600px;
            height: 260px;
        }
    }
}

@media screen and (max-width: 768px){
    .aboutTwo{
        padding: 50px 0px;

        iframe{
            width: 80vw;
        }
    }
}

@media screen and (max-width: 500px){
    .aboutTwo{
        iframe{
            height: 200px;
        }
    }
}

@media screen and (max-width: 425px){
    .aboutTwo{
        iframe{
            width: 90vw;
            height: 200px;
        }
    }
}