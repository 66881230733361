.homeFeed {
    padding: 50px 150px 50px 150px;
    width: 100vw;

    display: grid;
    justify-content: center;

    background-image: url('../../../assets/img/home/feed/feed-bg.webp');
    background-size: cover;
    background-repeat: no-repeat;

    .homeFeedTitle {
        text-align: center;
        padding: 0px 25%;

        h1 {
            color: var(--cor-primary);
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToUp 1s ease forwards;
        }
    }

    .homeFeed2 {
        padding-top: 50px !important;
        padding-top: 100px;
        width: 100vw;
        display: grid;
        grid-template-areas: 'homeFeed2';

        .phone {
            grid-area: homeFeed2;
            width: 100%;
            display: grid;
            justify-content: center;
            padding-bottom: 70px;
            height: fit-content;
            opacity: 0%;

            img {
                height: auto;
                width: auto;
            }
        }

        .phone.visible {
            animation: changeOpacity 1s 1s ease forwards;
        }

        .swiper {
            width: 965px;
            grid-area: homeFeed2;
            opacity: 0%;
        }

        .swiper.visible {
            animation: halfToUp 1s .3s ease forwards;
        }

        .swiper-slide {
            transition: .5s ease;
            text-align: center;
            font-size: 18px;

            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;

            .card-media {
                display: grid;
                gap: 20px;
                width: 100%;
                transition: .5s ease;

                img {
                    width: 100%;
                    height: 100%;
                }

                .media-text {
                    transition: 0.6s ease-in-out;
                    align-items: center;
                    display: grid;
                    height: 100%;
                    min-width: 0px;
                    max-width: 100%;
                    padding: 0px 5px;

                    p {
                        text-align: left;
                        font-size: 13px;
                        font-weight: 500;
                        overflow: hidden;
                        box-sizing: border-box;
                        margin: 0px;
                        min-width: 0px;
                        display: -webkit-box;
                        max-width: 100%;
                        text-overflow: ellipsis;
                        white-space: normal;
                        overflow-wrap: normal;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;
                        line-height: 1.3;
                        justify-self: center;
                        max-height: 148px;
                        z-index: 5;
                        color: rgb(15, 0, 0);
                        font-weight: 400;
                        transition: 0.6s ease-in-out;
                    }

                    a {
                        font-size: 14px;
                        width: 100%;
                        color: var(--cor-secondary);
                        text-align: left;
                    }
                }
            }
        }

        .swiper-slide-next {
            .card-media {
                gap: 40px;

                .media-text {
                    padding: 0px 15px;
                }
            }
        }
    }
}

@media screen and (min-width: 2000px) {
    .homeFeed {
        background-position: 50%;
        background-size: 100% 130%;
    }
}

@media screen and (max-width: 1600px) {
    .homeFeed {
        padding: 50px 50px 0px 50px;
        height: 700px !important;
        background-position: 100% 50%;

        .homeFeed2 {
            padding-top: 50px;
            height: 60% !important;

            .swiper {
                height: 70% !important;
                width: 705px;

                .card-media {
                    padding-top: 0px;
                    gap: 20px;

                    img {
                        
                    }
                }

                .media-text {
                    p {
                        font-size: 10px !important;
                    }

                    a {
                        font-size: 8px !important;
                    }
                }
            }

            .phone {
                height: 70% !important;

                img {
                    height: 70% !important;
                    width: auto;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .homeFeed {
        padding-bottom: 0px !important;

        .homeFeedTitle {
            padding: 0px 20% !important;
        }
    }
}

@media screen and (max-width: 1200px) {
    .homeFeed {
        background-position: 0%;

        .homeFeedTitle {
            padding: 0px 20% !important;
        }
    }
}

@media screen and (max-width: 1000px) {
    .homeFeed {
        background-position: 50%;

        .homeFeedTitle {
            padding: 0px 20% !important;
        }
    }
}

@media screen and (max-width: 850px) {
    .homeFeed {
        padding: 0px 150px 0px 150px;
    }
}

@media screen and (max-width: 768px) {
    .homeFeed {
        background-position: 0%;
        padding: 20px 150px 0px 150px;
        height: fit-content !important;

        .homeFeedTitle {
            padding: 0px 50px !important;
        }

        .homeFeed2 {
            padding: 50px 0px;
            padding-bottom: 0px;
            height: fit-content !important;

            .swiper {
                height: 100% !important;
                width: 288px !important;

                .card-media {
                    padding-top: 5px;
                    gap: 35px;

                    img {
                        /* max-height: 288px; */
                    }
                }

                .media-text {
                    p {
                        font-size: 12px !important;
                        -webkit-line-clamp: 4 !important;
                    }

                    a {
                        font-size: 12px !important;
                    }
                }

                .swiper-slide {
                    .card-media {
                        .media-text {

                            p,
                            a {
                                padding: 0px 10px;
                            }
                        }
                    }
                }
            }

            .phone {
                height: 100% !important;

                img {
                    backdrop-filter: blur(5.5px);
                    background-color: hsla(0, 0%, 100%, .349);
                    border-radius: 50px;
                    height: 100% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeFeed {
        .homeFeedTitle {
            padding: 0px 20px !important;
        }
    }
}

@media screen and (max-width: 350px) {
    .homeFeed {
        .homeFeed2 {
            .swiper {
                width: 85% !important;
            }

            .phone {
                img {
                    width: 100%;
                }
            }
        }
    }
}