.homeWelcome {
    padding: 0px 150px 0px;
    background-image: url(../../img/home/welcome/welcome-bg.webp);
    background-repeat: no-repeat;
    background-size: cover;

    .homeWelcomeTitle {
        width: 100%;
        display: grid;
        text-align: center;

        h1 {
            color: var(--cor-secondary);
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: halfToRight 1s ease forwards;
        }

        p {
            color: var(--cor-primary);
            font-weight: 700;
            font-size: 25px;
            width: 1200px;
            justify-self: center;
            opacity: 0%;
        }

        p.visible {
            animation: slideToRight 1s .1s ease forwards;
        }
    }

    .homeWelcome2 {
        .swiper {
            padding: 50px 0px;

            .swiper-button-next,
            .swiper-button-prev {
                opacity: 0%;
            }

            .swiper-button-next:after,
            .swiper-button-prev::after {
                display: none;
            }

            .swiper-slide {
                scale: .9;
                transition: .5s ease;


                .card {
                    background: none !important;
                    width: 100%;
                    display: grid;
                    align-items: center;
                    justify-content: center;
                    border: none;
                    opacity: 0%;
                    grid-template-areas: 'card';

                    img {
                        justify-self: center;
                        grid-area: card;
                        width: 350px;
                        z-index: 10;
                        transition: .5s ease;
                        cursor: pointer;
                    }

                    .imgBg {
                        transition: .5s ease;
                        opacity: 0%;
                        z-index: 5;
                        width: 380px;
                    }
                }

                .card:hover {

                    .imgBg,
                    img {
                        scale: 1.1;
                    }
                }
            }

            .swiper-slide.visible {
                animation: slideToUp 2s .7s ease forwards;
            }

            .swiper-slide-next {
                scale: 1.1;

                .imgBg {
                    opacity: 100% !important;
                }
            }
        }

        .swiper.visible {

            .swiper-button-next,
            .swiper-button-prev {
                animation: changeOpacity 2s .8s ease forwards;
            }

            .card1 {
                animation: halfToUp 2s .1s ease forwards;
            }

            .card2 {
                animation: halfToUp 2s .2s ease forwards;
            }

            .card3 {
                animation: halfToUp 2s .3s ease forwards;
            }

            .card4 {
                animation: halfToUp 2s .4s ease forwards;
            }

            .card5 {
                animation: halfToUp 2s .5s ease forwards;
            }

            .card6 {
                animation: halfToUp 2s .6s ease forwards;
            }

            .card7 {
                animation: halfToUp 2s .7s ease forwards;
            }

            .card8 {
                animation: halfToUp 2s .8s ease forwards;
            }

            .card9 {
                animation: halfToUp 2s .9s ease forwards;
            }
        }
    }
}

@media screen and (min-width: 2000px) {
    .homeWelcome {
        background-size: cover;
        background-position: 100% 25%;
    }
}

@media screen and (max-width: 1600px) {
    .homeWelcome {
        padding-top: 0px;

        .homeWelcomeTitle {
            p {
                width: 100%;
            }
        }

        .homeWelcome2 {
            .swiper {
                .swiper-slide {
                    .card {
                        img {
                            width: 300px !important;
                        }

                        .imgBg {
                            width: 330px !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .homeWelcome {
        .homeWelcomeTitle {
            p {
                font-size: 18px;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .homeWelcome {
        .homeWelcome2 {
            .swiper {
                .swiper-slide {
                    scale: 1;
                }

                .swiper-slide-next {
                    scale: 1;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .homeWelcome {
        padding: 0px 50px 0px;

        .homeWelcomeTitle {
            h1 {}

            p {
                width: 500px;
            }
        }

        .homeWelcome2 {
            .swiper {
                padding: 0px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .homeWelcome {
        padding: 0px 20px 0px;

        .homeWelcomeTitle {
            p {
                width: 80%;
            }
        }

        .homeWelcome2 {
            .swiper {

                .swiper-button-next,
                .swiper-button-prev {
                    img {
                        width: 25px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .homeWelcome {
        .homeWelcome2 {
            .swiper {
                .swiper-slide {
                    .card {
                        .imgBg {
                            opacity: 100%;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeWelcome {
        .homeWelcomeTitle {
            p {
                font-size: 16px;
            }
        }

        .homeWelcome2 {
            .swiper {
                .swiper-slide {
                    .card {
                        img {
                            width: 250px !important;
                        }

                        .imgBg {
                            width: 280px !important;
                        }
                    }
                }
            }
        }
    }
}