.aboutThree {
    padding: 100px 150px 50px;
    padding-top: 0px !important;
    opacity: 0%;

    .aboutThreeTitle {
        justify-content: center;
        text-align: center;

        h1 {
            color: var(--cor-secondary);
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToDown 1s ease forwards;
        }
    }

    .swiper {
        padding: 50px 0px;
        background-image: url('../../img/about/aboutThree/aboutThree-bg.webp');
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 0% 55%;

        .truck {
            position: absolute;
            top: 48%;
            left: 50px;
            z-index: 100;
            animation: truck2 1s ease;
            transition: .5s ease;
            cursor: pointer;
        }

        .truck:hover {
            transform: translateX(30px);
        }

        .truckActive {
            animation: truck 1s ease;
        }

        .swiper-button-next {
            position: absolute;
            top: 56%;
            right: -5px !important;
        }

        .swiper-button-prev {
            transition: .2s ease;
            top: 56%;
            left: 7px;
            z-index: 1000;
        }

        .swiper-button-prev:after,
        .swiper-button-next:after {
            display: none;
        }

        .swiper-button-prev.swiper-button-disabled,
        .swiper-button-next.swiper-button-disabled {
            opacity: 0 !important;
        }

        .swiper-slide {
            height: 600px;
            display: grid;

            .card {
                width: 100%;
                border: none;
                background-color: #ffffff00;
                display: grid;
                /* gap: 150px; */
                opacity: 100%;
                height: fit-content;

                h2 {
                    font-size: 45px !important;
                    font-weight: 800;
                    color: var(--cor-primary);
                }

                h3 {
                    font-size: 22px;
                    font-weight: 600;
                    color: var(--cor-secondary);
                }

                p {
                    width: 100%;
                    margin: 0px;
                }
            }

            .card1,
            .card3,
            .card5,
            .card7,
            .card9,
            .card11,
            .card13 {
                img {
                    padding-bottom: 150px
                }
            }

            .card2,
            .card4,
            .card6,
            .card8,
            .card10,
            .card12 {
                top: 60%;

                img {
                    padding-top: 150px;
                }
            }

            .card1 {
                opacity: 0%;
            }

            .card1.visible {
                animation: halfToDown 2s .2s ease forwards;
            }

            .card2 {
                opacity: 0%;
            }

            .card2.visible {
                animation: halfToDown 2s .4s ease forwards;
            }
        }
    }
}

.aboutThree.visible {
    animation: changeOpacity 2s ease forwards;
}

@media screen and (max-width: 1600px) {
    .aboutThree {
        padding: 0px 150px 50px;

        .swiper {
            background-size: 1900px;
            padding: 50px 0px;
            box-sizing: border-box;

            .swiper-slide {
                height: 500px;

                .card {
                    h2 {
                        font-size: 25px !important;
                    }

                    h3 {
                        font-size: 20px !important;
                    }

                    p {
                        font-size: 14px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .aboutThree {
        .swiper {
            .truck {
                top: 48%;
            }

            .swiper-slide {
                .card {
                    padding: 0px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .aboutThree {
        padding: 100px 50px 50px;

        .swiper {
            background-position: 50% 55%;
            padding: 50px 0px 0px;

            .swiper-slide {
                height: 450px;
            }

            .swiper-button-prev {
                top: 56%;
                left: -4px;
            }

            .swiper-button-next {
                top: 56%;
                right: -4px;
            }

            .truck {
                top: 47.2%;
                width: 120px;
            }
        }
    }
}

@media screen and (max-width: 1250px) {
    .aboutThree {
        .swiper {
            .swiper-slide {
                height: 500px;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .aboutThree {
        .swiper {
            background-position: 100% 60%;

            .swiper-button-prev {
                top: 61%;

            }

            .swiper-button-next {
                top: 61%;
            }

            .truck {
                top: 53%;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .aboutThree {
        .swiper {
            background-position: 100% 96%;

            .truck {
                top: 78%;
            }

            .swiper-button-next {
                top: 94%;
            }

            .swiper-button-prev {
                top: 94%;
            }

            .swiper-slide {
                height: auto;
                padding-bottom: 80px;

                .card {
                    align-items: start;
                    top: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .aboutThree {
        .swiper {
            .truck {
                top: 82%;
            }

            .swiper-button-next,
            .swiper-button-prev {
                top: 95%;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .aboutThree {
        padding: 0px 20px 20px;

        .swiper {
            .swiper-slide {
                .card {
                    h2 {
                        font-size: 22px !important;
                        margin: 0px;
                    }

                    h3 {
                        font-size: 16px !important;
                    }

                    img {
                        max-width: 100%;
                    }
                }
            }
        }
    }
}