.contactThree {
    padding: 50px 50px 50px;
    text-align: center;
    display: grid;

    h1 {
        color: var(--cor-primary);
        font-weight: 700;
        justify-self: center;
        opacity: 0%;
    }

    h1.visible {
        animation: halfToUp 1s ease forwards;
    }

    p {
        justify-self: center;
        font-size: 18px;
        max-width: 600px;
        padding: 10px 0px 20px 0px;
        opacity: 0%;
    }

    p.visible {
        animation: halfToUp 1s .1s ease forwards;
    }

    a {
        justify-self: center;
        display: grid;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: fit-content;
        padding: 5px;
        border-radius: 15px;
        border: 2px solid rgba(255, 255, 255, 0);
        transition: .4s ease;
        opacity: 0%;

        button {
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 600;
            background-color: var(--cor-secondary);
            width: 400px;
            height: 60px;
            border-radius: 10px;
            transition: .5s ease;
            border: none;
        }
    }

    a.visible {
        animation: halfToUp 1s .2s ease forwards;
    }

    a:hover {
        border: 2px solid var(--cor-tertiary);
        border-radius: 5px;
        scale: .9;

        button {
            background-color: var(--cor-tertiary);
            border-radius: 5px;
        }
    }
}

@media screen and (max-width: 1600px) {
    .contactThree {
        p {
            font-size: 16px;
        }

        a {
            button {
                width: 350px;
                height: 50px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .contactThree {
        h1 {
            font-size: 23px !important;
        }
    }
}

@media screen and (max-width: 500px) {
    .contactThree {
        padding-top: 20px;

        a {
            width: 100%;

            button {
                width: 300px;
                max-width: 300px;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .contactThree {
        padding: 60px 20px 0px;

        h1 {
            font-size: 21px !important;
        }

        p {
            font-size: 14px;
            margin: 0px;
        }
    }
}

@media screen and (max-width: 340px) {
    .contactThree {
        a {
            button {
                padding: 0px 50px;
                width: 100%;
            }
        }
    }
}