.homeOds {
    display: grid;
    justify-content: center;
    padding: 100px;
    background-image: url('../../img/home/ods/ods-bg.webp');
    background-size: 100%;

    span {
        color: var(--cor-secondary);
    }

    h1 {
        text-align: center;
        justify-self: center;
        color: var(--cor-tertiary);
        font-weight: 700;
        width: 75%;
        opacity: 0%;
    }

    h1.visible {
        animation: halfToLeft 1s ease forwards;
    }

    .homeOdsCards {
        padding-top: 80px;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        row-gap: 20px;
        column-gap: 50px;
        max-width: 1500px;
        justify-self: center;

        .card {
            cursor: pointer;
            opacity: 0%;
            box-shadow: 10px 10px 10px 2px #0000005b;
            width: fit-content;
            padding: 10px;
            border: none;
            display: grid;
            grid-template-areas: 'card';

            img {
                grid-area: card;
                width: 115px;
                height: 111px;
                transition: .2s ease;
            }

            .lock {
                opacity: 0%;
                padding: 5px;
                justify-self: right;
                width: fit-content;
                height: fit-content;
                grid-area: card;
                border-radius: 50px;
                position: relative;
                left: 20px;
                top: -20px;
                transition: .3s ease;

                .lockImg {
                    padding: 2px;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    background-color: #eeeeee !important;
                }
            }
        }

        .card:hover {
            img {
                scale: 1.3;
                border-radius: 5px;
            }

            .lock {
                opacity: 0%;
            }
        }

        .disable {
            img {
                background-color: #646464 !important;
            }

            .lock {
                opacity: 100%;
            }
        }

        .card1 {
            img {
                background-color: rgb(229, 36, 59);
            }
        }

        .card1.visible {
            animation: fadeIn 2s 1s ease forwards;
        }

        .card2 {
            position: relative;
            top: 50px;

            img {
                background-color: rgb(221, 166, 58);
            }
        }

        .card2.visible {
            animation: fadeIn 2s 1.1s ease forwards;
        }

        .card3 {
            img {
                background-color: rgb(76, 159, 56);
            }
        }

        .card3.visible {
            animation: fadeIn 2s 1.2s ease forwards;
        }

        .card4 {
            position: relative;
            top: 50px;

            img {
                background-color: rgb(197, 25, 45);
            }
        }

        .card4.visible {
            animation: fadeIn 2s 1.3s ease forwards;
        }

        .card5 {
            img {
                background-color: rgb(255, 58, 33);
            }
        }

        .card5.visible {
            animation: fadeIn 2s 1.4s ease forwards;
        }

        .card6 {
            position: relative;
            top: 50px;

            img {
                background-color: rgb(38, 189, 226);
            }
        }

        .card6.visible {
            animation: fadeIn 2s 1.5s ease forwards;
        }

        .card7 {
            img {
                background-color: rgb(252, 195, 11);
            }
        }

        .card7.visible {
            animation: fadeIn 2s 1.6s ease forwards;
        }

        .card8 {
            position: relative;
            top: 50px;

            img {
                background-color: rgb(162, 25, 66);
            }
        }

        .card8.visible {
            animation: fadeIn 2s 1.7s ease forwards;
        }

        .card9 {
            img {
                background-color: rgb(253, 105, 37);
            }
        }

        .card9.visible {
            animation: fadeIn 2s 1.8s ease forwards;
        }

        .card10 {
            position: relative;
            top: 50px;

            img {
                background-color: rgb(221, 19, 103);
            }
        }

        .card10.visible {
            animation: fadeIn 2s 1.9s ease forwards;
        }

        .card11 {
            img {
                background-color: rgb(253, 157, 36);
            }
        }

        .card11.visible {
            animation: fadeIn 2s 2s ease forwards;
        }

        .card12 {
            position: relative;
            top: 50px;

            img {
                background-color: rgb(191, 139, 46);
            }
        }

        .card12.visible {
            animation: fadeIn 2s 2.1s ease forwards;
        }

        .card13 {
            img {
                background-color: rgb(63, 126, 68);
            }
        }

        .card13.visible {
            animation: fadeIn 2s 2.2s ease forwards;
        }

        .card14 {
            position: relative;
            top: 50px;

            img {
                background-color: rgb(10, 151, 217);
            }
        }

        .card14.visible {
            animation: fadeIn 2s 2.3s ease forwards;
        }

        .card15 {
            img {
                background-color: rgb(86, 192, 43);
            }
        }

        .card15.visible {
            animation: fadeIn 2s 2.4s ease forwards;
        }

        .card16 {
            position: relative;
            top: 50px;

            img {
                background-color: rgb(0, 104, 157);
            }
        }

        .card16.visible {
            animation: fadeIn 2s 2.5s ease forwards;
        }

        .card17 {
            img {
                background-color: rgb(25, 72, 106);
            }
        }

        .card17.visible {
            animation: fadeIn 2s 2.6s ease forwards;
        }

    }
}

@media screen and (min-width: 2000px) {
    .homeOds {
        background-position: 50% 30%;
    }
}

@media screen and (max-width: 1600px) {
    .homeOds {
        .homeOdsCards {
            padding-top: 50px;
            row-gap: 20px;
            column-gap: 30px;

            .card {
                padding: 5px !important;

                img {
                    width: 90px;
                    height: 87px;
                }

                .lock {
                    .lockImg {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .homeOds {
        padding: 100px 50px;

        .homeOdsCards {
            grid-template-columns: repeat(5, 1fr);
            column-gap: 20px;

            .card {
                top: 0px;
            }

            .card2,
            .card4,
            .card7,
            .card9,
            .card12,
            .card14,
            .card17 {
                top: 30px;
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .homeOds {
        padding-top: 50px;
        padding-bottom: 50px;

        h1 {
            width: 100%;
        }

        .homeOdsCards {
            grid-template-columns: repeat(4, 1fr) !important;

            .card {
                top: 0px;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .homeOds {
        .homeOdsCards {
            grid-template-columns: repeat(3, 1fr) !important;
            justify-content: center;

            .card {
                justify-self: center;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeOds {
        padding: 50px 20px;

        .homeOdsCards {
            column-gap: 5px !important;

            .card {
                padding: 5px !important;
            }

            .card3,
            .card6,
            .card9,
            .card12,
            .card15 {
                .lock {
                    left: 10px;
                }

            }
        }
    }
}