.esgOne{
    padding: 50px 150px;
    padding-bottom: 0px !important;

    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto;
    gap: 10px;
    opacity: 0%;

    h1{
        grid-column-start: 1;
        grid-column-end: 4;
        justify-self: center;
        text-align: center;
        width: 500px;
        color: var(--cor-primary);
        font-weight: 700;
        padding-bottom: 100px;
        opacity: 0%;

        span{
            color: var(--cor-secondary);
        }
    }
    h1.visible{
        animation: slideToUp 1s .1s ease forwards;
    }

    .esgOneTitle{
        grid-column-start: 1;
        grid-column-end: 2;

        h1{
            padding-bottom: 20px;
            text-align: left;
            width: 400px;
        }
        p{
            opacity: 0%;
            font-size: 18px;
            line-height: 35px;
        }
        p.visible{
            animation: slideToUp 1s .2s ease forwards;
        }

    }
    img{
        transition: .5s ease;
        cursor: pointer;
        object-fit: cover;
    }
    img:hover{
        transform: translateY(-20px);
    }
    .img1{
        grid-column-start: 2;
        grid-column-end: 4;
        justify-self: right;
        align-self: end;
        opacity: 0%;

        img{
            border-radius: 10px;
        }
    }
    .img1.visible{
        animation: slideToUp 1s .3s ease forwards;
    }
    .img2{
        width: 100%;
        height: 300px;
        grid-column-start: 1;
        grid-column-end: 2;
        opacity: 0%;

        img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
    .img2.visible{
        animation: slideToUp 1s .4s ease forwards;
    }
    .img3{
        width: 100%;
        height: 300px;
        grid-column-start: 2;
        grid-column-end: 3;
        opacity: 0%;

        img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
    .img3.visible{
        animation: slideToUp 1s .5s ease forwards;
    }
    .img4{
        width: 100%;
        height: 300px;
        grid-column-start: 3;
        grid-column-end: 4;
        opacity: 0%;
        
        img{
            width: 100%;
            height: 100%;
            border-radius: 10px;
        }
    }
    .img4.visible{
        animation: slideToUp 1s .6s ease forwards;
    }
}
.esgOne.visible{
    animation: changeOpacity 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .esgOne{
        padding: 50px 100px;
        h1{
            width: 400px;
        }

        .esgOneTitle{
            h1{
                width: 300px;
            }
            p{
                font-size: 16px;
                line-height: 30px;
            }
        }
        .img1{
            img{
                width: 500px;
            }
        }
        .img2, .img3, .img4{
            height: 250px;
        }
    }
}

@media screen and (max-width: 1400px){
    .esgOne{
        padding: 50px 50px;

        h1{
            padding-bottom: 50px;
        }
        .esgOneTitle{
            p{
                margin: 0px;
            }
        }
    }
}

@media screen and (max-width: 1100px){
    .esgOne{
        .esgOneTitle{
            h1{
                padding-bottom: 10px;
                margin: 0px;
            }
        }
        .img1{
            img{
                width: 450px;
            }
        }
        .img2, .img3, .img4{
            height: 200px;
        }
    }
}

@media screen and (max-width: 1000px){
    .esgOne{padding-top: 20px;
        grid-template-columns: auto;
    
        h1{
            grid-column-start: 1;
            grid-column-end: 3;
        }
        .esgOneTitle{
            padding-bottom: 20px;
            grid-column-start: 1;
            grid-column-end: 3;
        }
        .img1{
            grid-column-start: 1;
            grid-column-end: 2;
            justify-self: left;
            width: 120%;

            img{
                width: 100%;
            }
        }
        .img2{
            grid-column-start: 2;
            grid-column-end: 3;
            width: 80%;
            height: 100%;
            justify-self: right;
        
            img{
                width: 100%;
            }
        }
        .img3{
            grid-column-start: 1;
            grid-column-end: 2;
            width: 100%;
        
            img{
                width: 100%;
            }
        }
        .img4{
            grid-column-start: 2;
            grid-column-end: 3;
            width: 100%;
        
            img{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .esgOne{
        .esgOneTitle{
            padding-bottom: 5px;
            h1{
                padding-bottom: 20px;
            }
            p{
                line-height: 25px;
            }
        }
        .img1, .img2, .img3, .img4{
            height: auto;
        }
    }
}

@media screen and (max-width: 600px){
    .esgOne{
        padding: 30px 50px;
        grid-template-columns: 100%;

        .esgOneTitle{
            grid-column-start: 1;
            grid-column-end: 2 !important;
        }
        .img1, .img2, .img3, .img4{
            width: 100%;
            height: auto;
            grid-column-start: 1;
            grid-column-end: 2; 
        }
    }
}

@media screen and (max-width: 500px){
    .esgOne{
        h1{
            width: 100%;
        }
    }
}

@media screen and (max-width: 425px){
    .esgOne{
        padding: 20px;

        .esgOneTitle{
            h1{
                width: 100%;
            }
            p{
                font-size: 14px;
            }
        }
    }
}