.footer-bg{
    background-image: url('../../img/footer/footer-bg.webp');
    box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.11);
    background-size: cover;
    background-position: 0% 100%;
    background-repeat: no-repeat;
    opacity: 0%;
    display: flex;
    justify-content: center;
    gap: 50px;
    
    .footer{
        justify-content: center;
        display: grid;
        background: none;
        border: none;
        padding: 50px 0px 1% 0px;
        gap: 10px;
        text-align: left;
        height: fit-content;
    
        .logo{
            display: grid;
            justify-content: left;
    
            img{
                padding-bottom: 10px;
                opacity: 0%;
            }
            img.visible{
                animation: slideToUp 1s ease forwards;
            }
    
            .line{
                justify-self: left;
                width: 0px;
                height: 2px;
                background-color: var(--cor-secondary);
                transition: 1s .5s ease;
            }
            .line.visible{
                width: 150px;
            }
        }
        
        .address{
            padding-top: 20px;
            opacity: 0%;
            p{
                font-size: 18px;
                font-weight: 500;
                color: var(--cor-primary);
            }
        }
        .address.visible{
            animation: slideToUp 2s .4s ease forwards;
        }

        .rights{
            opacity: 0%;
            p{
                color: var(--cor-primary);
                font-weight: 400;
            }
        }
        .rights.visible{
            animation: slideToUp 2s .6s ease forwards;
        }
        .singlec{
            opacity: 0%;
            p{
                color: var(--cor-primary);
                font-weight: 400;
            }
            a{
                text-decoration: none;
                color: var(--cor-primary);
                font-weight: 600;
            }
        }
        .singlec.visible{
            animation: changeOpacity 2s .8s ease forwards;
        }
    }
    .footer2{
        display: flex;
        padding: 50px 0px 30px 0px;

        ul{
            h2{
                color: var(--cor-primary);
                font-weight: 600;
                margin: 0px;
            }
            .underline{
                background-color: var(--cor-secondary);
                height: 2px;
                width: 50%;
                margin-bottom: 10px;
                border-radius: 50px;
            }
            li{
                transition: .5s ease;
                position: relative;
                color: var(--cor-tertiary);
                font-weight: 500;
                left: 0px;

                a{
                    color: var(--cor-tertiary);
                    font-weight: 500;
                }
            }
            li:hover{
                left: 10px;
            }
        }
    }
}
.footer-bg.visible{
    animation: changeOpacity 2s ease forwards;
}

@media screen and (min-width: 2000px) {
    .footer-bg{
         background-position: 0% 60%;
    }
}

@media screen and (max-width: 1600px){
    .footer-bg{
        background-image: none;
        padding: 20px 100px 10px !important;

    }
    .footer{    
        .logo{
            img{
                width: 200px;
            }
        }
        .address{
            p{
                font-size: 16px !important;
            }
        }
    }
    .footer2{
    }
}

@media screen and (max-width: 1440px){
    .footer-bg{
        .footer{
            width: 30%;
            text-align: center;
            font-size: 14px;

            p{
                margin: 0px;
            }
            .logo{
                justify-content: center;
                
                .line{
                    justify-self: center;
                }
            }
        }
        .footer2{
            ul{
                li{
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .footer-bg{
        padding: 20px 50px 20px !important;
    }
}

@media screen and (max-width: 1000px){
    .footer-bg{
        padding: 20px 50px 20px !important;
        display: grid;
        gap: 0px;
        
        .footer{
            padding: 0px !important;
            width: 100%;
            grid-row-start: 2;
            grid-row-end: 3;
        }
        .footer2{

            .listThree{
                min-width: 200px;
            }
            gap: 50px;
        }
    }
}


@media screen and (max-width: 768px){
    .footer-bg{
        padding: 20px !important;
        .footer{
            width: 100%;
            backdrop-filter: blur(5.5px);
            background-color: hsla(0, 0%, 100%, 0.349);
            padding: 20px 0px !important;
    
            .address{
                p{
                    font-size: 16px !important;
                }
            }
            .rights, .singlec{
                p{
                    font-size: 14px !important;
                    font-weight: 500;
                }
            }
        }
        .footer2{
            display: none;
        }
    }
}

@media screen and (max-width: 500px){
    .footer{
        padding: 50px 20px 10px 20px !important;
    }
}

@media screen and (max-width: 425px){
    .footer-bg{
        .footer{
            .logo{
                img{
                    width: 160px;
                }
            }
            .address p{
                font-size: 14px !important;
            }
        }
    }
}