.careerOne{
    opacity: 0%;
    padding: 250px 100px 100px;
    display: flex;
    gap: 0px;

    .img1{
        position: relative;
        left: -100px;
        img{
            width: 400px;
            height: auto;
        }
    }
    .img2{
        width: 300px;
        height: auto;
    }
    img{
        opacity: 0%;
    }
    img.visible{
        animation: halfToRight 1s .2s ease forwards;
    }
    .careerText{
        position: relative;
        display: grid;
        gap: 10px;
        height: fit-content;
        margin-left: 100px;

        h1{
            color: var(--cor-primary);
            font-weight: 700;
            max-width: 650px;
            opacity: 0%;

            span{
                color: var(--cor-secondary);
            }
        }
        h1.visible{
            animation: halfToRight 1s .3s ease forwards;
        }
        h2{
            color: var(--cor-secondary);
            font-weight: 600;
            opacity: 0%;
        }
        h2.visible{
            animation: halfToRight 1s .4s ease forwards;
        }
        p{
            font-size: 18px;
            max-width: 100%;
            opacity: 0%;
            margin: 0px;
        }
        p.visible{
            animation: halfToRight 1s .5s ease forwards;
        }
    }
}
.careerOne.visible{
    animation: fadeIn 2s ease forwards;
}

@media screen and (max-width: 1600px){
    .careerOne{
        padding: 200px 100px 100px;


        .img2{
            img{
                width: 150px;
            }
        }
        .careerText{
            right: 0px;
            p{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1440px){
    .careerOne{
        padding: 200px 50px 100px;

        .img2{
            position: relative;
            left: -100px;
        }
        .careerText{
            margin-left: 0px;
            p{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .careerOne{
        padding: 150px 50px 50px;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;

        .img1{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
            inset: 0px;
            top: 30px;
        }
        .img2{
            grid-column-start: 2;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3;
            inset: 0px 0px 0px -100px;

            img{
                width: 180px;
            }
        }
        .careerText{
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }
}

@media screen and (max-width: 1000px){
    .careerOne{
        .img2{
            inset: 0px;
        }
    }
}

@media screen and (max-width: 900px){
    .careerOne{
        .img1{
            width: fit-content;
            img{
                width: 300px;
            }
        }
        .img2{
            width: fit-content;
            img{
                width: 120px;
            }
        }
        .careerText{
            width: 100%;
            h1{
                width: 100%;
            }
            p{
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 500px){
    .careerOne{
        .img1{
            img{
                width: 100%;
                min-width: 150px;
            }
        }
        .img2{
            img{
                max-width: 120px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 425px){
    .careerOne{ 
        padding: 150px 20px 50px;
        .img1, .img2{
            left: -50px;
        }

        .careerText{
            p{
                font-size: 14px;
            }
        }
    }
}