.esgNine{
    padding: 50px 50px 50px 150px;
    display: grid;
    grid-template-columns: auto auto;
    opacity: 0%;

    .esgNineText{
        max-width: 800px;

        .social{
            h1{
                color: var(--cor-secondary);
                font-weight: 700;
                opacity: 0%;
            }
            h1.visible{
                animation: halfToUp 1s .2s ease forwards;
            }
            p{
                font-weight: 700;
                color: var(--cor-primary);
                font-size: 18px;
                opacity: 0%;
            }
            p.visible{
                animation: halfToUp 1s .3s ease forwards;
            }
        }
        .politica{
            h2{
                padding-top: 20px;
                font-weight: 700;
                font-size: 24px !important;
                color: var(--cor-tertiary);
                opacity: 0%;
            }
            h2.visible{
                animation: halfToUp 1s .4s ease forwards;
            }
            p{
                font-size: 18px;
                opacity: 0%;
            }
            p.visible{
                animation: halfToUp 1s .5s ease forwards;
            }
        }
        .link1.visible{
            animation: halfToUp 1s .6s ease forwards;
        }
        .link2.visible{
            animation: halfToUp 1s .7s ease forwards;
        }
        .links{
            padding-top: 20px;
            display: grid;
            gap: 10px;
            grid-template-columns: repeat(2, 1fr);
            
            a{
                color: var(--cor-primary);
                display: flex;
                height: fit-content;
                gap: 10px;
                opacity: 0%;

                img{
                    width: 20px;
                    height: 24px;
                }
                p{
                    font-weight: 700;
                    margin: 0px;
                }
            }
        }
    }
    .esgNineLogo{
        display: grid;
        grid-template-areas: 'img';
        opacity: 0%;

        img{
            justify-self: center;
            grid-area: img;
            width: 400px;
        }
    }
    .esgNineLogo.visible{
        animation: halfToUp 1s .5s ease forwards;
    }
}
.esgNine.visible{
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .esgNine{
        padding: 50px;

        .esgNineText{
            max-width: 600px;
            .politica{
                h2{
                    font-size: 22px !important;
                }
                p{
                    font-size: 16px;
                }
            }
            p{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .esgNine{
        padding: 30px 50px 50px;
        gap: 100px;

        .esgNineText{
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;

            p{
                line-height: 25px;
            }
        }
        .esgNineLogo{
            grid-column-start: 2;
            grid-column-end: 3;
            justify-self: end;

            img{
                width: 350px;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .esgNine{
        padding-top: 30px;
        grid-template-columns: 100%;
        gap: 20px;

        .esgNineLogo{
            display: none;
        }
        .esgNineText{
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 600px){
    .esgNine{
        padding: 30px 50px 20px;

        .esgNineLogo{
            width: 100%;
            img{
                width: 100%;
            }
        }
        .esgNineText{
            .links{
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}

@media screen and (max-width: 425px){
    .esgNine{
        padding: 30px 20px 20px;

        .esgNineText{
            .politica{
                h2{
                    padding-top: 10px;
                    font-size: 18px !important;
                }
                p{
                    font-size: 14px;
                }
            }
            .links{
                a{
                    p{
                        font-size: 14px;
                    }
                }
            }
        }
        .esgNineLogo{
            width: 100%;

            img{
                width: 100%;
            }
        }
    }
}