.aboutFive {
    padding: 150px 150px 20px;
    background-image: url('../../img/about/aboutFive/aboutFive-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    gap: 20px;
    border-radius: 20px;

    .aboutFiveTitle {
        width: fit-content;

        h1 {
            font-weight: 700;
            color: var(--cor-secondary);
            opacity: 0%;
        }

        h1.visible {
            animation: slideToRight 1s ease forwards;
        }
    }

    .aboutFiveText {
        display: flex;
        gap: 10%;
        line-height: 40px;

        p {
            color: rgb(228, 228, 228);
            font-weight: 300;
        }

        .text1,
        .text2 {
            opacity: 0%;
        }

        .text1.visible {
            animation: slideToRight 1s .2s ease forwards;
        }

        .text2.visible {
            animation: slideToRight 1s .2s ease forwards;
        }
    }

    .version {
        padding-top: 100px;
        position: relative;
        margin: auto 0px 0px;
        font-size: 14px;
        color: rgb(216, 216, 216);
        text-align: end;
    }
}

@media screen and (max-width: 1600px) {
    .aboutFive {
        padding: 150px 50px 20px !important;
    }
}

@media screen and (max-width: 1400px) {
    .aboutFive {
        .aboutFiveText {
            line-height: 35px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .aboutFive {
        .aboutFiveText {
            gap: 10px;
            display: grid;
        }
    }
}

@media screen and (max-width: 768px) {
    .aboutFive {
        .aboutFiveText {
            line-height: 30px;
        }
    }
}

@media screen and (max-width: 500px) {
    .aboutFive {
        .version {
            padding-top: 30px;
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 425px) {
    .aboutFive {
        padding: 100px 20px 20px !important;

        .aboutFiveText {
            p {
                font-size: 14px !important;
            }
        }
    }
}