.esgEleven {
    padding: 100px 0px 0px;
    display: grid;
    background-image: url("../../img/esg/esgSeven/esgSeven-bg.webp");
    background-repeat: no-repeat;
    background-position: 106% 100%;
    background-size: 350px;

    .esgElevenTitle {
        text-align: center;
        justify-self: center;
        display: grid;
        padding-top: 7rem;

        h1 {
            opacity: 0%;
            justify-self: center;
            font-weight: 700;
            color: var(--cor-secondary);
            width: 100%;

            span {
                color: var(--cor-primary);
            }
        }

        h1.visible {
            animation: halfToUp 1s .1s ease forwards;
        }

        p {
            opacity: 0%;
            margin: 0px;
            padding: 30px 150px;
            line-height: 30px;
            font-size: 18px;
            width: 100%;
        }

        p.visible {
            animation: halfToUp 1s .2s ease forwards;
        }
    }

    .swiper {
        max-width: 85%;
        padding: 50px 15px;

        .swiper-slide {
            transition: .5s ease;
            cursor: pointer;
        }

        .swiper-slide:hover {
            transform: translateY(-30px);

            .card {
                box-shadow: none;
                border: 1px solid var(--cor-secondary);
            }
        }

        .card {
            padding: 20px;
            border: none;
            border-radius: 20px;
            -webkit-backdrop-filter: blur(10.5px);
            backdrop-filter: blur(5px);
            background-color: rgba(255, 255, 255, 0.726);
            width: 100%;
            height: 100%;
            box-shadow: 5px 5px 10px 1px rgba(0, 0, 0, 0.267);
            display: grid;
            opacity: 0%;
            border: 1px solid rgba(255, 255, 255, 0);
            transition: .5s ease;

            img {
                width: 120px;
                justify-self: center;
                margin: 20px 0px;
            }

            p {
                font-size: 16px;
            }
        }

        .card.visible {
            animation: fadeIn 1s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px) {
    .esgEleven {
        padding: 50px 0px 0px;
        background-position: 100% 100%;
        background-size: 300px;

        .esgElevenTitle {
            p {
                font-size: 16px;
            }
        }

        .swiper {
            max-width: 90%;
        }
    }
}

@media screen and (max-width: 1400px) {
    .esgEleven {
        background-size: 250px;

        .swiper {
            max-width: 100%;

            .card {
                p {
                    font-size: 15px;
                    margin: 0px;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .esgEleven {
        background-size: 250px;

        .esgElevenTitle {
            padding: 120px 5rem 0px;

            p {
                width: 100%;
                padding: 20px 0px 0px;
            }
        }

        .swiper {
            .card {
                border-radius: 10px;
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .esgEleven {
        background-position: 100% 100%;
        background-size: 200px;

        .esgElevenTitle {
            padding: 100px 50px 0px;
            h1 {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .esgEleven {
        .swiper {
            .card {
                p {
                    font-size: 14px;
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .esgEleven {
        .esgElevenTitle {
            padding: 100px 20px 0px;

            p {
                font-size: 14px;
                padding-top: 20px;
            }
        }
        .swiper{
            padding: 20px 15px;
        }
    }
}