.warehouseFive {
    padding: 50px 250px 0px;

    display: grid;
    align-items: center;
    justify-content: right;
    grid-template-columns: auto 60%;
    grid-template-rows: 100%;
    column-gap: 50px;

    h1 {
        color: var(--cor-secondary);
        font-weight: 700;
        max-width: 500px;
        opacity: 0%;
    }

    h1.visible {
        animation: halfToRight 1s ease forwards;
    }

    p {
        line-height: 30px;
        font-size: 18px;
        max-width: 1000px;
        opacity: 0%;
    }

    p.visible {
        animation: halfToRight 1s ease forwards;
    }

    img {
        width: 350px;
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
        opacity: 0%;
    }

    img.visible {
        animation: halfToRight 1s ease forwards;
    }
}

@media screen and (max-width: 1600px) {
    .warehouseFive {
        padding: 50px 150px 0px;

        p {
            font-size: 16px
        }
    }
}

@media screen and (max-width: 1400px) {
    .warehouseFive {
        padding: 50px 100px 0px;

        img {
            width: 300px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .warehouseFive {
        padding: 50px 50px 0px;
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 800px) {
    .warehouseFive {
        grid-template-columns: 100%;
        grid-template-rows: auto auto;

        img {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 2;
            grid-row-end: 3;
        }

        .warehouseFiveText {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }
}

@media screen and (max-width: 700px) {
    .warehouseFive {
        padding: 50px 50px 0px;

        img {
            position: relative;
            right: 0px;
        }

        .text {
            p {
                line-height: 25px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .warehouseFive {
        padding: 30px 20px 0px;
        gap: 20px;

        img {
            justify-self: center;
            width: 250px;
        }

        h1 {
            align-self: flex-end;
        }

        p {
            width: 100%;
            line-height: 25px;
        }
    }
}

@media screen and (max-width: 425px) {
    .warehouseFive {
        img {
            top: 10px;
        }

        p {
            font-size: 16px;
        }
    }
}