#card-publication {
    .cards {
        display: flex;
        flex-wrap: wrap;
        gap: 30px;

        a{
            height: fit-content;
        }
    }

    .card {
        padding: 15px;
        background-color: #fff;
        box-shadow: 10px 10px 10px 2px rgba(0, 0, 0, 0.246);
        border-radius: 10px;
        border: none;

        .card-img-top{
            max-width: 520px;
            max-height: 300px;
        }
    }

    .card-title {
        font-weight: 600;
        font-size: 16px;
        color: var(--cor-primary);
    }

    .icon {
        color: var(--cor-secondary);
    }

    .card-small {
        width: 350px;
    }

    .margin-15 {
        margin-top: 15rem;
    }

    .card-plus {
        width: 100%;
        height: 100%;
        display: flex;
    }
    .card-align-plus {
        padding: 2rem;

        h5{
            font-size: 24px;
            font-weight: 700;
        }
        .card-text{
            font-size: 16px !important;
        }

        .card-align {
            display: flex;
            justify-content: space-between;
            
            .card-text {
                font-size: 14px !important;
            }
        }
    }
}

.blogTitle{
    padding: 250px 0px 0px;
    text-align: center;
    display: grid;
    opacity: 0%;
    animation: fadeIn 1s .8s ease forwards;

    h1{
        justify-self: center;
        width: 400px;
        font-weight: 700;
        color: var(--cor-primary);

        span{
            color: var(--cor-secondary);
        }
    }
}
#card-publication-sm{
    padding: 0px 100px 50px !important;

    .swiper{
        padding: 50px 10px;
        opacity: 0%;

        .swiper-slide{
            transition: .5s ease;
            
            .card{
                border: 1px solid rgba(255, 255, 255, 0);
                transition: .5s ease;
            }
        }
        .swiper-slide:hover{
            transform: translateY(-20px);

            .card{
                border: 1px solid var(--cor-primary);
            }
        }
        .swiper-pagination-bullet-active{
            background-color: var(--cor-secondary);
        }
        .card-small{
            border: none;
            box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.199);
            padding: 20px;
            height: 480px;
            width: 100%;
            
            img, .card-img-top{
                min-height: 200px;
                height: 200px !important;
                max-height: 200px !important;
                object-fit: cover;
            }

            .card-body{
                height: fit-content !important;
                .card-align{
                    .card-text{
                        font-size: 12px;
                        height: fit-content;
                    }
                }


                .card-title{
                    max-height: 100px;
                    height: 75px;
                    min-width: 0px;
                    max-width: 100%;
                    padding: 0px;
                    margin: 0px;

                    h5{
                        line-height: 25px;
                        padding-top: 25px;
                        font-size: 20px;
                        color: var(--cor-primary);
                        font-weight: 700;
                        overflow: hidden;
                        box-sizing: border-box;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        white-space: normal;
                        overflow-wrap: normal;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        z-index: 5;
                    }
                }
                .card-description{
                    height: 100px;
                    min-width: 0px;
                    max-width: 100%;
                    padding: 10px 0px 5px;

                    p{
                        font-size: 14px;
                        overflow: hidden;
                        box-sizing: border-box;
                        display: -webkit-box;
                        text-overflow: ellipsis;
                        white-space: normal;
                        overflow-wrap: normal;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 6;
                    }
                }
            }
        }
    }
    .swiper.visible{
        animation: halfToUp 1s .5s ease forwards;
    }
}
@media screen and (max-width: 1600px){
    .blogTitle{
        padding-top: 220px;
        h1{
            font-size: 28px !important;
        }
    }

    #sub-nav .navbar{
        top: 98px !important
    }
}
@media screen and (max-width:1400px){
    .blogTitle{
        h1{
            font-size: 25px !important;
        }
    }
    #card-publication-sm{
        padding: 0px 50px 0px !important;

        .swiper{
            padding: 20px 10px 50px;
        }
    }
    .card{
        padding: 15px !important;

        .card-img-top{
            max-width: 418px;
            max-height: 233px;
        }

        .card-title {
            h5{
                font-size: 18px !important;
                font-weight: 700;
            }
        }
    }
}

@media screen and (max-width:1200px){
    #card-publication{
        padding: 0px 0px 10px;

        .container{
            padding: 0px;
        }
    }
}

@media screen and (max-width: 991px) {
    .container{
        max-width: 100%;
    }
    #card-publication-sm{
        .swiper{
            .card-small{
                height: 450px;

                .card-body{
                    .card-description{
                        p{
                            -webkit-line-clamp: 4;
                        }
                    }
                }
            }
        }
    }
    .card-plus {
        .card-img-top{
            max-width: 450px !important;
        }
        .card-align-plus{
            padding: 30px 20px 10px!important;
        }
    }
}

@media screen and (max-width: 900px) {
    .card-plus{
        flex-direction: column;
    }
    .container{
        max-width: 800px;
    }
}

@media screen and (max-width: 710px) {
    .blogTitle{
        padding-top: 290px;
    }
    #card-publication-sm{
        padding: 0px 10px !important;

        .card-align-plus {
            h5{
                font-size: 20px !important;
            }
            .card-text{
                font-size: 14px !important;
            }
    
            .card-align {
                .card-text {
                    font-size: 12px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    #card-publication .gradient-white {
        background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.044), #ffffff00);
    }

    #card-publication .card-small {
        width: 100%;
    }

    #card-publication-sm{
        .swiper{
            .card-small{
                height: 450px;

                .card-body{
                    .card-description{
                        -webkit-line-clamp: 6;
                        p{
                            -webkit-line-clamp: 6;
                        }
                    }
                }
            }
        }
    }
    .card-plus {
        flex-direction: column;
        width: 100%;
    }
}