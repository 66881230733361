.fleetTwo {
    display: grid;
    grid-template-columns: auto auto;
    padding: 100px 0px 50px 150px;
    overflow: hidden !important;
    /*     background-image: url("../../img/fleetStructure/fleetTwo/fleetTwo-bg2.webp");
    background-repeat: no-repeat;
    background-size: cover; */

    .imgBg {
        position: absolute;
        bottom: -50px;
        left: -220px;
    }

    .imgBg2 {
        position: absolute;
        top: 120px;
        right: 0px;
        z-index: 1;
    }

    .fleetTwoContent {
        z-index: 2;
        display: grid;

        .fleetTwoTitle {
            z-index: 2;

            h1 {
                color: var(--cor-primary);
                font-weight: 700;
                opacity: 0%;
            }

            h1.visible {
                animation: slideToRight 1s ease forwards;
            }
        }

        .fleetTwoText {
            display: grid;
            height: fit-content;
            gap: 20px;

            .text {
                h3 {
                    font-size: 28px;
                    font-weight: 700;
                    color: var(--cor-secondary);
                    opacity: 0%;
                }

                h3.visible {
                    animation: slideToRight 1s .2s ease forwards;
                }

                p {
                    font-size: 22px;
                    color: var(--cor-tertiary);
                    font-weight: 500;
                    opacity: 0%;
                }

                p.visible {
                    animation: slideToRight 1s .3s ease forwards;
                }
            }

            p {
                max-width: 500px;
                font-size: 18px;
                opacity: 0%;
            }

            p.visible {
                animation: slideToRight 2s .5s ease forwards;
            }

        }
    }

    .fleetTwoImg {
        opacity: 0%;
        position: relative;
        margin-right: 0px;
        display: grid;
        justify-content: end;

        img {
            justify-self: end;
            align-self: center;
            border-radius: 15px;
        }
    }

    .fleetTwoImg.visible {
        animation: slideToLeft 2s ease forwards;
    }
}

@media screen and (max-width: 1600px) {
    .fleetTwo {
        padding: 100px 0px 0px 50px;

        .imgBg {
            opacity: 20%;
            bottom: -100px !important;
        }

        .fleetTwoContent {
            .fleetTwoText {
                p {
                    font-size: 16px;
                }
            }
        }

        .fleetTwoImg {
            img {
                width: 700px;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .fleetTwo {
        .fleetTwoContent {
            .fleetTwoText {
                h3 {
                    font-size: 25px !important;
                }

                p {
                    max-width: 400px;
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .fleetTwo {
        .fleetTwoContent {
            p {
                max-width: 400px;
            }
        }

        .fleetTwoImg {
            img {
                width: 550px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .fleetTwo {
        padding: 100px 50px 0px;
        grid-template-columns: 100%;

        .fleetTwoContent {
            .fleetTwoText {
                padding-top: 20px;
                grid-template-columns: auto auto;
                column-gap: 100px;

                p {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    max-width: 100%;
                }
            }
        }

        .fleetTwoImg {
            justify-content: center;

            img {
                width: 600px;
                margin-top: 30px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .fleetTwo {
        .fleetTwoImg {
            img {
                width: 100%;
            }
        }

        .fleetTwoContent {
            .fleetTwoText {
                .text {
                    h3 {
                        font-size: 22px !important;
                    }

                    p {
                        font-size: 20px !important;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .fleetTwo {
        padding: 80px 50px 0px;

        .fleetTwoContent {
            .fleetTwoText {
                grid-template-columns: 100%;
                gap: 10px;

                p {
                    grid-column-end: 2;
                }
            }
        }

        .fleetTwoImg {
            img {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .fleetTwo {
        padding: 50px 20px 0px;

        .fleetTwoContent {
            padding-top: 20px;

            .fleetTwoText {
                gap: 10px;

                .text {
                    h3 {
                        margin: 0px;
                        font-size: 20px !important;
                    }

                    p {
                        font-size: 18px !important;
                    }
                }

                p {
                    font-size: 14px;
                }
            }
        }
    }
}