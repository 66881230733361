.reportsOne{
    padding: 200px 150px;
    padding-bottom: 0px !important;
    display: grid;
    grid-template-columns: auto auto;

    .reportsText{
        width: 80%;

        h1{
            font-weight: 700;
            color: var(--cor-primary);
            width: 400px;
            padding-bottom: 30px;

            span{
                color: var(--cor-secondary);
            }
        }
        .subTitle{
            font-weight: 600;
            font-size: 20px;
        }
        p{
            font-size: 18px
        }
        ul{
            padding-left: 10px;
            font-size: 18px;
        }
    }
    form{
        background-color: var(--cor-primary);
        padding: 50px 20px;
        border-radius: 20px;
        min-width: 450px;
        max-width: 550px;
        
        label{
            font-weight: 300;
            margin-bottom: 5px;
            color: rgb(240, 240, 240);
        }
        input{
            line-height: 1.2;
            margin-bottom: 15px;
            background-color: rgba(255, 255, 255, 0.925);
            transition: .4s ease;
        }
        input:focus{
            background-color: rgb(255, 255, 255, 0.925);
        }
        textarea{
            background-color: rgba(255, 255, 255, 0.925);
            transition: .4s ease;
        }
        textarea:focus{
            background-color: rgb(255, 255, 255, 0.925);
        }
        button{
            width: 100%;
            background-color: var(--cor-secondary);
            transition: .4s ease;
        }
        button:hover{
            scale: .95;
            background-color: #FF6700;
        }

    }
}

@media screen and (max-width: 1600px){
    .reportsOne{
        padding: 200px 50px;

        .reportsText{
            p{
                font-size: 16px;
            }
            .subTitle{
                font-size: 18px;
            }
            ul{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1100px){
    .reportsOne{
        grid-template-columns: 50% 50%;
        form{
            max-width: 450px !important;
            min-width: 0%;
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px){
    .reportsOne{
        padding-bottom: 20px !important; 
        grid-template-columns: 100%;
        padding: 150px 50px;

        .reportsText{
            width: 100%;
            h1{
                padding-bottom: 15px;
            }
            .subTitle{
                margin-bottom: 5px;
            }
            p{
                margin-bottom: 0px;
            }
        }
        form{
            justify-self: right;
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 500px){
    .reportsOne{
        padding: 150px 20px;

        form{
            padding: 30px 10px;
        }
    }
}

@media screen and (max-width: 425px){
    .reportsOne{
        .reportsText{
            h1{
                width: 100%;
            }
            .subTitle{
                font-size: 16px;
            }
            p{
                margin-bottom: 5px;
                font-size: 14px;
            }
            ul{
                font-size: 14px;
                margin: 0px;
            }
        }
        form{
            label{
                font-size: 14px;
            }
            input{
                line-height: 1;
                margin-bottom: 5px;
            }
        }
    }
}