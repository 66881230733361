.aboutOneB {
    padding: 100px 150px;
    padding-bottom: 0px !important;
    background-image: url('../../img/about/aboutOne/aboutOneB-bg.webp');
    background-repeat: no-repeat;
    background-size: 1900px;
    opacity: 0%;

    .aboutOneC2 {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;

        img {
            justify-self: right;
            position: relative;
            right: 20%;
            opacity: 0%;
            transition: .5s ease;
            cursor: pointer;
        }

        img:hover {
            scale: 1.1;
        }

        img.visible {
            animation: halfToLeft 1s ease forwards;
        }

        .text {
            padding: 150px 0px 0px 0px;
            width: 750px;

            span {
                color: var(--cor-secondary);
            }

            h1 {
                font-weight: 700;
                color: var(--cor-primary);
                padding-bottom: 10px;
                opacity: 0%;
            }

            h1.visible {
                animation: halfToRight 1s ease forwards;
            }

            p {
                font-size: 18px;
                line-height: 40px;
                opacity: 0%;
            }

            p.visible {
                animation: halfToRight 1s ease forwards;
            }
        }
    }
}

.aboutOneB.visible {
    animation: changeOpacity 1s ease forwards;
}

@media screen and (max-width: 1700px) {
    .aboutOneB {
        .aboutOneC2 {
            img {
                right: 5%;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .aboutOneB {
        background-size: cover;

        .aboutOneC2 {
            img {
                width: 400px;
            }

            .text {
                p {
                    font-size: 16px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1550px) {
    .aboutOneB {
        padding-top: 100px;

        .aboutOneC2 {
            .text {
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1440px) {
    .aboutOneB {
        padding: 100px 50px;

        .aboutOneC2 {
            gap: 0px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .aboutOneB {
        background-position: 0%;

        .aboutOneC2 {
            img {
                left: 0px !important;
                right: 0px !important;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .aboutOneB {
        padding-top: 50px;
        padding-bottom: 50px !important;
        background-image: none;

        .aboutOneC2 {
            .text {
                padding-top: 100px;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 2;
            }

            img {
                width: 350px;
                top: 100px;
                display: grid;
                justify-self: right;
                grid-column-start: 2;
                grid-column-end: 3;
            }
        }
    }
}

@media screen and (max-width: 800px) {
    .aboutOneB {
        padding-bottom: 0px !important;

        .aboutOneC2 {
            grid-template-columns: 100%;

            .text {
                padding-top: 40px;
                grid-row-start: 1;
                grid-row-end: 2;
            }

            img {
                top: 0px;
                justify-self: center;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 2;
                grid-row-end: 3;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .aboutOneB {
        .text {
            p {
                line-height: 30px !important;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .aboutOneB {
        padding: 50px 20px;

        img {
            width: 100% !important;
        }

        p {
            font-size: 14px !important;
            padding-top: 20px;
        }
    }
}