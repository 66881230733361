.warehouseNine{
    padding: 0px 150px 0px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 100px;
    background-image: url("../../img/warehouseStructure/warehouseNine/nine-bg.webp");
    background-repeat: no-repeat;
    background-position: 50% 30%;
    background-size: 100%;
    z-index: 1000;
    opacity: 0%;

    .nineText{
        max-width: 700px;
        h1{
            color: var(--cor-primary);
            font-weight: 700;
            opacity: 0%;

            span{
                font-size: 32px;
                color: var(--cor-secondary);
            }
        }
        h1.visible{
            animation: halfToLeft 1s .2s ease forwards;
        }
        p{
            padding: 50px 0px;
            font-size: 18px;
            line-height: 35px;
            opacity: 0%;
        }
        p.visible{
            animation: halfToLeft 1s .4s ease forwards;
        }
    }
    .nineImg{
        display: grid;

        .img1{
            position: relative;
            opacity: 0%;
            width: 650px;
        }
        .img1.visible{
            animation: halfToLeft 1s .6s ease forwards;
        }
    }
}
.warehouseNine.visible{
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .warehouseNine{
        padding: 0px 100px 0px;
        background-size: 1600px;

        .nineText{
            p{
                font-size: 16px;
            }
        }
        .nineImg{
            .img1{
                width: 550px;
            }
        }
    }
}

@media screen and (max-width: 1300px){
    .warehouseNine{
        background-size: 1500px;
        .nineText{
            max-width: 500px;

            p{
                line-height: 30px;
            }
        }
        .nineImg{
            .img1{
                left: 0px;
            }
            .img2{
                left: 70px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .warehouseNine{
        padding: 0px 50px 20px 50px;
        grid-template-columns: 100%;
        gap: 0px;
        background-image: none;

        .nineText{
            max-width: 100%;
        
            p{
                padding: 30px 0px 0px;
            }
        }
        .nineImg{
            background-image: url('../../img/warehouseStructure/warehouseNine/imgBg.png');
            background-repeat: no-repeat;
            background-size: 300px;
            background-position: 50% 80%;
            width: fit-content;
            justify-self: end;
            grid-template-columns: auto auto;
        }
    }
}

@media screen and (max-width: 700px){
    .warehouseNine{
        padding: 0px 50px 20px 50px;

        .nineImg{
            .img1{
                width: 100%;
            }
        }
    }
}
@media screen and (max-width: 550px){
    .warehouseNine{
        padding-top: 20px;
        .nineImg{
            background-size: 200px;
            img{
                width: 200px;
            }
        }
    }
}

@media screen and (max-width: 425px){
    .warehouseNine{
        padding:30px 20px;
        background-size: 1200px;

        .nineText{
            h1{
                span{
                    font-size: 25px;
                }
                margin: 0px;
            }
            p{
                padding-top: 20px;
                font-size: 14px;
                line-height: 25px;
            }
        }
        .nineImg{
            padding-top: 50px;
            width: 100%;
            img{
                width: 100%;
            }
        }
    }
}