.homeBlog {
    padding-top: 0px !important;
    padding: 150px;
    padding-bottom: 0px;
    height: fit-content;

    .homeBlogTitle {
        h1 {
            color: var(--cor-primary);
            font-weight: 700;
            width: 500px;
            opacity: 0%;
        }

        h1.visible {
            animation: halfToRight 1s ease forwards;
        }

        span {
            color: var(--cor-secondary);
        }

        p {
            padding-top: 20px;
            width: 600px;
            font-size: 18px;
            opacity: 0%;
        }

        p.visible {
            animation: halfToRight 1s .2s ease forwards;
        }
    }

    .homeBlog2 {
        .swiper {
            padding: 20px 20px 50px;
            opacity: 0%;

            .swiper-pagination {
                opacity: 100%;

            }

            .swiper-slide {
                transition: .5s;
                cursor: pointer;
                width: 350px;
                /* height: 500px; */

                a {
                    .blogCard {
                        opacity: 0%;
                        height: 450px;
                        width: 100%;
                        padding: 20px;
                        border-radius: 10px;
                        box-shadow: 10px 10px 10px 2px rgba(0, 0, 0, 0.246);
                        transition: .5s ease;

                        img {
                            width: 100%;
                            height: 200px;
                            max-height: 200px;
                            min-height: 200px;
                            object-fit: cover;
                            transition: .5s;
                        }

                        h1 {
                            color: var(--cor-primary);
                            padding-top: 30px;
                            font-size: 18px !important;
                            font-weight: 600;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            transition: .5s;
                        }

                        p {
                            color: black;
                            padding-top: 10px;
                            font-size: 14px;
                            display: -webkit-box;
                            overflow: hidden;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 5;
                            transition: .5s;
                        }
                    }

                    .card1.visible {
                        animation: slideToUp 2s ease forwards;
                    }

                    .card2.visible {
                        animation: slideToUp 2s .2s ease forwards;
                    }

                    .card3.visible {
                        animation: slideToUp 2s .4s ease forwards;
                    }

                    .card4.visible {
                        animation: slideToUp 2s .6s ease forwards;
                    }

                    .card5.visible {
                        animation: slideToUp 2s .8s ease forwards;
                    }

                    .card6.visible {
                        animation: slideToUp 2s 1s ease forwards;
                    }

                    .card7.visible {
                        animation: slideToUp 2s 1.2s ease forwards;
                    }

                    .card8.visible {
                        animation: slideToUp 2s 1.4s ease forwards;
                    }

                    .card9.visible {
                        animation: slideToUp 2s 1.6s ease forwards;
                    }

                    .card10.visible {
                        animation: slideToUp 2s 1.8s ease forwards;
                    }
                }
            }

            .swiper-slide:hover {
                transform: translateY(-30px);

                a {
                    .blogCard {
                        img {
                            scale: 1.05;
                            border-radius: 5px;
                        }
                    }
                }
            }

            .swiper-slide-prev {
                .blogCard {
                    box-shadow: none !important;
                }
            }
        }

        .swiper.visible {
            animation: halfToUp 1s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px) {
    .homeBlog {
        padding: 0px 50px !important;

        .homeBlogTitle {
            p {
                font-size: 16px !important;
            }
        }

        .homeBlog2 {
            .swiper {
                /* height: 450px; */

                .swiper-slide {
                    /* height: 360px; */

                    a {
                        .blogCard {
                            h1 {
                                font-size: 15px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .homeBlog {
        .homeBlogTitle {
            p {
                font-size: 16px !important;
            }
        }

        .homeBlog2 {
            .swiper {
                .swiper-slide {
                    a {
                        .blogCard {
                            height: 420px;

                            img {
                                height: 180px;
                                max-height: 180px;
                                min-height: 180px;
                            }

                            h1 {
                                font-size: 16px !important;
                            }

                            p {
                                font-size: 14px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .homeBlog {
        padding: 0px 20px !important;

        .homeBlogTitle {
            padding: 0px 30px !important;
        }

        .homeBlog2 {
            .swiper {
                .swiper-slide {
                    /* height: 380px; */

                    a {
                        .blogCard {
                            h1 {
                                font-size: 14px !important;
                            }

                            p {
                                font-size: 12px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .homeBlog {
        padding-top: 50px !important;

        .homeBlogTitle {

            h1,
            p {
                width: 100%;
            }
        }

        .homeBlog2 {
            .swiper {
                .swiper-slide {
                    /* height: 350px; */

                    a {
                        .blogCard {
                            height: 400px;

                            h1 {
                                font-size: 14px !important;
                            }

                            p {
                                font-size: 12px !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px) {
    .homeBlog {
        .homeBlog2 {
            .swiper {
                padding: 20px;
                height: fit-content;

                .swiper-pagination {
                    position: relative;
                    top: 20px;
                }

                .swiper-slide {
                    height: fit-content;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .homeBlog {
        padding: 0px !important;

        .homeBlogTitle {
            padding: 0px 20px;
        }

        .homeBlog2 {
            .swiper {
                .swiper-pagination {
                    span {
                        font-size: 14px !important;
                        margin: 0px 5px !important;
                    }
                }

                .swiper-slide a {
                    .blogCard {
                        height: 380px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .homeBlog {
        .homeBlog2 {
            .swiper {
                .swiper-slide a {
                    .blogCard {
                        height: 400px;
                    }
                }
            }
        }
    }
}