.aboutFour {
    padding: 20px 0px;

    .aboutFourTitle {
        text-align: center;

        h1 {
            color: var(--cor-primary);
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToDown 1s ease forwards;
        }
    }

    .aboutFourContent1 {
        display: flex;

        padding-top: 50px;
        text-align: center;
        justify-content: center;

        gap: 5%;

        .card1,
        .card2,
        .card3 {
            border: none;
            opacity: 0%;
            display: grid;
            grid-template-areas: 'card';
            cursor: pointer;

            justify-self: center;
            justify-content: center;
            align-items: center;

            width: 300px;

            img {
                transition: .3s ease;
                grid-area: card;
            }

            .cardText {
                width: 100%;
                grid-area: card;
                justify-content: center;
                padding: 0px 50px;
                transition: .3s ease;

                h2 {
                    margin-bottom: 5px;
                    font-size: 25px;
                    color: var(--cor-secondary);
                    font-weight: 700;
                }
            }
        }

        .card:hover {
            img {
                filter: blur(5px);
            }

            .cardText {
                scale: 1.1;
            }
        }

        .card2 {
            position: relative;
            top: 150px;
            width: 150px;

            .cardText {
                padding: 0px;
            }
        }

        .card1.visible {
            animation: halfToRight 1s .5s ease forwards;
        }

        .card2.visible {
            animation: slideToUp 1s ease forwards;
        }

        .card3.visible {
            animation: halfToLeft 1s .5s ease forwards;
        }
    }

    .swiper {
        padding: 150px 20px 50px 0px;
        width: 80%;

        .swiper-slide {
            height: auto;

            .card1,
            .card2,
            .card3,
            .card4,
            .card5,
            .card6 {
                cursor: pointer;
                opacity: 0%;
                border-radius: 10px;
                box-shadow: 10px 10px 10px 2px rgba(0, 0, 0, 0.219);
                padding: 50px 30px 10px !important;
                transition: .5s ease;

                h2 {
                    font-size: 22px !important;
                    color: var(--cor-primary);
                    margin: 0px;
                }

                p {
                    margin-top: 20px;
                    font-weight: 400;
                    color: rgb(48, 48, 48);
                }
            }

            .card {
                border: none;
                background: none;
                transition: .5s ease;
                height: 100%;
                border: 1px solid var(--cor-tertiary);

                .underline {
                    height: 2px;
                    width: 100px;
                    transition: .5s ease;
                    background-color: var(--cor-secondary);
                }
            }

            .card:hover {
                border: 1px solid var(--cor-primary);
                box-shadow: none;
                background-color: rgb(250, 250, 250);

                .underline {
                    width: 20px;
                }

                p {
                    color: var(--cor-tertiary);
                }
            }

            .card1.visible {
                animation: slideToDown 2s 1s ease forwards;
            }

            .card2.visible {
                animation: slideToDown 2s 1.2s ease forwards;
            }

            .card3.visible {
                animation: slideToDown 2s 1.4s ease forwards;
            }

            .card4.visible {
                animation: slideToDown 2s 1.6s ease forwards;
            }

            .card5.visible {
                animation: slideToDown 2s 1.6s ease forwards;
            }

            .card6.visible {
                animation: slideToDown 2s 1.6s ease forwards;
            }
        }
    }
}

@media screen and (max-width: 1600px) {
    .aboutFour {
        .aboutFourContent1 {

            .card1,
            .card3 {
                img {
                    width: 250px;
                }

                .cardText {
                    width: 250px;
                    padding: 0px 20px;
                }
            }
        }

        .swiper {
            .swiper-slide {
                .card {
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .aboutFour {
        .swiper {
            .swiper-slide {
                .card {
                    h2 {
                        font-size: 20px !important;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .aboutFour {
        .swiper {
            width: 90%;
        }
    }
}

@media screen and (max-width: 1000px) {
    .aboutFour {
        .aboutFourContent1 {
            gap: 0px;
        }

        .aboutFourContent2 {
            grid-template-columns: auto auto;
            grid-template-rows: auto;

            .card1,
            .card2,
            .card3,
            .card4 {
                padding: 50px 20px 20px !important;
                height: fit-content;
            }

            .card1,
            .card2 {
                grid-row-start: 1;
                grid-row-end: 2;
            }

            .card3,
            .card4 {
                grid-row-start: 2;
                grid-row-end: 3;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .aboutFour {
        .aboutFourContent1 {

            .card1,
            .card3 {
                width: 200px !important;

                .cardText {
                    width: 200px !important;

                    h2 {
                        font-size: 22px !important;
                    }

                    p {
                        font-size: 14px;
                    }
                }

                img {
                    width: 200px !important;
                }
            }

            .card2 {
                .cardText {
                    h2 {
                        font-size: 22px !important;
                    }
                }
            }
        }

        .aboutFourContent2 {
            grid-template-columns: 100%;
            grid-template-rows: auto auto auto auto;

            .card1,
            .card2,
            .card3,
            .card4 {
                padding: 30px 10px 10px !important;

                h2 {
                    font-size: 20px !important;
                }

                p {
                    font-size: 14px !important;
                }
            }

            .card1 {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 2;
            }

            .card2 {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 2 !important;
                grid-row-end: 3 !important;

                h2 {
                    padding: 0px !important;
                }
            }

            .card3 {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 3 !important;
                grid-row-end: 4 !important;
            }

            .card4 {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 4 !important;
                grid-row-end: 5 !important;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .aboutFour {
        .aboutFourContent1 {
            display: grid;
            grid-template-columns: 50% 50%;

            .card1 {
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 2;
            }

            .card2 {
                top: 0px;
                justify-self: center;
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 2;
                grid-row-end: 3;
            }

            .card3 {
                grid-column-start: 2;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 2;
            }
        }

        .swiper {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
}

@media screen and (max-width: 600px) {
    .aboutFour {
        .aboutFourContent1 {
            .card3 {
                top: 100px;
                position: relative;
                grid-column-start: 2;
                grid-column-end: 3;
            }

            .card2 {
                padding-top: 20px;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 2;
                grid-row-end: 3;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .aboutFour {
        padding-top: 40px;

        .aboutFourContent1 {
            padding: 20px 30px 0px;
            grid-template-columns: 100%;

            h2 {
                font-size: 18px !important;
            }

            .card1 {
                justify-self: left;
            }

            .card2 {
                padding: 0px;
                justify-self: left;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 3;
                grid-row-end: 4;
            }

            .card3 {
                justify-self: right;
                top: 0px !important;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 2 !important;
                grid-row-end: 3 !important;
            }
        }

        .aboutFourContent2 {
            padding: 20px 20px;
        }
    }
}