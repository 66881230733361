.fleetFour {
    padding: 80px 0px 0px;
    padding-bottom: 0px !important;
    background-image: url('../../img/fleetStructure/fleetFour/fleetFour-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    opacity: 0%;
    transition: .5s ease;

    .fleetFourTitle {
        text-align: center;
        display: grid;
        justify-content: center;

        h1 {
            justify-self: center;
            font-weight: 700;
            color: var(--cor-primary);
            width: 100%;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToUp 1s ease forwards;
        }

        span {
            color: var(--cor-secondary);
        }
    }

    .swiper {
        padding: 50px 200px;

        .swiper-button-prev:after,
        .swiper-button-next:after {
            display: none;
        }

        .swiper-button-next {
            right: 150px;
        }

        .swiper-button-prev {
            left: 150px;
        }

        .swiper-slide-active {
            .card {
                opacity: 100% !important;
            }
        }

        .swiper-slide {
            .card {
                display: grid;
                align-items: center;
                grid-template-columns: 50% 50%;
                padding: 50px 0px 50px 50px;
                border: none;
                box-shadow: 7px 10px 10px 2px rgba(0, 0, 0, 0.246);
                background-color: rgba(255, 255, 255, 0.699);
                border-radius: 30px;
                backdrop-filter: blur(5px);
                transition: .5s ease;
                opacity: 0%;
                min-height: 50vh;

                .cardText {
                    padding-top: 30px;
                    display: grid;
                    height: fit-content;
                    align-self: center;
                    gap: 5px;

                    h3 {
                        font-weight: 700;
                        color: var(--cor-primary);
                        margin: 0px;
                        opacity: 0%;
                    }

                    h3.visible {
                        animation: slideToUp 1s .4s ease forwards;
                    }

                    h4 {
                        font-weight: 600;
                        color: var(--cor-secondary);
                        font-size: 20px;
                        margin: 0px;
                        opacity: 0%;
                    }

                    h4.visible {
                        animation: slideToUp 1s .6s ease forwards;
                    }

                    p {
                        width: 100%;
                        line-height: 25px;
                        padding-top: 10px;
                        margin: 0px;
                        font-size: 16px;
                        opacity: 0%;
                        color: rgb(80, 80, 80);

                        .seta {
                            margin: 0px 5px 5px;
                            width: 20px;
                            opacity: 100%;
                        }
                    }

                    p.visible {
                        animation: slideToUp 2s .8s ease forwards;
                    }
                }

                img {
                    justify-self: end;
                    opacity: 0%;
                }

                img.visible {
                    animation: slideToUp 2s .4s ease forwards;
                }
            }

            .gheco {
                img {
                    width: 400px;
                    justify-self: center;
                }
            }

            .oneText {

                .text2,
                .text3,
                .text4 {
                    display: none;
                }
            }

            .twoText {

                .text3,
                .text4 {
                    display: none;
                }
            }
        }
    }
}

.fleetFour.visible {
    opacity: 100%;
}

@media screen and (max-width: 1600px) {
    .fleetFour {
        padding: 100px 0px 50px;

        .fleetFourTitle {
            h1 {
                width: 450px;
            }
        }

        .swiper {
            .swiper-slide {

                .card {
                    padding: 50px 0px 50px 50px;

                    .cardText {
                        h3 {
                            font-size: 22px;
                        }

                        h4 {
                            font-size: 18px;
                        }

                        p {
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }

                    img {
                        width: 500px;
                    }
                }

                .gheco {
                    img {
                        width: 350px;
                        justify-self: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .fleetFour {
        padding: 50px 0px 50px;
        background-position: 0% 36%;

        .swiper {

            .swiper-button-next,
            .swiper-button-prev {
                img {
                    width: 30px;
                }
            }

            .swiper-slide {
                .card {
                    .cardText {
                        p {
                            line-height: 25px;
                        }
                    }

                    img {
                        width: 400px;
                    }
                }

                .gheco {
                    img {
                        width: 300px;
                        justify-self: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .fleetFour {
        .swiper {
            padding: 50px 100px;

            .swiper-button-next {
                right: 50px;
            }

            .swiper-button-prev {
                left: 50px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .fleetFour {
        .swiper {
            padding: 50px 50px;

            .swiper-button-next {
                right: 10px;
            }

            .swiper-button-prev {
                left: 10px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .fleetFour {
        padding: 0px 0px 50px;

        .swiper {
            .swiper-button-next {
                right: 30px;
            }

            .swiper-button-prev {
                left: 30px;
            }

            .swiper-slide {
                .card {
                    padding: 60px 0px 20px 20px;
                    grid-template-columns: 100%;

                    .cardText {
                        padding: 0px;

                        p {
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .fleetFour {
        .fleetFourTitle {
            padding: 0px 50px;

            h1 {
                width: 100%;
            }
        }

        .swiper {
            padding: 20px;

            .swiper-button-next {
                top: 55%;
                right: 10px;
            }

            .swiper-button-prev {
                top: 55%;
                left: 10px;
            }

            .swiper-slide {
                .card {
                    img {
                        width: 300px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .fleetFour {
        padding: 20px 0px;

        .swiper {

            .swiper-button-prev,
            .swiper-button-next {
                img {
                    width: 25px;
                }
            }

            .swiper-slide {
                .card {
                    padding-top: 40px;
                    border-radius: 10px;

                    .cardText {
                        h3 {
                            font-size: 18px;
                        }

                        h4 {
                            font-size: 16px;
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }

                .gheco {
                    img {
                        width: 100%;
                        justify-self: center;
                    }
                }
            }
        }
    }
}