.mediaButtons{
    z-index: 9999;
    position: fixed;
    bottom: 50px;
    left: 0px;
    opacity: 0%;

    display: grid;
    grid-template-areas: 'media';
    
    align-items: center;
    justify-content: center;

    padding: 90px 90px 70px 20px;

    border-radius: 50%;
    transition: .5s ease;

    button{
        z-index: 100;
        grid-area: media;
        border: none;
        background: none;
        display: grid;

        .mais{
            justify-self: center;
            align-self: center;
            transition: 0.5s ease-in-out;
            filter: grayscale(0%);
        }
    }

    .link{
        grid-area: media;
        justify-self: center;
        align-self: center;

        position: relative;

        background-color: var(--cor-primary);
        border-radius: 50px;
        padding: 8px;
        z-index: 10;

        a{
            display: grid;
            width: fit-content !important;
            height: fit-content;
        }
    }
    .curriculum{
        opacity: 0%;
        transition: .3s ease-in-out;
        background-color: var(--cor-secondary);
    }
    .whats{
        opacity: 0%;
        transition: .3s .05s ease-in-out;
    }
    .insta{
        opacity: 0%;
        transition: .3s .1s ease-in-out;
    }
    .linkedin{
        opacity: 0%;
        transition: .3s .15s ease-in-out;
    }
    .face{
        opacity: 0%;
        transition: .3s .2s ease-in-out;
    }
    .youtube{
        opacity: 0%;
        transition: .3s .25s ease-in-out;
    }
}
.mediaButtons.visible{
    animation: slideToRight 2s 1s ease forwards;
}
.mediaButtons:hover{
    .mais{
        filter:grayscale(20%);
        scale: .9;
    }
    .curriculum{
        pointer-events: all;
        opacity: 100%;
        transform: translate(10px, -80px);
    }
    .whats{
        opacity: 100%;
        transform: translate(50px, -60px);
    }
    .insta{
        opacity: 100%;
        transform: translate(75px, -22px);
    }
    .linkedin{
        opacity: 100%;
        transform: translate(75px, 22px);
    }
    .face{
        opacity: 100%;
        transform: translate(50px, 60px);
    }
    .youtube{
        opacity: 100%;
        transform: translate(10px, 80px);
    }
}

@media screen and (max-width: 1440px){
    .mediaButtons{
        bottom: 100px;
        padding: 40px 0px 40px 20px;

        .mais{
            width: 55px;
        }
        .link a img{
            width: 20px;
        }
    }
    .mediaButtons:hover{
        .mais{
            filter:grayscale(20%);
            scale: .9;
        }
        .curriculum{
            pointer-events: all;
            opacity: 100%;
            transform: translate(10px, -70px);
        }
        .whats{
            opacity: 100%;
            transform: translate(44px, -52px);
        }
        .insta{
            opacity: 100%;
            transform: translate(65px, -19px);
        }
        .linkedin{
            opacity: 100%;
            transform: translate(65px, 19px);
        }
        .face{
            opacity: 100%;
            transform: translate(44px, 52px);
        }
        .youtube{
            opacity: 100%;
            transform: translate(10px, 70px);
        }
    }
}

@media screen and (max-width: 768px){
    .mediaButtons{
        bottom: 80px;
        padding: 40px 0px 40px 20px;

        .link a img{
            width: 20px;
        }
    }
    .mediaButtons:hover{
        .mais{
            filter:grayscale(20%);
            scale: .9;
        }
    }
}