#Fleet-structure-truck {
    margin-top: 10rem;

    h1 {
        opacity: 0%;
        font-weight: 700;
        color: var(--cor-primary);
        text-align: center;

        span {
            color: var(--cor-secondary);
        }
    }
    h1.visible{
        animation: halfToUp 1s ease forwards;
    }

    p {
        text-align: center;
        line-height: 2rem;
        opacity: 0%;
    }
    p.visible{
        animation: halfToUp 1s .1s ease forwards;
    }
    iframe{
        opacity: 0%;
    }
    iframe.visible{
        animation: halfToUp 1s .2s ease forwards;
    }

}