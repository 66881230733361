#section-visible-publi {
    padding: 16rem 9rem 9rem;


    .align-item-title {
        display: flex;
        justify-content: space-between;
        align-items: center;

        color: #000;
        font-weight: 600;
    }

    .card {
        border: none;

        a{
            color: #0945c7 !important;
            font-weight: 600;
        }
    }

    .card-title {
        color: var(--cor-primary);
        font-size: 25px;
        font-weight: 600;
        margin: 2rem 0rem;
    }

    p {
        font-size: 16px;
    }

    .text-visible {
        color: var(--cor-primary);
        font-weight: 500;
    }
}

.tittle-visible-publi {
    color: var(--cor-primary);
    font-weight: 600;
    padding: 0rem 3rem;

}

@media screen and (max-width: 800px) {

    #section-visible-publi {
        padding: 10rem 4rem;
    }
}