.esgFour{
    padding: 80px 0px 0px;
    padding-bottom: 0px !important;
    background-image: url('../../img/esg/esgFour/esgFour-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0%;
    transition: 1s ease;

    .esgFourTitle{
        text-align: center;
        display: grid;
        justify-content: center;

        h1{
            justify-self: center;
            font-weight: 700;
            color: var(--cor-primary);
            width: 500px;
            opacity: 0%;
        }
        h1.visible{
            animation: slideToUp 1s .1s ease forwards;
        }
        span{
            color: var(--cor-secondary);
        }
        p{
            max-width: 1000px;
            font-size: 18px;
            opacity: 0%;
        }
        p.visible{
            animation: slideToUp 1s .2s ease forwards;
        }
    }
    .swiper{
        padding: 50px 200px;
        /* opacity: 0%; */

        .swiper-button-prev:after, .swiper-button-next:after{
            display: none;
        }
        .swiper-button-next{
            right: 150px;
            opacity: 0%;
        }
        .swiper-button-next.visible{
            animation: fadeIn 1s 1s ease forwards;
        }
        .swiper-button-prev{
            opacity: 0%;
            left: 150px;
        }
        .swiper-button-prev.visible{
            animation: fadeInRight 1s 1s ease forwards;
        }
        .swiper-slide{
            .card{
                max-height: 1000px;
                display: grid;
                align-items: center;
                grid-template-columns: 50% 50%;
                padding: 100px 0px 50px 50px;
                border: none;
                box-shadow: 7px 10px 10px 2px rgba(0, 0, 0, 0.246);
                -webkit-backdrop-filter: blur(10.5px);
                backdrop-filter: blur(5px);
                background-color: rgba(255, 255, 255, 0.726);
                border-radius: 30px;

                .cardText{
                    padding-top: 30px;
                    display: grid;
                    height: fit-content;
                    align-self: center;
                    gap: 5px;

                    h3{
                        font-weight: 700;
                        color: #3A904F;
                        margin: 0px;
                        opacity: 0%;
                        font-size: 25px;
                    }
                    h3.visible{
                        animation: slideToUp 1s .3s ease forwards;
                    }
                    h4{
                        font-weight: 600;
                        color: var(--cor-secondary);
                        font-size: 20px;
                        margin: 0px;
                        opacity: 0%;
                    }
                    h4.visible{
                        animation: slideToUp 1s .4s ease forwards;
                    }
                    p{
                        width: 80%;
                        line-height: 35px;
                        padding-top: 10px;
                        font-size: 16px;
                        opacity: 0%;
                    }
                    p.visible{
                        animation: slideToUp 1s .5s ease forwards;
                    }
                }
                .cardImg{
                    display: grid;
                    justify-content: right;

                    .plvb{
                        position: absolute;
                        top: 20px;
                        right: 10px;
                        width: 150px !important;
                    }
                    img{
                        justify-self: end;
                        opacity: 0%;
                    }
                    img.visible{
                        animation: slideToUp 2s .4s ease forwards;
                    }
                }
            }
            .card1{
                h3{
                    color: #3A904F;
                }
            }
            .card2{
                h3{
                    color: #fcbd11 !important;
                }
                .plvb{
                    display: none;
                }
            }
            .card3{
                h3{
                    color: #ee005a !important;
                }
                .plvb{
                    display: none;
                }
            }
            .card4{
                h3{
                    color: #028cf2 !important;
                }
                .plvb{
                    display: none;
                }
            }
        }
    }
    /* .swiper.visible{
        animation: changeOpacity 2s .2s ease forwards;
    } */
}
.esgFour.visible{
    opacity: 100%;
}

@media screen and (max-width: 1600px){
    .esgFour{
        padding: 50px 0px 0px;

        .esgFourTitle{
            h1{
                width: 450px;
            }
            p{
                font-size: 16px;
            }
        }
        .swiper{
            padding-bottom: 0px;
            .swiper-slide{
                .card{
                    padding: 70px 0px 30px 50px; 
                    .cardText{
                        h3{
                            font-size: 22px;
                        }
                        h4{
                            font-size: 18px;
                        }
                        p{
                            font-size: 14px;
                            line-height: 30px;
                        }
                    }
                    img{
                        width: 550px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px){
    .esgFour{
        padding: 50px 0px 50px;

        .swiper{
            .swiper-button-next, .swiper-button-prev{
                img{
                    width: 30px;
                }
            }
            .swiper-slide{
                .card{
                    padding: 30px 30px 30px!important;
                    display: grid;

                    .cardText{
                        p{
                            margin: 0px;
                            line-height: 25px;
                        }
                    }
                    .cardImg{
                        align-self: end;
                        img{
                            width: 500px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .esgFour{
        padding: 20px 0px 0px;
        .esgFourTitle{
            p{
                margin: 0px;
                padding: 10px 50px 0px;
                width: 100%;
            }
        }
        .swiper{
            padding: 50px 100px 0px;

            .swiper-button-next{
                right: 50px;
            }
            .swiper-button-prev{
                left: 50px;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .esgFour{
        .swiper{
            padding: 50px 50px 0px;

            .swiper-button-next{
                right: 10px;
            }
            .swiper-button-prev{
                left: 10px;
            }
        }
    }
}

@media screen and (max-width: 900px){
    .esgFour{
        padding: 20px 0px;
        .swiper{
            .swiper-button-next{
                right: 30px;
            }
            .swiper-button-prev{
                left: 30px;
            }
            .swiper-slide{
                .card{
                    padding: 60px 0px 20px 20px;
                    grid-template-columns: 100%;

                    .cardImg{
                        padding-top: 20px;
                        .plvb.visible{
                            width: 100px !important;
                            top: auto;
                            right: auto;
                            bottom: 10px;
                            left: 10px;
                        }
                    }
                    .cardText{
                        padding: 0px;
                        p{
                            padding-right: 20px;
                            width: 100%;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
    .esgFour{
        padding: 0px;
        .esgFourTitle{
            padding: 50px 50px 20px;

            h1{
                width: 100%;
            }
            p{
                padding: 0px;
            }
        }
        .swiper{
            padding: 20px;

            .swiper-button-next{
                top: 55%;
                right: 10px;
            }
            .swiper-button-prev{
                top: 55%;
                left: 10px;
            }

            .swiper-slide{
                .card{
                    padding-bottom: 60px !important;
                    .cardImg{
                        img{
                            width: 400px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px){
    .esgFour{
        padding: 0px 0px 40px;

        .esgFourTitle{
            padding: 0px 20px;

            p{
                font-size: 14px;
            }
        }
        .swiper{
            .swiper-button-prev, .swiper-button-next{
                img{
                    width: 25px;
                }
            }
            .swiper-slide{
                .card{
                    padding-top: 40px;
                    padding-bottom: 60px;
                    border-radius: 10px;
                    .cardText{
                        h3{
                            font-size: 18px;
                        }
                        h4{
                            font-size: 16px;
                        }
                        p{
                            font-size: 14px;
                        }
                    }
                    .cardImg{
                        img{
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}