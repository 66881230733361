.fleetFive{
    padding: 100px 0px 0px;
    background-image: url('../../img/fleetStructure/fleetFive/fleetFive-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    opacity: 0%;
    transition: .5s ease;

    .fleetFiveTitle{
        text-align: center;
        display: grid;
        justify-content: center;

        h1{
            justify-self: center;
            font-weight: 700;
            color: var(--cor-primary);
            width: 500px;
            opacity: 0%;
        }
        h1.visible{
            animation: slideToDown 2s ease forwards;
        }
        span{
            color: var(--cor-secondary);
        }
    }
    .swiper{
        padding: 50px 200px;

        .swiper-button-prev:after, .swiper-button-next:after{
            display: none;
        }
        .swiper-button-next{
            right: 150px;
        }
        .swiper-button-prev{
            left: 150px;
        }
        .swiper-slide-active{
            .card{
                opacity: 100% !important;
            }
        }
        .swiper-slide{
            .card{
                max-height: 1000px;
                display: grid;
                align-items: center;
                grid-template-columns: 50% 50%;
                padding: 50px 0px 0px 50px;
                border: none;
                box-shadow: 7px 10px 10px 2px rgba(0, 0, 0, 0.246);
                -webkit-backdrop-filter: blur(10.5px);
                background-color: rgba(255, 255, 255, 0.699);
                border-radius: 30px;
                backdrop-filter: blur(5px);
                opacity: 0%;
                transition: .5s ease;

                .cardText{
                    padding-top: 30px;
                    display: grid;
                    height: fit-content;
                    align-self: center;
                    gap: 5px;

                    h3{
                        font-weight: 700;
                        color: var(--cor-primary);
                        margin: 0px;
                        opacity: 0%;
                    }
                    h3.visible{
                        animation: slideToDown 2s .4s ease forwards;
                    }
                    h4{
                        font-weight: 600;
                        color: var(--cor-secondary);
                        font-size: 20px;
                        margin: 0px;
                        opacity: 0%;
                    }
                    h4.visible{
                        animation: slideToDown 2s .6s ease forwards;
                    }
                    p{
                        width: 100%;
                        line-height: 35px;
                        padding-top: 10px;
                        font-size: 18px;
                        margin: 0px;
                        opacity: 0%;
                    }
                    p.visible{
                        animation: slideToDown 2s .8s ease forwards;
                    }
                }
                img{
                    grid-column-start: 1;
                    grid-column-end: 3;
                    justify-self: end;
                    opacity: 0%;
                }
                img.visible{
                    animation: halfToDown 2s .4s ease forwards;
                }
            }
        }
    }
}
.fleetFive.visible{
    opacity: 100%;
}

@media screen and (max-width: 1600px){
    .fleetFive{
        padding: 80px 0px 0px;

        .fleetFiveTitle{
            h1{
                width: 450px;
            }
        }
        .swiper{
            .swiper-slide{
                .card{
                    padding: 20px 0px 0px 50px; 
                    .cardText{
                        h3{
                            font-size: 22px;
                        }
                        h4{
                            font-size: 18px;
                        }
                        p{
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                    img{
                        width: 500px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1400px){
    .fleetFive{
        .swiper{
            .swiper-button-next, .swiper-button-prev{
                img{
                    width: 30px;
                }
            }
            .swiper-slide{
                .card{
                    .cardText{
                        p{
                            line-height: 25px;
                        }
                    }
                    img{
                        width: 400px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .fleetFive{
        .swiper{
            padding: 50px 100px;

            .swiper-button-next{
                right: 50px;
            }
            .swiper-button-prev{
                left: 50px;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .fleetFive{
        .swiper{
            padding: 50px 50px;

            .swiper-button-next{
                right: 10px;
            }
            .swiper-button-prev{
                left: 10px;
            }

            .card{
                .cardText{
                    padding-bottom: 20px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .fleetFive{
        .swiper{
            .swiper-button-next{
                right: 30px;
            }
            .swiper-button-prev{
                left: 30px;
            }
            .swiper-slide{
                .card{
                    padding: 60px 0px 20px 20px;
                    grid-template-columns: 100%;

                    .cardText{
                        padding: 0px 20px 0px 0px;
                        p{
                            line-height: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
    .fleetFive{
        .fleetFiveTitle{
            padding: 0px 50px;

            h1{
                width: 100%;
            }
        }
        .swiper{
            padding: 20px;

            .swiper-button-next{
                top: 55%;
                right: 10px;
            }
            .swiper-button-prev{
                top: 55%;
                left: 10px;
            }

            .swiper-slide{
                .card{
                    img{
                        width: 300px;
                        max-width: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px){
    .fleetFive{
        padding: 70px 0px;
        .swiper{
            .swiper-button-prev, .swiper-button-next{
                img{
                    width: 25px;
                }
            }
            .swiper-slide{
                .card{
                    padding-top: 40px;
                    border-radius: 10px;
                    .cardText{
                        h3{
                            font-size: 18px;
                        }
                        h4{
                            font-size: 16px;
                        }
                        p{
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}
