.warehouseSeven {
    padding: 80px 250px 50px;

    display: grid;
    align-items: center;
    justify-content: right;
    grid-template-columns: auto 70%;
    column-gap: 50px;

    h1 {
        grid-column-start: 2;
        grid-column-end: 3;
        color: var(--cor-primary);
        font-weight: 700;
        max-width: 500px;
        opacity: 0%;
    }

    h1.visible {
        animation: halfToRight 2s ease forwards;
    }

    p {
        grid-column-start: 2;
        grid-column-end: 3;
        line-height: 30px;
        font-size: 18px;
        max-width: 1000px;
        opacity: 0%;
    }

    p.visible {
        animation: halfToRight 2s ease forwards;
    }

    img {
        width: 200px;
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 2;
        opacity: 0%;
    }

    img.visible {
        animation: halfToRight 2s ease forwards;
    }
}

@media screen and (max-width: 1600px) {
    .warehouseSeven {
        padding: 50px 150px 50px;

        p {
            font-size: 16px
        }
    }
}

@media screen and (max-width: 1400px) {
    .warehouseSeven {
        padding: 50px 100px 50px;

        img {
            width: 180px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .warehouseSeven {
        padding: 50px 50px 30px;
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 700px) {
    .warehouseSeven {
        padding: 20px 50px 0px;

        img {
            position: relative;
            right: 0px;
        }

        .text {
            p {
                line-height: 25px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .warehouseSeven {
        padding: 20px 20px 30px;
        gap: 20px 0px;
        align-items: center;

        img {
            justify-self: start;
            width: 120px;
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 2;
        }

        h1 {
            grid-column-start: 2 !important;
            grid-column: 3;
            grid-row-start: 1;
            grid-row-end: 2;
            max-width: 250px;
            align-self: flex-end;
            margin: 0px;
        }

        p {
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3 !important;
        }
    }
}

@media screen and (max-width: 425px) {
    .warehouseSeven {
        grid-template-columns: auto auto;
        padding-top: 0px;
        img {
            top: 10px;
        }
    
        p {
            font-size: 16px;
        }
    }
}