.esgFive{
    text-align: center;
    display: grid;

    background-image: url('../../img/esg/esgFive/teste.webp');
    background-size: 100% 600px;
    background-position: 50% 100%;
    background-repeat: no-repeat;

    padding: 50px 0px;
    padding-bottom: 40px !important;

    .esgFiveTitle{
        padding: 0px 50x;
        display: grid;
        justify-content: center;
        h1{
            opacity: 0%;
            color: var(--cor-primary);
            font-weight: 700;
            margin: 0px;
        }
        h1.visible{
            animation: halfToDown 1s .2s ease forwards !important;
        }
        img{
            justify-self: center;
            padding: 30px 0px 20px;
            opacity: 0%;
        }
        img.visible{
            animation: halfToDown 1s .4s ease forwards;
        }
        p{
            opacity: 0%;
            justify-self: center;
            font-size: 18px;
            max-width: 1400px;
            margin: 0px;
            line-height: 30px;
        }
        p.visible{
            animation: halfToDown 1s .6s ease forwards;
        }
    }
    .esgFiveTitle.visible{
        animation: halfToDown 1s ease forwards;
    }
    .esgFiveContent{
        padding: 20px 0px 0px;
        display: grid;
        grid-template-columns: auto auto;
        align-items: center;
        opacity: 0%;

        .swiper{
            top: 40px;
            border-radius: 10px;
            width: 600px;
            padding: 20px 0px;
            opacity: 0%;

            .swiper-slide-active{
                scale: 1 !important;
                z-index: 2; 
                margin-left: 50px;
                opacity: 100% !important;
            }
            .swiper-slide-next{
                opacity: 100% !important;
                filter: blur(5px);
                z-index: 1;
                margin-left: -80px;
            }
            .swiper-slide{
                opacity: 0%;
                transition: .5s ease;
                scale: .6;
                width: 400px;

                .card{
                    background-color: rgba(255, 255, 255, 0);
                    width: 350px;
                    border: none;

                    img{
                        padding: 10px;
                        width: 350px !important;
                    }
                }
            }
        }
        .swiper.visible{
            animation: halfToRight 1s .8s ease forwards;
        }
        .video{
            display: grid;
            grid-template-areas: 'video';
            align-items: center;
            justify-self: left;
            margin-bottom: -50px;

            .phone{
                grid-area: video;
                height: 550px;
                justify-self: center;
            }

            video{
                grid-area: video;
                justify-self: center;
                height: 405px;
                border-radius: 5px;
                padding: 5px 100px;
                opacity: 0%;
            }
            video.visible{
                animation: halfToLeft 1s .8s ease forwards;
            }
        }
    }
    .esgFiveContent{
        animation: fadeIn 1s .5s ease forwards;
    }
    
}
.esgFive.visible{
    animation: changeOpacity 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .esgFive{
        padding: 50px;
        .esgFiveTitle{
            img{
                padding: 15px 0px;
            }            
            p{
                width: 100%;
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1440px){
    .esgFive{
        background-size: 100% 510px;

        .esgFiveContent{
            padding: 0px;

            .swiper{
                width: 500px;
                .swiper-slide{
                    .card{
                        img{
                            width: 300px !important;
                        }
                    }
                }
            }
            .video{
                margin-top: 20px;
            }
        }
    }
}

@media screen and (max-width: 1300px){
    .esgFive{
        .esgFiveContent{
            .swiper{
                justify-self: left;
                margin: 0px auto 0px 0px;
            }
            video{
                justify-self: right;
                margin-left: 0px;
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .esgFive{
        background-size: 100% 530px;
    }
}

@media screen and (max-width: 1050px){
    .esgFive{
        background-position: 0% 110%;
        background-size: 100% 500px;
        .esgFiveContent{
            video{
                padding: 5px 30px;
            }
            .swiper{
                width: 400px;
                .swiper-slide-active{
                    margin: 0px;
                    .card{
                        width: 400px;
                        display: grid;
                        justify-content: center;
                    }
                }
                .swiper-slide{
                    margin: 0px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .esgFive{
        padding-top: 20px;

        .esgFiveContent{
            .video{
                width: fit-content;
                margin-bottom: -80px;

                .phone{
                    height: 450px;
                }
                video{
                    padding: 0px;
                    height: 320px;
                }
            }
        }
    }
}

@media screen and (max-width: 700px){
    .esgFive{
        background-position: 0% 50%;
        background-size: 1000px;

        .esgFiveContent{
            grid-template-columns: 100%;


            .video{
                margin: 0px;
                padding-top: 20px;
                justify-self: center;
            }
            .swiper{
                width: 100%;
                grid-row-start: 2;
                grid-row-end: 3;
                
                .swiper-button-next, .swiper-button-prev{
                    img{
                        width: 30px;
                    }
                }
                .swiper-slide{
                    left: 8px;
                    filter: none !important;
                    scale: 1 !important;
                    opacity: 100% !important;
                    width: 33% !important;

                    .card{
                        padding: 0px !important;
                        width: 100% !important;
                        
                        img{
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 700px){
    .esgFive{
        .esgFiveContent{
            .swiper{
                .swiper-button-prev{
                    left: 25px;
                }
                .swiper-slide{
                    width: 50% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
    .esgFive{
        background-position: 0% 58%;
        .esgFiveContent{
            .video{
                width: 100%;
                
                .phone{
                    height: 600px;
                }
                video{
                    height: 430px;
                }
            }
            .swiper{
                .swiper-slide{
                    width: 50% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 500px){
    .esgFive{
        background-position: 0% 100%;
        background-size: 250%;
        .esgFiveContent{
            .swiper{
                padding: 0px;
                justify-content: center;
                max-width: 100%;
                justify-self: center;

                .swiper-slide{
                    box-sizing: border-box;
                    justify-self: center;
                    width: 100% !important;

                    img{
                        padding: 0px !important;
                        justify-self: center;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 425px){
    .esgFive{
        padding: 50px 20px;
        .esgFiveTitle{
            p{
                font-size: 14px;
            }
        }
        .esgFiveContent{
            video{
                justify-self: center;
                padding: 0px;
                height: 300px;
            }
            .swiper{
                .swiper-button-next{
                    right: 0px;
                }
                .swiper-button-prev{
                    left: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 350px){
    .esgFive{
        .esgFiveContent{
            .video{
                video{
                    height: 350px;
                }
                .phone{
                    height: 480px;
                }
            }
        }
    }
}