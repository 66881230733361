.warehouseTitle {
    padding-top: 80px;
    color: var(--cor-primary);
    width: 100%;
    font-weight: 700;
    text-align: center;
    opacity: 0%;

    span {
        color: var(--cor-secondary);
    }
}

.warehouseTitle.visible {
    animation: halfToUp 2s ease forwards;
}

.warehouseFour {
    padding: 50px 250px 0px;

    display: grid;
    align-items: center;
    justify-content: left;
    grid-template-columns: 60% auto;
    column-gap: 10px;

    h1 {
        grid-column-start: 1;
        grid-column-end: 2;
        color: var(--cor-primary);
        font-weight: 700;
        max-width: 500px;
        opacity: 0%;
    }

    h1.visible {
        animation: halfToLeft 1s ease forwards;
    }

    p {
        grid-column-start: 1;
        grid-column-end: 2;
        line-height: 30px;
        font-size: 18px;
        max-width: 1000px;
        opacity: 0%;
    }

    p.visible {
        animation: halfToLeft 1s ease forwards;
    }

    ul {
        grid-column-start: 1;
        grid-column-end: 3;
        padding: 0px;
        opacity: 0%;

        .seta {
            width: 20px;
            margin: 0px 5px 5px;
            opacity: 100%;
            rotate: 180deg;
            cursor: default;
        }

        li {
            font-weight: 600;
            color: var(--cor-tertiary);
        }
    }

    ul.visible {
        animation: halfToLeft 2s ease forwards;
    }

    img {
        width: 200px;
        grid-row-start: 1;
        grid-row-end: 3;
        grid-column-start: 2;
        grid-column-end: 3;
        opacity: 0%;
        transition: .5s ease;
        right: 0px;
        cursor: pointer;
    }

    img.visible {
        animation: halfToLeft 2s ease forwards;
    }
}

@media screen and (max-width: 1600px) {
    .warehouseFour {
        padding: 100px 150px 0px;

        p {
            font-size: 16px
        }
    }
}

@media screen and (max-width: 1400px) {
    .warehouseFour {
        padding: 100px 100px 0px;

        img {
            width: 150px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .warehouseFour {
        padding: 80px 50px 0px;
        grid-template-columns: auto auto;
    }
}

@media screen and (max-width: 700px) {
    .warehouseFour {
        padding: 50px 50px 0px;

        img {
            position: relative;
            right: 0px;
        }

        .text {
            p {
                line-height: 25px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .warehouseFour {
        padding: 50px 20px 0px;
        gap: 20px;

        img {
            justify-self: end;
            width: 100px;
            grid-row-start: 1;
            grid-row-end: 2;
            grid-column-start: 2;
            grid-column-end: 3;
        }

        h1 {
            grid-column-start: 1 !important;
            grid-column: 2;
            grid-row-start: 1;
            grid-row-end: 2;
            max-width: 250px;
            align-self: flex-end;
            margin: 0px;
        }

        p {
            width: 100%;
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 2;
            grid-row-end: 3 !important;
            line-height: 25px;
        }
    }
}

@media screen and (max-width: 425px) {
    .warehouseFour {
        img {
            top: 10px;
        }

        p {
            font-size: 16px;
            margin: 0px;
        }

        ul {
            li {
                font-size: 14px;
            }
        }
    }
}