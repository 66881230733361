.aboutOne{
    padding: 100px 150px;
    padding-bottom: 0px !important;
    background-image: url('../../img/about/aboutOne/aboutOne-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0%;

    .aboutOneC1{
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        gap: 50px;
    
        img{
            top: 150px;
            left: 100px;
            position: relative;
            justify-self: left;
            opacity: 0%;
            transition: .5s ease;
            cursor: pointer;
        }
        img:hover{
            scale: 1.05;
        }
        img.visible{
            animation: slideToRight 2s .5s ease forwards;
        }
        .text{
            padding: 0px 200px 0px 0px;
            width: 100%;

            span{
                font-size: 50px;
            }
            h1{
                width: 300px;
                font-weight: 700;
                color: var(--cor-primary);
                opacity: 0%;
            }
            h1.visible{
                animation: halfToLeft 2s .2s ease forwards;
            }
            p{
                font-size: 18px;
                line-height: 40px;
                opacity: 0%;
            }
            p.visible{
                animation: halfToLeft 2s .2s ease forwards;
            }
        }
    }
}
.aboutOne.visible{
    animation: changeOpacity 2s ease forwards;
}

@media screen and (max-width: 1600px){
    .aboutOne{
        .aboutOneC1{
            .text{
                p{
                    font-size: 16px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1550px){
    .aboutOne{
        padding-top: 100px;
        .aboutOneC1{
            img{
                width: 400px;
                left: 10%;
            }
            .text{
                padding: 0px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 1440px){
    .aboutOne{
        padding: 100px 50px;

        .aboutOneC1{
            h1{
                width: 200px !important;
            }
            span{
                font-size: 40px !important;
            }
        }
        .aboutOneC1{
            gap: 0px;
        }
    }
}

@media screen and (max-width: 1200px){
    .aboutOne{
        background-position: 0%;
        .aboutOneC1{
            img{
                left: 0px !important;
               right: 0px !important;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .aboutOne{
        padding-top: 150px;
        background-image: none;

        .aboutOneC1{
            .text{
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 2;
            }
            img{
                width: 350px;
                top: 100px;
                display: grid;
                justify-self: right;
                grid-column-start: 2;
                grid-column-end: 3;
            }
        }
    }
}

@media screen and (max-width: 800px){
    .aboutOne{
        .aboutOneC1{
            grid-template-columns: 100%;
            
            .text{
                grid-row-start: 1;
                grid-row-end: 2;
            }
            img{
                top: 0px;
                justify-self: center;
                grid-column-start: 1;
                grid-column-end: 2;
                grid-row-start: 2;
                grid-row-end: 3;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .aboutOne{
        .text{
            p{
                line-height: 30px !important;
            }
        }
    }
}

@media screen and (max-width: 500px){
    .aboutOne{
        padding: 150px 20px;
    }
}
@media screen and (max-width: 500px){
    .aboutOne{
        .aboutOneC1{
            span{
                font-size: 30px !important;
            }
        }
        img{
            width: 100% !important;
        }
        p{
            font-size: 14px !important;
            padding-top: 20px;
        }
    }
}