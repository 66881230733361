.careerTwo{
    opacity: 0%;
    padding: 50px 150px;
    height: fit-content;
    display: grid;
    grid-template-columns: 30% 70%;
    background-image: linear-gradient( 90deg, white 60%, var(--cor-secondary) 60%);
    align-items: center;

    .careerTwoTitle{
        h1{
            opacity: 0%;
            font-weight: 700;
            color: var(--cor-primary);
        }
        h1.visible{
            animation: halfToRight 1s ease forwards;
        }
        p{
            opacity: 0%;
            padding-top: 5px;
            width: 80%;
            font-size: 18px;
        }
        p.visible{
            animation: halfToRight 1s .2s ease forwards;
        }
    }
    .swiper{
        opacity: 0%;
        width: 1000px;
        height: fit-content;
        max-width: 100%;
        /*background-color: var(--cor-tertiary); */
        background-image: linear-gradient(to right, var(--cor-primary), var(--cor-tertiary));
        border-radius: 10px;

        .swiper-button-prev:after, .swiper-button-next:after{
            display: none;
        }
        .swiper-button-next{
            top: 95%;
        }

        .swiper-slide{
            width: 100% !important;

            .card-media{
                padding: 20px;
                display: flex;
                gap: 20px;
                width: 100%;
                transition: .5s ease;

                video{
                    border-radius: 10px;
                    height: auto;
                    width: 300px;
                }
                .media-text{
                    padding: 10px 50px 10px 20px;
                    transition: 0.6s ease-in-out;
                    align-items: center;
                    align-self: center;
                    display: grid;
                    height: 100%;
                    min-width: 0px;
                    max-width: 100%;
                    
                    h1{
                        color: white;
                        margin: 0px;
                    }
                    h2{
                        color: white;
                        margin: 0px;
                    }
                    p{
                        color: white;
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .swiper.visible{
        animation: halfToUp 1s .2s ease forwards;
    }
}
.careerTwo.visible{
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .careerTwo{
        padding: 50px;
        
        .careerTwoTitle{
            p{
                font-size: 16px !important;
            }
        }
        .swiper{
            .swiper-button-next{
                top: 90%;
            }
            .swiper-slide{
                .card-media{
                    video{
                        width: 280px;
                        height: auto;
                    }
                    .media-text{
                        p{
                            max-height: 350px;
                            font-size: 14px !important;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .careerTwo{
        padding: 50px;
        
        .swiper{
            .swiper-slide{
                .card-media{
                    video{
                        width: 250px;
                    }
                    .media-text{
                        p{
                            max-height: 300px;
                            font-size: 14px !important;
                            -webkit-line-clamp: 16;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .careerTwo{
        display: grid;
        grid-template-columns: 100%;
        
        .swiper{
            margin-top: 20px;
        }
        .careerTwoTitle{
            width: 50%;
        }
    }
}

@media screen and (max-width: 768px){
    .careerTwo{
        padding: 50px !important;
        display: grid;
        grid-template-columns: 100%;
        
        .careerTwoTitle{
            width: 50%;
        }
        .swiper{
            .swiper-slide{
                .card-media{
                    video{
                        width: 250px;
                        height: auto;
                    }
                    .media-text{
                        padding: 10px;
                        p{
                            max-height: 250px;
                            -webkit-line-clamp: 11;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 600px){
    .careerTwo{
        padding: 50px 0px !important;
        
        .careerTwoTitle{
            padding: 0px 50px;
            width: 70%;
        }

        .swiper{
            margin-top: 20px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 425px){
    .careerTwo{
        padding: 50px 0px !important;
        
        .careerTwoTitle{
            padding: 0px 20px;
            p{
                font-size: 14px !important;
            }
        }

        .swiper{
            margin-top: 20px;
            width: 100%;

            .swiper-button-next{
                top: 95%;
                img{
                    width: 30px;
                }
            }
            .swiper-slide{
                .card-media{
                    display: grid;
                    padding-right: 50px;

                    video{
                        width: 90%;
                    }
                    .media-text{
                        padding: 0px;
                        p{
                            padding: 0px;
                            -webkit-line-clamp: 6;
                        }
                    }
                }
            }
        }
    }
}