.homeCertifications {
    padding: 50px 150px 80px;
    display: grid;
    grid-template-columns: auto auto;

    .homeCertificationsText {
        display: grid;
        height: fit-content;
        padding-top: 50px;

        h1 {
            color: var(--cor-secondary);
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToRight 1s ease forwards;
        }

        span {
            opacity: 0%;
            color: var(--cor-primary);
        }

        span.visible {
            animation: slideToRight 1s .3s ease forwards;
        }

        p {
            opacity: 0%;
            width: 600px;
            padding-top: 10px;
            font-size: 18px;
        }

        p.visible {
            animation: slideToRight 1s .3s ease forwards;
        }
    }

    .homeCertificationsCards {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: auto;
        row-gap: 10px;
        column-gap: 10px;
        width: fit-content;
        justify-self: center;

        .card {
            cursor: pointer;
            box-shadow: 10px 10px 10px 2px #0000005b;
            border: none;
            border: 20px;
            width: fit-content;
            height: fit-content;
            padding: 0px 10px;
            display: grid;
            grid-template-areas: 'card';
            align-items: center;
            justify-content: center;
            transition: .5s ease;
            opacity: 0%;

            img {
                grid-area: card;
                transition: .5s ease;
                padding: 5px;
                width: 100px;
                height: 100px;
            }

            .cardText {
                width: 250px;
                right: 90%;
                bottom: 90%;
                display: none;
                grid-area: card;
                position: absolute;
                background-color: var(--cor-primary);
                padding: 15px 10px;
                color: white;
                font-size: 14px;
                border-radius: 10px;

                p {
                    margin: 0px;
                }
            }
        }

        .card:hover {
            z-index: 10;
            scale: 1;
            background-color: var(--cor-secondary);
            box-shadow: none;

            img {
                scale: 1.1;
            }

            .cardText {
                display: grid;
                animation: fadeIn 1s ease forwards;
            }
        }

        .card1 {}

        .card1.visible {
            animation: fadeInRight 1.0s 1s ease forwards;
        }

        .card2 {}

        .card2.visible {
            animation: fadeInRight 1.0s 1.1s ease forwards;
        }

        .card3 {}

        .card3.visible {
            animation: fadeInRight 1.0s 1.2s ease forwards;
        }

        .card4 {}

        .card4.visible {
            animation: fadeInRight 1.0s 1.3s ease forwards;
        }

        .card5 {}

        .card5.visible {
            animation: fadeInRight 1.0s 1.4s ease forwards;
        }

        .card6 {}

        .card6.visible {
            animation: fadeInRight 1.0s 1.5s ease forwards;
        }

        .card7 {}

        .card7.visible {
            animation: fadeInRight 1.0s 1.6s ease forwards;
        }

        .card8 {}

        .card8.visible {
            animation: fadeInRight 1.0s 1.7s ease forwards;
        }

        .card9 {}

        .card9.visible {
            animation: fadeInRight 1.0s 1.8s ease forwards;
        }

        .card10 {}

        .card10.visible {
            animation: fadeInRight 1.0s 1.9s ease forwards;
        }

        .card11 {}

        .card11.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }

        .card12 {}

        .card12.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }

        .card13 {}

        .card13.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }

        .card14 {}

        .card14.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }

        .card15 {}

        .card15.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }

        .card16 {}

        .card16.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }

        .card17 {}

        .card17.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }

        .card18 {}

        .card18.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }

        .card19 {}

        .card19.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }

        .card20 {}

        .card20.visible {
            animation: fadeInRight 1.0s 2s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px) {
    .homeCertifications {
        padding: 20px 50px 50px 50px;
    }
}

@media screen and (max-width: 1400px) {
    .homeCertifications {
        .homeCertificationsCards {
            .card {
                padding: 0px 10px !important;

                img {
                    width: 80px;
                    height: 80px;
                }
            }
        }

        .homeCertificationsText {
            p {
                font-size: 16px !important;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .homeCertifications {
        grid-template-columns: 100%;

        .homeCertificationsCards {
            grid-template-columns: repeat(10, 1fr);
            justify-self: center;
            padding: 20px 0px 10px;

            .card {
                img {
                    width: 70px;
                    height: 70px;
                }
            }

            .card1,
            .card2,
            .card3,
            .card4,
            .card5,
            .card11,
            .card12,
            .card13,
            .card14,
            .card15 {
                .cardText {
                    right: -200%;
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .homeCertifications {
        .homeCertificationsCards {
            grid-template-columns: repeat(7, 1fr);

            .card {
                .cardText {
                    right: -200%;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .homeCertifications {
        .homeCertificationsCards {

            .card5,
            .card6,
            .card7,
            .card12,
            .card13,
            .card14,
            .card19,
            .card20 {
                .cardText {
                    right: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .homeCertifications {
        .homeCertificationsText {
            p {
                width: 100%;
            }
        }

        .homeCertificationsCards {
            grid-template-columns: repeat(5, 1fr);
            justify-self: center;

            .card {
                .cardText {
                    right: 20px;
                }
            }

            .card1,
            .card2,
            .card6,
            .card7,
            .card11,
            .card12,
            .card16,
            .card17 {
                .cardText {
                    right: -200%;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .homeCertifications {
        display: grid;

        .homeCertificationsCards {
            grid-template-columns: repeat(4, 1fr);

            .card1,
            .card2,
            .card5,
            .card6,
            .card9,
            .card10,
            .card13,
            .card14,
            .card17,
            .card18 {
                .cardText {
                    right: -220% !important;
                }
            }

            .card3,
            .card4,
            .card7,
            .card8,
            .card11,
            .card12,
            .card15,
            .card16,
            .card19,
            .card20 {
                .cardText {
                    right: 20px;
                }
            }
        }
    }
}

@media screen and (max-width: 475px){
    .homeCertifications{
        padding: 50px 20px;

        .homeCertificationsCards{
            width: 100%;
        }
    }
}

@media screen and (max-width: 425px) {
    .homeCertifications {
        padding: 50px 20px;

        .homeCertificationsText {
            p {
                font-size: 14px !important;
            }
        }

        .homeCertificationsCards {
            width: fit-content;
            grid-template-columns: repeat(3, 1fr);

            .card {
                padding: 0px 15px !important;
            }
            .card1,
            .card4,
            .card7,
            .card10,
            .card13,
            .card16,
            .card19,
            .card22,
            .card25,
            .card28 {
                .cardText {
                    right: -250% !important;
                }
            }

            .card2,
            .card5,
            .card8,
            .card11,
            .card14,
            .card17,
            .card20,
            .card23,
            .card26,
            .card29 {
                .cardText {
                    right: -150% !important;
                }
            }

            .card3,
            .card6,
            .card9,
            .card12,
            .card15,
            .card18,
            .card21,
            .card24,
            .card27,
            .card30 {
                .cardText {
                    right: 0px !important;
                }
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .homeCertifications {
        .homeCertificationsCards {
            .card {
                padding: 0px 10px !important;
            }
        }
    }
}