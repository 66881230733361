.servicesThree{
    padding: 150px 150px;
    display: flex;
    align-items: center;
    gap: 100px;

    .servicesThreeImg{
        img{
            background-color: rgba(255, 255, 255, 0);
        }
        img.visible{
            animation: pulse 2s ease infinite;
        }
    }
    .servicesThreeText{
        h1{
            font-weight: 700;
            color: var(--cor-primary);
            opacity: 0%;
        }
        h1.visible{
            animation: halfToRight 2s ease forwards;
        }
        h2{
            font-weight: 500;
            color: var(--cor-secondary);
            opacity: 0%;
        }
        h2.visible{
            animation: halfToRight 2s .2s ease forwards;
        }
        p{
            font-size: 18px;
            max-width: 1200px;
            margin: 0px;
            padding-top: 20px;
            opacity: 0%;
        }
        p.visible{
            animation: halfToRight 2s .5s ease forwards;
        }
    }
}

@media screen and (max-width: 1600px) {
    .servicesThree{
        padding: 100px 50px;
        
        .servicesThreeImg{
            img{
                width: 180px;
            }
        }
        .servicesThreeText{
            p{
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .servicesThree{
        gap: 50px;
        .servicesThreeImg{
            img{
                width: 150px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .servicesThree{
        padding: 70px 50px;
        display: grid;

        .servicesThreeImg{
            display: none;
        }
    }
}



@media screen and (max-width: 425px) {
    .servicesThree{
        padding: 100px 20px;
        
        .servicesThreeText{
            p{
                font-size: 14px;
            }
        }
    }
}