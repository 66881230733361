.homeMap {
    padding: 50px;
    display: flex;
    justify-content: space-around;
    gap: 100px;

    .homeMap1 {
        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-areas: 'map';

        img {
            grid-area: map;
            opacity: 0%;
            height: auto;
        }

        img.visible {
            animation: halfToRight 1s .2s ease forwards;
        }

        .pin {
            cursor: pointer;
            grid-area: map;
            position: relative;
            height: fit-content;
            display: block;
            width: fit-content;
            justify-self: center;
            opacity: 0%;

            img {
                height: auto;
                width: 40px;
                opacity: 100%;
                transition: .2s ease;
            }

            .card {
                display: none;
            }
        }

        .pin:hover {
            img {
                transform: translateX(5px);
            }

            .card {
                animation: fadeIn .2s ease forwards;
                z-index: 500;
                width: 300px;
                right: 10px;
                bottom: 10px;
                position: absolute;
                display: grid;
                padding: 15px 10px;
                border-radius: 10px;
                border: none;
                box-shadow: 10px 10px 10px 2px rgba(0, 0, 0, 0.3568627451);
                gap: 5px;

                h1 {
                    font-size: 20px !important;
                    color: var(--cor-primary);
                    font-weight: 600;
                }

                p {
                    margin: 0px;
                    font-size: 14px !important;
                }

                a {
                    width: fit-content;
                    font-size: 14px;
                    margin: 0px;
                    color: var(--cor-secondary);
                }
            }
        }

        .pin1 {
            left: 325px;
            top: -120px;

            img {
                width: 28px;
            }
        }

        .pin1.visible {
            animation: slideToUp 1s 2s ease forwards;
        }

        .pin2 {
            top: 210px;
            left: 82px;

            img {
                width: 28px;
            }
        }

        .pin2.visible {
            animation: slideToUp 1s 2.2s ease forwards;
        }

        .pin3 {
            top: 135px;
            left: 110px;

            img {
                width: 28px;
            }
        }

        .pin3.visible {
            animation: slideToUp 1s 2.4s ease forwards;
        }

        .pin4 {
            top: 100px;
            left: 10px;

            img {
                width: 28px;
            }
        }

        .pin4.visible {
            animation: slideToUp 1s 2.6s ease forwards;
        }

        .pin5 {
            top: 155px;
            left: 70px;

            img {
                width: 28px;
            }
        }

        .pin5.visible {
            animation: slideToUp 1s 2.8s ease forwards;
        }

        .pin6 {
            top: 90px;
            left: 180px;

            img {
                width: 30px;
            }
        }

        .pin6.visible {
            animation: slideToUp 1s 2.6s ease forwards;
        }

        .pin7 {
            top: 125px;
            left: 200px;

            img {
                width: 25px;
            }
        }

        .pin7.visible {
            animation: slideToUp 1s 2.6s ease forwards;
        }

        .pin8 {
            top: 188px;
            left: 93px;

            img {
                width: 22px;
            }
        }

        .pin8.visible {
            animation: slideToUp 1s 2.8s ease forwards;
        }

        .pin9 {
            top: 250px;
            left: 50px;

            img {
                width: 28px;
            }
        }

        .pin9.visible {
            animation: slideToUp 1s 2.2s ease forwards;
        }

        .pin10 {
            top: 135px;
            left: 140px;

            img {
                width: 28px;
            }
        }

        .pin10.visible {
            animation: slideToUp 1s 2.4s ease forwards;
        }
    }

    .homeMap2 {
        padding-top: 100px;
        display: grid;
        height: fit-content;

        h1 {
            color: var(--cor-tertiary);
            font-weight: 700;
            width: 90%;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToUp 2s ease forwards;
        }

        p {
            padding-top: 50px;
            font-size: 18px;
            width: 70%;
            opacity: 0%;
        }

        p.visible {
            animation: slideToUp 2s .1s ease forwards;
        }

        span {
            padding-top: 10px;
            font-size: 18px;
            width: 60%;
            padding-bottom: 30px;
            opacity: 0%;
        }

        span.visible {
            animation: slideToUp 2s .2s ease forwards;
        }

        a {
            width: fit-content;
            display: grid;
            grid-template-areas: 'button';
            justify-content: center;
            text-align: center;
            transition: .5s ease;

            img {
                height: auto;
                z-index: 99;
                grid-area: button;
                opacity: 0%;
                transition: .5s ease;
            }

            img.visible {
                animation: slideToUp 2s .3s ease forwards;
            }

            p {
                transition: .5s ease;
                position: relative;
                bottom: 20px;
                width: 100%;
                opacity: 100%;
                z-index: 100;
                grid-area: button;
                font-weight: 500;
            }
        }

        a:hover {
            img {
                filter: blur(5px);
                scale: .9;
            }

            p {
                color: var(--cor-primary);
                scale: 1.1;
                font-weight: 700;
            }
        }
    }

}

@media screen and (max-width: 1600px) {
    .homeMap {
        padding-top: 0px !important;

        .homeMap1 {
            img {
                width: 600px;
            }

            .pin1 {
                left: 270px;
                top: -105px;

                img {
                    width: 30px;
                }
            }

            .pin2 {
                top: 180px;
                left: 70px;

                img {
                    width: 28px;
                }
            }

            .pin3 {
                top: 110px;
                left: 95px;

                img {
                    width: 28px;
                }
            }

            .pin10 {
                top: 112px;
                left: 120px;

                img {
                    width: 28px;
                }
            }

            .pin4 {
                top: 80px;
                left: 20px;

                img {
                    width: 28px;
                }
            }

            .pin5 {
                top: 140px;
                left: 40px;

                img {
                    width: 32px;
                }
            }

            .pin6 {
                top: 80px;
                left: 160px;

                img {
                    width: 30px;
                }
            }

            .pin7 {
                top: 105px;
                left: 170px;

                img {
                    width: 25px;
                }
            }

            .pin8 {
                top: 158px;
                left: 82px;

                img {
                    width: 22px;
                }
            }

            .pin9 {
                top: 220px;
                left: 40px;

                img {
                    width: 25px;
                }
            }
        }

        .homeMap2 {
            p {
                font-size: 16px !important;
            }

            span {
                font-size: 16px !important;
            }

            a {
                img {
                    width: 250px;
                }

                p {
                    bottom: 25px;
                }
            }
        }
    }
}

@media screen and (max-width: 1500px) {
    .homeMap {
        .homeMap1 {
            img {
                width: 600px;
            }

            .pin1 {
                left: 270px;
                top: -105px;

                img {
                    width: 30px;
                }
            }

            .pin2 {
                top: 180px;
                left: 70px;

                img {
                    width: 28px;
                }
            }

            .pin3 {
                top: 110px;
                left: 95px;

                img {
                    width: 28px;
                }
            }

            .pin4 {
                top: 80px;
                left: 20px;

                img {
                    width: 28px;
                }
            }

            .pin5 {
                top: 140px;
                left: 40px;

                img {
                    width: 32px;
                }
            }
        }

        .homeMap2 {
            h1 {
                width: 100% !important;
            }

            p {
                width: 90% !important;
            }

            span {
                width: 80% !important;
            }

            a {
                p {
                    width: 100% !important;
                }
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .homeMap {
        .homeMap2 {
            p {
                font-size: 16px !important;
            }

            span {
                font-size: 16px !important;
            }
        }
    }
}

@media screen and (max-width: 1300px) {
    .homeMap {
        .homeMap1 {
            img {
                width: 500px;
            }

            .pin1 {
                left: 235px;
                top: -85px;

                img {
                    width: 22px;
                }
            }

            .pin2 {
                top: 150px;
                left: 60px;

                img {
                    width: 22px;
                }
            }

            .pin3 {
                top: 95px;
                left: 80px;

                img {
                    width: 25px;
                }
            }

            .pin4 {
                top: 70px;
                left: 10px;

                img {
                    width: 25px;
                }
            }

            .pin5 {
                top: 115px;
                left: 30px;

                img {
                    width: 25px;
                }
            }

            .pin6 {
                top: 70px;
                left: 130px;

                img {
                    width: 20px;
                }
            }

            .pin7 {
                top: 87px;
                left: 142px;

                img {
                    width: 20px;
                }
            }

            .pin8 {
                top: 130px;
                left: 65px;

                img {
                    width: 18px;
                }
            }

            .pin9 {
                top: 175px;
                left: 35px;

                img {
                    width: 20px;
                }
            }

            .pin10 {
                top: 95px;
                left: 100px;

                img {
                    width: 20px;
                }
            }
        }

        .homeMap2 {
            h1 {
                width: 100% !important;
            }

            p {
                padding-top: 20px;
                width: 100% !important;
            }

            span {
                padding-bottom: 20px;
                width: 100% !important;
            }

            a {
                p {
                    align-self: center;
                    margin: 0px;
                    padding: 0px;
                    bottom: 10px;
                    height: fit-content;
                }
            }
        }
    }
}

@media screen and (max-width: 1020px) {
    .homeMap {
        padding-top: 0px;
        gap: 50px;

        .homeMap1 {
            img {
                width: 450px;
            }

            .pin1 {
                left: 207px;
                top: -76px;

                img {
                    width: 22px;
                }
            }

            .pin2 {
                top: 135px;
                left: 55px;

                img {
                    width: 22px;
                }
            }

            .pin3 {
                top: 80px;
                left: 70px;

                img {
                    width: 20px;
                }
            }

            .pin4 {
                top: 60px;
                left: 10px;

                img {
                    width: 22px;
                }
            }

            .pin5 {
                top: 100px;
                left: 30px;

                img {
                    width: 22px;
                }
            }

            .pin6 {
                top: 60px;
                left: 115px;

                img {
                    width: 20px;
                }
            }

            .pin7 {
                top: 75px;
                left: 128px;

                img {
                    width: 20px;
                }
            }

            .pin8 {
                top: 115px;
                left: 60px;

                img {
                    width: 18px;
                }
            }

            .pin9 {
                top: 160px;
                left: 30px;

                img {
                    width: 20px;
                }
            }

            .pin10 {
                top: 85px;
                left: 90px;

                img {
                    width: 18px;
                }
            }
        }

        .homeMap2 {
            padding-top: 50px;

            h1 {
                width: 100% !important;
            }

            p {
                padding-top: 10px;
                width: 100% !important;
                margin-bottom: 0px;
            }

            span {
                padding-bottom: 10px;
                width: 100% !important;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .homeMap {
        display: grid;
        padding-bottom: 0px !important;

        .homeMap1 {
            grid-row-start: 2;
            grid-row-end: 3;
            justify-self: end;
            position: relative;

            .pin:hover {
                .card {
                    h1 {
                        font-size: 16px !important;
                        margin-bottom: 0px !important;
                    }

                    p {
                        font-size: 12px !important;
                    }
                }
            }
        }

        .homeMap2 {
            padding-top: 0px !important;
            width: 80%;
            grid-row-start: 1;
            grid-row-end: 2;
        }
    }
}

@media screen and (max-width: 768px) {
    .homeMap {
        .homeMap2 {
            width: 100% !important;

            a {
                img {
                    width: 200px !important;
                }

                p {
                    font-size: 14px !important;
                    bottom: 5px;
                }
            }
        }
    }
}

@media screen and (max-width: 550px) {
    .homeMap {
        padding: 50px 0px;

        .homeMap1 {
            padding-bottom: 50px;
            bottom: 0px;
            overflow-x: hidden;
            justify-self: end;

            .pin:hover {
                .card {
                    width: 200px !important;
                    gap: 0px;
                }
            }
        }

        .homeMap2 {
            padding: 0px 50px;
            width: 100% !important;
        }
    }
}

@media screen and (max-width: 425px) {
    .homeMap {
        .homeMap2 {
            padding: 0px 20px !important;

            p {
                font-size: 14px !important;
            }

            span {
                font-size: 14px !important;
            }
        }

        .homeMap1 {

            .pin4:hover,
            .pin5:hover,
            .pin9:hover {
                .card {
                    right: -100px !important;
                    bottom: 20px;
                }
            }

            .pin2:hover,
            .pin3:hover,
            .pin8:hover,
            .pin10:hover{
                .card {
                    right: -60px !important;
                    bottom: 20px;
                }
            }
        }
    }
}