.esgThree{
    padding: 100px 0px 100px 150px;
    display: grid;
    grid-template-columns: auto auto;
    background-image: url("../../img/esg/esgThree/esgThree-bg.webp");
    background-repeat: no-repeat;
    background-size: 450px;
    background-position: 100% 70%;
    opacity: 0%;

    .esgText{
        display: grid;
        height: fit-content;
        align-self: center;
        h1{
            font-weight: 700;
            color: var(--cor-primary);
            max-width: 450px;
            margin: 0px;
            opacity: 0%;
    
            span{
                color: #3A904F;
            }
        }
        h1.visible{
            animation: halfToLeft 1s ease forwards;
        }
        p{
            padding-top: 30px;
            max-width: 650px;
            margin: 0px;
            font-size: 18px;
            line-height: 35px;
            opacity: 0%;
        }
        p.visible{
            animation: halfToLeft 1s .1s ease forwards;
        }
    }
    .esgImg{
        position: relative;
        justify-self: right;
        padding-top: 100px;
        opacity: 0%;

        img{
            border-radius: 10px 0px 0px 10px;
            width: 750px;
        }
    }
    .esgImg.visible{
        animation: halfToLeft 1s .2s ease forwards;
    }
}
.esgThree.visible{
    animation: changeOpacity 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .esgThree{
        padding: 0px 0px 100px 50px;
        background-size: 400px;
        .esgText{
            p{
                font-size: 16px;
            }
        }
        .esgImg{
            right: 0px;
            padding-top: 150px;
            img{
                margin-top: 30px;
                width: 700px;
            }
        }
    }
}

@media screen and (max-width: 1440px){
    .esgThree{
        background-size: 350px;
        padding: 0px 0px 40px 50px;
        gap: 50px;
        
        .esgText{
            max-width: 550px;
            p{
                font-size: 16px;
            }
        }
        .esgImg{
            img{
                border-radius: 10px 0px 0px 10px;
                margin-bottom: 50px;
                width: 600px;
            }
        }
    } 
}

@media screen and (max-width: 1200px){
    .esgThree{
        padding: 30px 0px 50px 50px;
        .esgImg{
            img{
                width: 500px;
            }
        }
    }
}

@media screen and (max-width: 1000px){
    .esgThree{
        grid-template-columns: 100%;
        padding-bottom: 50px;
        padding-top: 40px;
        background-position: 100% 90%;
        background-size: 250px;
        gap: 0px;
        
        .esgImg{
            padding: 0px;
            img{
                width: 500px;
                margin-top: 50px;
            }
        }
    }
}

@media screen and (max-width: 768px){
    .esgThree{
        background-position: 100% 90%;
        padding-top: 40px;

        .esgText{
            p{
                width: 100%;
                line-height: 25px;
                padding-right: 50px;
            }
        }
    }
}

@media screen and (max-width: 550px){
    .esgThree{
        padding-top: 30px;
        padding-bottom: 50px;
        background-size: 45%;
        background-position: 100% 90%;
        .esgText{
            h1{
                width: 100%;
                padding-right: 50px;
            }
        }
        .esgImg{
            img{
                margin-top: 50px;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 425px){
    .esgThree{
        padding: 30px 0px 0px 20px;
        background-size: 45%;
        background-position: 100% 95%;
        .esgText{
            h1{
                padding-right: 50px;
            }
            p{
                padding-top: 20px;
                padding-right: 20px;
                font-size: 14px;
            }
        }
        .esgImg{
            img{
                margin-top: 30px;
                width: 100%;
            }
        }
    }
}