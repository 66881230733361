.aboutSeven{
    padding: 100px 0px 0px;
    opacity: 0%;

    .aboutSevenTitle{
        text-align: center;
        padding-bottom: 20px;

        h1{
            color: var(--cor-secondary);
            font-weight: 700;
            opacity: 0%;
        }
        h1.visible{
            animation: halfToLeft 1s .2s ease forwards;
        }
    }
    .swiper{
        padding: 50px 0px;
        max-width: 1200px;
        border-radius: 10px;
        cursor: pointer;
        opacity: 0%;

        .swiper-button-prev:after, .swiper-button-next:after{
            display: none;
        }

        .swiper-slide{
            transition: .5 ease;
            height: 600px;

            .card{
                border: none;
                img{
                    transition: .5s ease;
                    z-index: 1;
                }
                img:hover{
                    scale: 1.1;
                    z-index: 1000;
                }
            }
            .card1, .card2{
                img{
                    display: block;
                    width: 100%;
                    height: 300px;
                }
            }
            .card3{
                img{
                    height: 600px;
                }
            }
        }
        .swiper-slide:hover{
            z-index: 1000;
        }
    }
    .swiper.visible{
        animation: halfToLeft 1s .4s ease forwards;
    }
}
.aboutSeven{
    animation: fadeIn 1s ease forwards;
}

@media screen and (max-width: 1600px){
    .aboutSeven{
        .aboutSevenTitle{
            padding: 0px;
            h1{
                margin: 0px
            };
        }
        .swiper{
            max-width: 1000px;
        
            .swiper-slide{
                height: 500px;

                .card1, .card2{
                    img{
                        height: 250px;
                    }
                }
                .card3{
                    img{
                        height: 500px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1200px){
    .aboutSeven{
        padding: 100px 20px 0px;
        .swiper{
            max-width: 900px;

            .swiper-button-next, .swiper-button-prev{
                img{
                    width: 25px;
                }
            }
            .swiper-slide{
                height: 400px;
                
                .card1, .card2{
                    img{
                        height: 200px;
                    }
                }
                .card3{
                    img{
                        height: 400px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .aboutSeven{
        padding: 50px 20px 0px;
        
        .swiper{
            max-width: 600px;
        }
    }
}

@media screen and (max-width: 500px){
    .aboutSeven{
        padding: 50px 50px 0px;
        
        .swiper{
            padding: 20px 0px;
            max-width: 100%;

            .swiper-slide{
                height: 500px;

                .card1, .card2{
                    img{
                        height: 250px;
                    }
                }
                .card3{
                    img{
                        height: 500px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 425px){
    .aboutSeven{
        padding: 50px 20px 0px;
    }
}