.servicesFour {
    padding: 100px 0px 0px;
    display: grid;
    justify-content: center;

    .servicesFourText {
        display: grid;
        justify-content: center;
        text-align: center;

        h1 {
            margin: 0px;
            color: var(--cor-secondary);
            font-weight: 700;
            opacity: 0%;
        }

        h1.visible {
            animation: slideToDown 1s ease forwards;
        }

        h2 {
            padding-top: 10px;
            color: var(--cor-primary);
            font-weight: 600;
            max-width: 550px;
            justify-self: center;
            opacity: 0%;
        }

        h2.visible {
            animation: slideToDown 1s .2s ease forwards;
        }

        p {
            padding-top: 30px;
            margin: 0px;
            line-height: 35px;
            max-width: 1130px;
            font-size: 18px;
            opacity: 0%;
        }

        p.visible {
            animation: halfToDown 1s .2s ease forwards;
        }
    }

    .servicesFourCards {
        display: grid;
        gap: 50px;

        .cards1,
        .cards2,
        .cards3 {
            display: flex;
            justify-content: center;
            gap: 50px;
        }

        .cardImg {
            position: relative;
            top: 10px;
            opacity: 0%;
            transition: .5s ease;
            cursor: pointer;
        }

        .cardImg:hover {
            scale: 1.1;
        }

        .cardImg.visible {
            animation: changeOpacity 2s 1.4s ease forwards;
        }

        .card {
            width: 400px;
            height: fit-content;
            border-radius: 30px;
            box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.341);
            padding: 30px 50px 10px;
            align-self: center;
            opacity: 0%;
            transition: .5s ease;
            border: 1px solid rgba(255, 255, 255, 0);
            cursor: pointer;

            h3 {
                font-size: 20px;
                color: var(--cor-secondary);
                font-weight: 600;
            }
        }

        .card:hover {
            border-radius: 10px;
            border: 1px solid var(--cor-secondary);
            scale: 1.1;
        }

        .card1 {
            position: relative;
            top: 100px;
            right: 0px;
        }

        .card1.visible {
            animation: slideToRight 2s 1.5s ease forwards;
        }

        .card2 {
            position: relative;
            top: 100px;
            left: 100px;
        }

        .card2.visible {
            animation: slideToRight 2s 1.6s ease forwards;
        }

        .card3.visible {
            animation: slideToRight 2s 1.7s ease forwards;
        }

        .card4.visible {
            animation: slideToRight 2s 1.8s ease forwards;
        }

        .card5 {
            position: relative;
            top: -100px;
            right: 70px;
        }

        .card5.visible {
            animation: slideToRight 2s 1.9s ease forwards;
        }

        .card6 {
            position: relative;
            top: -100px;
            left: 50px;
        }

        .card6.visible {
            animation: slideToRight 2s 2s ease forwards;
        }
    }

    a {
        justify-self: center;
        display: grid;
        height: fit-content;
        width: fit-content;
        opacity: 0%;

        button {
            width: 400px;
            height: 60px;
            background-color: var(--cor-secondary);
            border: none;
            border-radius: 10px;
            color: white;
            font-weight: 600;
            transition: .4s ease;
            border: 2px solid rgba(255, 255, 255, 0);
        }
    }

    a.visible {
        animation: halfToDown 1s .6s ease forwards;
    }

    a:hover {
        button {
            background-color: rgba(255, 255, 255, 0.815);
            color: var(--cor-secondary);
            border: 2px solid var(--cor-secondary);
            scale: .95;
        }
    }
}

@media screen and (max-width: 1600px) {
    .servicesFour {
        .servicesFourText {
            p {
                font-size: 16px
            }
        }

        .servicesFourCards {
            .card {
                h3 {
                    font-size: 18px;
                }

                p {
                    font-size: 14px;
                }
            }

            .cardImg {
                width: 350px;
            }
        }

        a {
            button {
                width: 300px;
                font-size: 16px;
            }
        }
    }
}

@media screen and (max-width: 1400px) {
    .servicesFour {
        padding: 80px 50px 0px;

        .servicesFourText {
            p {
                max-width: 100%;
            }
        }

        .servicesFourCards {
            .card {
                padding: 20px 30px 10px !important;
            }

            .cardImg {
                z-index: 500;
                width: 300px;
            }

            .cards1,
            .cards2,
            .cards3 {
                gap: 10px;
            }

            .card1 {
                right: 50px;
            }

            .card2 {
                left: 50px;
            }

            .card3,
            .card4 {
                max-width: 400px;
                width: 100%;
            }

            .card5 {
                right: 20px;
            }

            .card6 {
                left: 20px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .servicesFour {
        .servicesFourText {
            p {
                line-height: 30px;
            }
        }

        .servicesFourCards {
            row-gap: 20px;
            padding: 50px 0px;

            .cardImg {
                display: none;
            }
        }

        .card {
            width: 100%;
        }

        .card1 {
            top: 0px !important;
            right: 0px !important;
            width: 120% !important;
        }

        .card2 {
            top: 0px !important;
            left: 0px !important;
            width: 80% !important;
        }

        .card3 {
            max-width: none !important;
            width: 90% !important;
        }

        .card4 {
            max-width: none !important;
            width: 110% !important;
        }

        .card5 {
            top: 0px !important;
            right: 0px !important;
            width: 100% !important;
        }

        .card6 {
            top: 0px !important;
            left: 0px !important;
            width: 100% !important;
        }

        a {
            button {
                padding: 10px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .servicesFour {
        .servicesFourCards {
            .card6 {
                top: -20px !important;
            }
        }

        a {
            button {
                height: 50px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .servicesFour {
        padding-bottom: 20px;

        .servicesFourCards {
            gap: 10px;
            padding-bottom: 30px;

            .card {
                width: 100% !important;
            }

            .cards1,
            .cards2,
            .cards3 {
                display: grid;
            }

            .card1,
            .card3,
            .card5 {
                width: 80% !important;
                justify-self: left;
            }

            .card2,
            .card4,
            .card6 {
                width: 80% !important;
                justify-self: right;
            }

            .card6 {
                top: 0px !important;
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .servicesFour {
        padding: 50px 20px 0px;

        .servicesFourText {
            p {
                line-height: 25px !important;
                font-size: 14px !important;
            }
        }

        .servicesFourCards {
            padding: 50px 0px 20px;
            gap: 10px;

            .card {
                width: 100% !important;

                h3 {
                    font-size: 16px;
                }
            }

            .cards1,
            .cards2,
            .cards3 {
                display: grid;
            }

            .card1,
            .card3,
            .card5,
            .card2,
            .card4,
            .card6 {
                width: 100% !important;
                justify-self: center;
            }
        }

        a {
            button {
                width: 200px;
                padding: 5px;
            }
        }
    }
}