.esgTwo{
    width: 100%;
    display: grid;
    justify-content: center;
    padding: 150px 0px;
    background-image: url('../../img/esg/esgTwo/esgTwo-bg.webp');
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0%;

    iframe{
        border-radius: 10px;
        opacity: 0%;
        transition: .5s ease;
        border: 3px solid rgba(255, 255, 255, 0);
    }
    iframe:hover{
        border: 3px solid var(--cor-secondary);
    }
    iframe.visible{
        animation: halfToUp 1s .1s ease forwards;
    }
}
.esgTwo{
    animation: changeOpacity 1s ease forwards;
}

@media screen and (max-width: 1200px){
    .esgTwo{
        padding: 100px 150px;
        background-position: 50% !important;

        iframe{
            width: 600px;
            height: 260px;
        }
    }
}

@media screen and (max-width: 768px){
    .esgTwo{
        padding: 50px 0px;

        iframe{
            width: 80vw;
        }
    }
}

@media screen and (max-width: 500px){
    .esgTwo{
        iframe{
            height: 200px;
        }
    }
}

@media screen and (max-width: 425px){
    .esgTwo{
        iframe{
            width: 90vw;
            height: 200px;
        }
    }
}